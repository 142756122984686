@filter-gutter : 15px;

.filter-popover {
    width: 263px;
    z-index: 1;
}

.filter-advanced {
    text-align: right;

    .form-control-styled-select {
        margin-right: 10px;
        display: inline-block;
        min-width: 155px;
    }
}

.filter-popover-holder {
    position: relative;
    clear: both;

    > .popover {
        left: auto;
        right: 0;
    }
}

.filter {
    .h3 {
        padding-bottom: @filter-gutter;
        margin-bottom: @filter-gutter;
        border-bottom: 1px solid @brand-pastel-gray;
    }

    .h6 {
        margin-bottom: @filter-gutter;
    }

    .bootstrap-select {
        margin-bottom: @vertical-space-2;
    }
}

.filter-status-holder {
    .checkbox {
        padding: 0 0 @vertical-space-2;
    }
}

.filter-advanced .mooicon-filter:before {
    font-size: 36px;
    min-width: 36px;
}
