.lists-container {
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0px;
        border-left: 2px dotted #eee;
        display: block;
        left: 50%;
        margin: 0;
    }

    .pack {
        .grow;
        overflow: hidden;
        transition: all 150ms ease-in-out;
        margin-bottom: 10px;
    }

    .packs {
        .custom-checkbox {
            visibility: hidden;
        }

        .product {
            .btn {
                opacity: 0;
                position: absolute;
                right: 10px;
                top: 10px;
                .transition(opacity 150ms ease-in);
            }

            h3 {
                overflow: hidden;
                vertical-align: middle;
                text-overflow: ellipsis;
            }

            &:hover h3 {
                max-width: 120px;
            }

            &:hover .btn {
                visibility: visible;
                opacity: 1;
            }

            &:after {
                display: none;
            }
        }

        .product-thumb {
            height: 100px;

            .mooicon-search { opacity:0; }
        }
    }

    .grow {
        opacity: 1;
        height: 108px;
    }

    .shrink {
        opacity: 0;
        height: 0;
    }

    .attached-packs {
        .pack {
            padding-left: 10px;
            padding-right: 15px;
            background: @brand-secondary-gray;

            button {
                .btn-alternate;
            }
        }
    }
}