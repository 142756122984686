//
// Popovers
// --------------------------------------------------


.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: @zindex-popover;
    color: @brand-black;
    display: none;
    max-width: @popover-max-width;
    padding: 1px;
    text-align: left; // Reset given new insertion method
    background-color: @popover-bg;
    background-clip: padding-box;
    border-radius: @border-radius-base;
    outline: 0 none;

    border: 1px solid @popover-border-color;

    // Overrides for proper insertion
    white-space: normal;

    // Offset the popover to account for the popover arrow
    &.top           { margin-top: -10px; }
    &.right         { margin-left: 10px; }
    &.bottom        { margin-top: 10px; }
    &.bottom-right  { margin-top: 10px; }
    &.bottom-left   { margin-top: 10px; }
    &.left          { margin-left: -10px; }

    // Reset styling for lists in popups
    ul {
        margin: 2px 0 0;
        padding: 5px 0;
        list-style: none;

        & > li > a {
            display: block;
            padding: 5px 18px;
            color: @brand-black;
            text-align: left;
            white-space: nowrap;

            [class*="moosprite-"] {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }

    .new {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px;
        overflow: hidden;
        line-height: @line-height-base;
        z-index: 2;

        span {
            display: block;
            text-align: center;
            .rotate(45deg);
            background-color: @brand-blue;
            padding: 16px 8px 6px;
            width: 80px;
            position: relative;
            right: -9px;
            top: -7px;
            color: @brand-white;
            font-size: 11px;
        }
    }
}

.popover-title {
    margin: 0; // reset heading margin
    padding: 12px 15px 8px;
    font-size: @font-size-base;
    font-weight: normal;
    line-height: 18px;
    border-bottom: 1px solid darken(@popover-title-bg, 5%);
    border-radius: 5px 5px 0 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: 0;
        margin: 0;
    }
}

.popover-content {
    padding: @popover-inner-padding;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover .arrow {
    &,
    &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
}
.popover .arrow {
    border-width: @popover-arrow-outer-width;
}
.popover .arrow:after {
    border-width: @popover-arrow-width;
    content: "";
}

.popover {
  &.top .arrow {
      left: 50%;
      margin-left: -@popover-arrow-outer-width;
      border-bottom-width: 0;
      border-top-color: @popover-arrow-outer-color;
      bottom: -@popover-arrow-outer-width;
      &:after {
          content: " ";
          bottom: 1px;
          margin-left: -@popover-arrow-width;
          border-bottom-width: 0;
          border-top-color: @popover-arrow-color;
      }
  }
  &.right .arrow {
      top: 50%;
      left: -@popover-arrow-outer-width;
      margin-top: -@popover-arrow-outer-width;
      border-left-width: 0;
      border-right-color: @popover-arrow-outer-color;
      &:after {
          content: " ";
          left: 1px;
          bottom: -@popover-arrow-width;
          border-left-width: 0;
          border-right-color: @popover-arrow-color;
      }
  }
  &.bottom .arrow {
      left: 50%;
      margin-left: -@popover-arrow-outer-width;
      border-top-width: 0;
      border-bottom-color: @popover-arrow-outer-color;
      top: -@popover-arrow-outer-width;
      &:after {
          content: " ";
          top: 1px;
          margin-left: -@popover-arrow-width;
          border-top-width: 0;
          border-bottom-color: @popover-arrow-color;
      }
  }

  &.bottom-left .arrow {
      left: 4%;
      border-top-width: 0;
      border-bottom-color: @popover-arrow-outer-color;
      top: -@popover-arrow-outer-width;
      &:after {
          content: " ";
          top: 1px;
          margin-left: -@popover-arrow-width;
          border-top-width: 0;
          border-bottom-color: @popover-arrow-color;
      }
  }

  &.bottom-right .arrow {
      right: 4%;
      border-top-width: 0;
      border-bottom-color: @popover-arrow-outer-color;
      top: -@popover-arrow-outer-width;
      &:after {
          content: " ";
          top: 1px;
          margin-left: -@popover-arrow-width;
          border-top-width: 0;
          border-bottom-color: @popover-arrow-color;
      }
  }

  &.left .arrow {
      top: 50%;
      right: -@popover-arrow-outer-width;
      margin-top: -@popover-arrow-outer-width;
      border-right-width: 0;
      border-left-color: @popover-arrow-outer-color;
      &:after {
          content: " ";
          right: 1px;
          border-right-width: 0;
          border-left-color: @popover-arrow-color;
          bottom: -@popover-arrow-width;
      }
  }

}

.popover-anchor {
    display: inline-block;
    width: @caret-font-size;
    height: @caret-font-size;
    background-color: @popover-anchor-bg;
    border: 2px solid transparent;
    color: @popover-anchor-colour;
    text-align: center;
    cursor: pointer;
    position: relative;

    font-size: @caret-font-size;

    &:before {
        .mooicon-chevron-down-x-small();
    }

    &:active,
    &:focus,
    &:hover {
        margin: 0;
        border: 0 none;
        text-decoration: none;
        color: @popover-anchor-colour;
    }

    &:empty {
        width: @caret-font-size;
        height: @caret-font-size;
    }

    &[disabled] {
        background: @brand-pastel-gray;
        color: @gray;
    }

    .is-showing &,
    &.is-showing {
        outline: 0 none;
        &:before {
            .mooicon-chevron-up-x-small();
        }
    }

    &,
    .is-showing &,
    &.is-showing {
        &:empty {
            width: @caret-font-size;
            height: @caret-font-size;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            line-height: @caret-font-size;
            height: @caret-font-size;
            width: @caret-font-size;
            font-size: @caret-font-size;

            body:not(#foo) & {
                top: 0;
            }
        }
    }
}

.popover-fit-content {
    display: inline-block;
    width: auto;
    max-width: inherit;
}

// popover state classes
// ----------------------

.has-no-arrow {
    .arrow {
        display: none;
    }

    .popover-content {
        padding: 0; // reset padding to bleed borders
    }
}

body.is-loading {
    &, & * {
        cursor: wait !important;
    }
}
