//
// Tooltips
// --------------------------------------------------

// Base class
.tooltip {
    position: absolute;
    z-index: @zindex-tooltip;
    display: block;
    visibility: visible;
    font-size: @font-size-small;
    line-height: 1.4;
    border-radius: @tooltip-border-radius;

    .opacity(0);

    &.in     { .opacity(1); }
    &.top    { margin-top:  -3px;  }
    &.right  { margin-left:  3px;  }
    &.bottom { margin-top:   3px;  }
    &.left   { margin-left: -3px;  }
}

// Wrapper for the tooltip content
.tooltip-inner {
    max-width: @tooltip-max-width;
    color: @tooltip-color;
    font-size: @font-size-base;
    line-height: 1.2em;
    text-align: left;
    text-decoration: none;
    border-radius: @border-radius-base;
    padding: @tooltip-padding;
    background-color: @tooltip-bg;
    border: 1px solid @tooltip-border-color;
}

// Arrows
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    display: none;
}
.tooltip {
    &.top .tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -@tooltip-arrow-width;
        border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
        border-top-color: @tooltip-arrow-color;
    }
    &.top-left .tooltip-arrow {
        bottom: 0;
        left: @tooltip-arrow-width;
        border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
        border-top-color: @tooltip-arrow-color;
    }
    &.top-right .tooltip-arrow {
        bottom: 0;
        right: @tooltip-arrow-width;
        border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
        border-top-color: @tooltip-arrow-color;
    }
    &.right .tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -@tooltip-arrow-width;
        border-width: @tooltip-arrow-width @tooltip-arrow-width @tooltip-arrow-width 0;
        border-right-color: @tooltip-arrow-color;
    }
    &.left .tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -@tooltip-arrow-width;
        border-width: @tooltip-arrow-width 0 @tooltip-arrow-width @tooltip-arrow-width;
        border-left-color: @tooltip-arrow-color;
    }
    &.bottom .tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -@tooltip-arrow-width;
        border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
        border-bottom-color: @tooltip-arrow-color;
    }
    &.bottom-left .tooltip-arrow {
        top: 0;
        left: @tooltip-arrow-width;
        border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
        border-bottom-color: @tooltip-arrow-color;
    }
    &.bottom-right .tooltip-arrow {
        top: 0;
        right: @tooltip-arrow-width;
        border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
        border-bottom-color: @tooltip-arrow-color;
    }
}

[data-toggle="tooltip"] {
    cursor: pointer;
}