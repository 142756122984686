
.moosprite-card-amex {
	width: 28px;
	height: 18px;
	background-position: -0px 0;
}

.moosprite-card-giropay {
	width: 28px;
	height: 18px;
	background-position: -35px 0;
}

.moosprite-card-ideal {
	width: 28px;
	height: 18px;
	background-position: -70px 0;
}

.moosprite-card-mastercard {
	width: 28px;
	height: 18px;
	background-position: -105px 0;
}

.moosprite-card-paypal {
	width: 28px;
	height: 18px;
	background-position: -140px 0;
}

.moosprite-card-sofort {
	width: 28px;
	height: 18px;
	background-position: -175px 0;
}

.moosprite-card-visa {
	width: 28px;
	height: 18px;
	background-position: -210px 0;
}

.moosprite-flag-au {
	width: 17px;
	height: 12px;
	background-position: -245px 0;
}

.moosprite-flag-ca {
	width: 17px;
	height: 12px;
	background-position: -270px 0;
}

.moosprite-flag-de {
	width: 17px;
	height: 12px;
	background-position: -295px 0;
}

.moosprite-flag-es {
	width: 17px;
	height: 12px;
	background-position: -320px 0;
}

.moosprite-flag-eu {
	width: 17px;
	height: 12px;
	background-position: -345px 0;
}

.moosprite-flag-fr {
	width: 17px;
	height: 12px;
	background-position: -370px 0;
}

.moosprite-flag-it {
	width: 17px;
	height: 12px;
	background-position: -395px 0;
}

.moosprite-flag-nl {
	width: 17px;
	height: 12px;
	background-position: -420px 0;
}

.moosprite-flag-uk {
	width: 17px;
	height: 12px;
	background-position: -445px 0;
}

.moosprite-flag-us {
	width: 17px;
	height: 12px;
	background-position: -470px 0;
}

.moosprite-social-facebook {
	width: 16px;
	height: 16px;
	background-position: -495px 0;
}

.moosprite-social-facebook-alt {
	width: 16px;
	height: 16px;
	background-position: -520px 0;
}

.moosprite-social-google {
	width: 16px;
	height: 16px;
	background-position: -545px 0;
}

.moosprite-social-google-alt {
	width: 16px;
	height: 16px;
	background-position: -570px 0;
}

.moosprite-social-twitter {
	width: 16px;
	height: 16px;
	background-position: -595px 0;
}

.moosprite-social-twitter-alt {
	width: 16px;
	height: 16px;
	background-position: -620px 0;
}

.no-svg .moosprite-card-amex,
.no-svg .moosprite-card-giropay,
.no-svg .moosprite-card-ideal,
.no-svg .moosprite-card-mastercard,
.no-svg .moosprite-card-paypal,
.no-svg .moosprite-card-sofort,
.no-svg .moosprite-card-visa,
.no-svg .moosprite-flag-au,
.no-svg .moosprite-flag-ca,
.no-svg .moosprite-flag-de,
.no-svg .moosprite-flag-es,
.no-svg .moosprite-flag-eu,
.no-svg .moosprite-flag-fr,
.no-svg .moosprite-flag-it,
.no-svg .moosprite-flag-nl,
.no-svg .moosprite-flag-uk,
.no-svg .moosprite-flag-us,
.no-svg .moosprite-social-facebook,
.no-svg .moosprite-social-facebook-alt,
.no-svg .moosprite-social-google,
.no-svg .moosprite-social-google-alt,
.no-svg .moosprite-social-twitter,
.no-svg .moosprite-social-twitter-alt {
	display: inline-block;
	background-image: url("@{image-path}/sprites/png-sprite.png");
	background-size: 645px 18px;
}

.moosprite-card-amex,
.moosprite-card-giropay,
.moosprite-card-ideal,
.moosprite-card-mastercard,
.moosprite-card-paypal,
.moosprite-card-sofort,
.moosprite-card-visa,
.moosprite-flag-au,
.moosprite-flag-ca,
.moosprite-flag-de,
.moosprite-flag-es,
.moosprite-flag-eu,
.moosprite-flag-fr,
.moosprite-flag-it,
.moosprite-flag-nl,
.moosprite-flag-uk,
.moosprite-flag-us,
.moosprite-social-facebook,
.moosprite-social-facebook-alt,
.moosprite-social-google,
.moosprite-social-google-alt,
.moosprite-social-twitter,
.moosprite-social-twitter-alt {
	display: inline-block;
	background-image: url("@{image-path}/sprites/svg-sprite.svg");
	background-repeat: no-repeat;
}
