.bulk-import {
    h3 {
        margin-bottom: @vertical-space-2;

        &.product-title {
            margin-bottom: 0;
        }
    }

    .product-type-name {
        position: relative;
        left: 10px;
    }

    .paper-quantity-wrapper {
        float: left;
        margin-right: 20px;
        margin-bottom: -10px;
    }

    .paper-options-message a {
        padding-left: 10px;
    }

    .paper-summary {
        clear: both;

      .form-control {
          border: none;
          width: auto;

          .btn {
              border: 0;
              padding-left: 0;
          }
      }
    }
}

