.payment-iframe {
    overflow: hidden;
    .payment-details {
        .control-label {
            .make-xs-column(3);

            &.cvv-tooltip {
                white-space: nowrap;

                // The tooltip lives inside the label
                .tooltip {
                    white-space: normal;
                }
            }
        }

        .tooltip {
            width: 100%;
        }

        .cvv-tooltip .tooltip-inner {
            padding-bottom: 120 + @tooltip-padding;
            max-width: 400px;
            width: 400px;
            background: url(../img/help-cc-green.png) @tooltip-bg no-repeat;
            background-position: @tooltip-padding (@line-height-computed * 5);
            letter-spacing: 0;
        }
    }

}