//
// Thumbnails
// --------------------------------------------------


// Mixin and adjust the regular image class
.thumbnail {
    .img-thumbnail();
    display: block; // Override the inline-block from `.img-thumbnail`
    margin-bottom: @line-height-computed;

    > img {
        .img-responsive();
        margin-left: auto;
        margin-right: auto;
    }

    // Add a hover state for linked versions only
    a&:hover,
    a&:focus,
    a&.active {
        border-color: @link-color;
    }

    // Image captions
    .caption {
        padding: @thumbnail-caption-padding;
        color: @thumbnail-caption-color;
    }
}


.pack-thumb {
    display: inline-block;
    padding-right: 10px;
}

.pack-thumb-side {
    width: 100px;
    height: 66px;
    display: block;
    border: 1px solid @brand-pastel-gray;
    .box-shadow(-4px 4px 5px rgba(0, 0, 0, 0.14));

    & + & {
        position: relative;
        top: -10px;
        left: 10px;
    }
}
