.order-summary {
    .col-key,
    .col-value {
        .make-sm-column(6);
        padding-top: @vertical-space-1 / 2;
        padding-bottom: @vertical-space-2;

        > span {
            display: block;
        }
    }
}

.order-summary-total {
    margin-bottom: @vertical-space-2;

    &:before {
        margin-top: 0;
    }
}
