@login-margin-bottom : 20px;

.reset-page,
.login-page {
    .moo-logo {
        margin-bottom: 7px; // extra pixels necessary to ensure the logo is 20px away from the title
    }

    .control-label{
        .make-sm-column(3);
    }
    .form-input-col{
        .make-sm-column(9);
        padding-left: 0;
    }

    .form-button-col {
        margin-top: 15px;
        margin-bottom: 10px;
        margin-left: 0;
        width: auto;
    }

    .form-info > .help-block {
        .make-sm-column(9);
        .make-sm-column-offset(3);
        padding-left: 0;
    }

    .form-group-btn {
        margin-bottom: 0;
    }

    .login-back {
        width: @minimal-content-width;
        margin: 0 auto;
        text-align: center;

        a {
            color: @moods-grey-06;
            text-decoration: none;
        }
    }

}

.login-form {

    .required:after { display: none; }

    .forgot-link {
        position: absolute;
        font-size: @font-size-base;

        // align link to the bottom + half of button height
        bottom: @minimal-content-padding-vertical + @padding-large-vertical;

        // align forgot link with the grid form fields
        left: percentage((3 / 12));
        margin-left: @grid-gutter-width;
    }

    &.is-loading {
        .forgot-link {
            display: none;
        }
    }

    .has-form-error {
        .has-error;
    }
}

.login-reset-form {
    .control-label {
        padding-top: 0;
    }

    p {
        margin-bottom: @login-margin-bottom;
    }
}
