@import "moo-toolkit/src/less/fontface.less";

@font-face {
    @font-name: 'AvenirNextRoundedPro-Reg';

    font-family: 'Avenir Next Rounded Pro-Reg';
    src: url('@{font-path}@{font-name}.eot');
    src: url('@{font-path}@{font-name}.eot?#iefix') format('embedded-opentype'),
    url('@{font-path}@{font-name}.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
}

@font-face {
    @font-name: 'AvenirNextRoundedPro-Med';

    font-family: 'Avenir Next Rounded Pro-Med';
    src: url('@{font-path}@{font-name}.eot');
    src: url('@{font-path}@{font-name}.eot?#iefix') format('embedded-opentype'),
    url('@{font-path}@{font-name}.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
}