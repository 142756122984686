.bulk-preview {
    .table tbody tr.error td {
        border: 1px solid @brand-danger;
        background: @brand-pastel-pink;
    }

    .csv-pack-data {
        &:hover {
            text-decoration: underline;
        }
    }
}

