//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

// Core styles

/* --------------------------------------------------
 Buttons
-----------------------------------------------------
Default: No border, 2px border radius to match MOO.com toolkit

    /------------------------\
    |        Default         |
    \------------------------/

Hover: Has a thin bottom border

    /------------------------\
    |         Hover          |
    \========================/

Active: Has no bottom border, but background color is slightly lighter than default state

    /------------------------\
    |    Active (clicked)    |
    \------------------------/

*/

.btn {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: @btn-font-weight;
    font-family: @font-family-bryant;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: none;
    border-bottom: @btn-border-bottom solid transparent;
    white-space: nowrap;
    .button-size(@padding-base-vertical; @padding-large-horizontal; @font-size-medium; @line-height-base; @border-radius-base);
    .user-select(none);

    .btn + & {
        margin-left: 10px;
    }

    &:focus,
    &.focus {
        .tab-focus();
    }

    &:hover,
    &.hover,
    &:focus,
    &.focus {
        .btn-focus();
    }

    &:active,
    &.active {
        background-color: @btn-default-bg-active;
    }

    // The content for the button is updated during the animation.
    // The animation starts with the text being transparent and then fades in.
    // at the end of the animation javascript is then moved to swap
    // out the text for the value in the data attribute.
    &[data-loading-text],
    &[data-loading-text]:before {
        .transition(opacity @transition-speed);
    }

    &.loading[data-loading-text],
    &.success[data-success-text] {
        cursor: default;
        // Hide text - from .text-hide mixin
        // hides text and set 0 width. font size and line-height 0
        font: ~"0/0" a;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: inherit;
            border-radius: inherit;
            background: inherit;
            pointer-events: none;
            opacity: 1;

            // lt IE9 has no support for opacity on pseudo elements
            html.oldie & {
                display: none;
            }
        }
        &:after {
            padding: 0;
        }
        &:before,
        &:after {
            .button-size(@padding-base-vertical; @padding-large-horizontal; @font-size-medium; @line-height-base; @border-radius-base);
            font-family: @font-family-bryant;
        }
    }

    &.loading[data-loading-text] {
        opacity: @btn-loading-opacity;
        &:before,
        &:after {
            content: attr(data-loading-text);
        }
    }

    &.success[data-success-text] {
        border-bottom-color: transparent;
        background-color: @btn-primary-bg-active;
        &:before,
        &:after {
            content: attr(data-success-text);
        }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &[data-loading-text] {
            display: inline-block;
        }

        html:not(.oldie) & {

            /*
             * Disabled Tooltip States
             */
            &[data-disabled-tooltip-text] {
                // Show tooltip on hover
                &:hover,
                .hover {
                    &:before,
                    &:after {
                        opacity: 1;
                    }
                }

                // Set which direction we show the tooltip in
                &,
                &[data-disabled-tooltip-direction="top"] {
                    &:before {
                        left: 50%;
                        .translate(-50%, 0);
                        bottom: 50px;
                    }
                    &:after {
                        border-color: @brand-black transparent transparent transparent;
                        bottom: 30px;
                        left: 50%;
                        .translate(-50%, 0);
                    }
                }
                &[data-disabled-tooltip-direction="bottom"] {
                    &:before {
                        left: 50%;
                        .translate(-50%, 0);
                        top: 50px;
                    }
                    &:after {
                        border-color: transparent transparent @brand-black transparent;
                        top: 30px;
                        left: 50%;
                        .translate(-50%, 0);
                    }
                }
                &[data-disabled-tooltip-direction="left"] {
                    &:before {
                        top: 50%;
                        .translate(-100%, -50%);
                        left: -12px;
                    }
                    &:after {
                        border-color: transparent transparent transparent @brand-black;
                        top: 50%;
                        .translate(-100%, -50%);
                        left: 8px;
                    }
                }
                &[data-disabled-tooltip-direction="right"] {
                    &:before {
                        top: 50%;
                        .translate(100%, -50%);
                        right: -12px;
                    }
                    &:after {
                        border-color: transparent @brand-black transparent transparent;
                        top: 50%;
                        .translate(0, -50%);
                        right: -12px;
                    }
                }

                // Main toopltip styling, hidden
                &:before {
                    content: attr(data-disabled-tooltip-text);
                    position: absolute;
                    min-width: 60px;
                    min-height: 40px;
                    height: auto;
                    padding: 10px;
                    color: @brand-white;
                    text-align: center;
                    font-size: @font-size-h6;
                    background: @brand-black;
                    border-radius: @tooltip-border-radius;
                    pointer-events: none;
                    opacity: 0;
                    zoom: 1;
                    .transition(all 0.3s ease-in-out);
                }
                &:after {
                    content: "";
                    position: absolute;
                    opacity: 0;
                    zoom: 1;
                    border-width: @tooltip-border-width;
                    border-style: solid;
                    .transition(all 0.3s ease-in-out);
                }
            }
        }
    }
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
    .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border; @btn-default-bg-active);
}

.btn-primary {
    .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border; @btn-primary-bg-active);
}

.btn-alternate {
    .button-variant(@btn-alternate-color; @btn-alternate-bg; @btn-alternate-border; @btn-alternate-bg-active);
}

// Warning appears as orange
.btn-warning {
    .button-variant(@brand-white; @brand-warning; darken(@brand-warning, 5%); @brand-warning);
}

// Danger and error appear as red
.btn-danger {
    .button-variant(@brand-white; @brand-danger; darken(@brand-danger, 5%); @brand-danger);
}

// Success appears as green
.btn-success {
    .button-variant(@brand-white; @brand-success; darken(@brand-success, 5%); @brand-success);
}

// Info appears as blue-green
.btn-info {
    .button-variant(@brand-white; @brand-info; darken(@brand-info, 5%); @brand-info);
}

// Buttons with icon and text
// --------------------------
.btn-with-icon {

    > [class^="mooicon-"] {
        margin-right: 10px;
        position: relative;
        top: -3px;

        &.mooicon-right {
            margin-right: 0;
            margin-left: 5px;
        }
    }

    // If there is a loading or success state, hide the icon - the data properties will add the wanted text
    &.loading[data-loading-text],
    &.success[data-success-text] {
        > [class^="mooicon-"] {
            font: 0/0 a;
        }
    }
}

// Buttons with just an icon
// -------------------------

.btn-icon {
    padding: 1px 5px;
    display: inline-block;
    max-height: 41px;

    &.mooicon-large {
        min-width: @mooicon-font-size-lg + (@btn-border-bottom * 2);
        font-size: @mooicon-font-size-lg;
    }
    &.mooicon-medium {
        min-width: @mooicon-font-size-md + (@btn-border-bottom * 2);
        font-size: @mooicon-font-size-md;
    }
    &.mooicon-small {
        min-width: @mooicon-font-size-sm + (@btn-border-bottom * 2);
    }
}

// Label buttons
// -------------------------------------------------

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
    color: @link-color;
    font-weight: normal;
    cursor: pointer;
    border-radius: 0;
    outline: none;

    &,
    &:active,
    &[disabled],
    fieldset[disabled] & {
        background-color: transparent;
        .box-shadow(none);
    }
    &,
    &:hover,
    &:focus,
    &:active {
        margin-top: 0;
        border-color: transparent;
    }
    &:hover,
    &:focus {
        color: @link-hover-color;
        text-decoration: underline;
        background-color: transparent;
    }
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus {
            color: @btn-link-disabled-color;
            text-decoration: none;
        }
    }
}

// reset btn to make it behave like ordinary inline text
.btn-reset {
    padding: 0;
    margin: 0;
    border: 0;
    display: inline;
    font-family: @font-family-base;
    font-size: @font-size-base;
    line-height: 1;
    vertical-align: baseline;
}

.btn-clear {
    border: 0;
    padding: 0;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

// Block button
// --------------------------------------------------
.btn-block-holder {
    margin-top: @vertical-space-1 / 2;
    margin-bottom: @vertical-space-4;
}

.btn-block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
    margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}

// Full button - make sure container has relative/absolute positioning
// --------------------------------------------------
.btn-full {
    line-height: 65px;
    padding: 0 20px;
    border-radius: 0;
    min-width: 100px;
}

.btn.btn-full + .btn.btn-full {
    margin: 0;
}

// Label buttons
// -------------------------------------------------

.btn-label-danger {

    .btn-primary;
    .label;
    .label-danger;

    &:hover {
        background-color: #66aa00;
        border-bottom: 3px solid #579100;
        padding-bottom: 6px;
        margin-top: 0;
    }

    &:active {
        margin-top: 0;
        border: 0 none;
        padding-bottom: 7px;
        position: relative;
        top: 1px;
    }
}

// Confirm buttons
// -------------------------------------------------

.btn-confirm-block {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;

    &.open {
        .btn-confirm {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            top: -1px;

            /*void the hover and active rules*/
            &:focus, &:hover, &:active {
                border-bottom-width: 2px;
                margin-top: 0px;
                border-bottom-color: @btn-default-border;
            }
        }
        .btn-confirm-panel {
            display: inline-block;
        }
    }

    .btn-confirm {
        float: none;
        top: -1px;
    }
    .btn-confirm-panel {
        display: none;

        .transition(all 400ms ease);
        &.no-transition {
            .transition(none);
        }
        button {
            margin-left: 0px;
            height: 42px;
            top: -1px;
            border-bottom-width: 2px;

            &:hover {
                text-decoration: none;
                margin-top: 0px;
                padding-bottom: 2px;
            }
            &:active, &.active {
                margin-top: 0px;
            }
            &.btn-confirm-panel-icon {
                min-width: @mooicon-font-size-md;
                padding: 0;

                > span {
                    float: none;
                }
                &.btn-confirm-panel-icon-yes {
                    color: @brand-success;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    border-left: 2px dotted @gray;
                }
                &.btn-confirm-panel-icon-no {
                    color: @brand-danger;
                    border-radius: 0;
                }
            }
        }
    }
}