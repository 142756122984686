.shipping-method.popover {
    display: none;
    margin-top: @vertical-space-1 /2;
    max-height: 350px;
    overflow-x: scroll;
    
    .shipping-method-option {
        width: auto;
    }
}

.shipping-method-options {
    width: 500px;
    margin-bottom: 0;
}

.shipping-method-option {
    cursor: pointer;
    margin-bottom: 0;
    padding-bottom: @vertical-space-2;
    
    &:hover {
        background-color: @brand-pastel-gray;

        & + .media {
            border-color: @brand-pastel-gray;
        }
    }

    h4 {
        margin-top: 0;
    }

    p {
        margin-bottom: 0;
    }

    .product-price {
        display: block;
        margin-top: 15px;
    }
}
