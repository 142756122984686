.products {
    .list-unstyled;

    > .row > [class*="col-"]{
        padding-bottom: @vertical-space-base * 2.5;
    }
}

.product {
    .separator-horizontal();

    &:first-child {
        &:before {
            display: none;
        }
    }

    &.product-dismissible {
        position: relative;
    }
}

.product-checkbox {
    @product-checkbox-size : 30px;

    float: left;
    max-width: 55px;
    padding-right: 25px;

    &.col-sm-1 {
        padding-right: 10px;
        margin-left: 5px;
    }

    .radio,
    .checkbox {
        padding-top: 0;
        margin-top: 0;
        padding-left: 0;
        width: @product-checkbox-size;
        height: @product-checkbox-size;
        min-height: @product-checkbox-size;

        .products & {
            padding-top: @vertical-space-1 / 2;
        }
    }
}

.product-title {
    margin: @vertical-space-1 0 0;
}

.product-description {
    padding-left: 15px;
    
    > p {
        clear: both;
        padding: (@vertical-space-1 / 2) 0;
        margin: 0;
    }

    h3 {

        > a,
        > span {
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            max-width: 100%;
            text-overflow: ellipsis;
        }
    }
}

.product-thumb-holder {
    max-width: 140px; // grid gutter plus thumbnail width 109px
}

.product-thumb {
    width: 109px;
    height: 124px;
    position: relative;
    display: inline-block;
    outline: none;

    .mooicon-search {
        color: @gray;
        position: absolute;
    }

    &.no-label {
        height: 101px;

        > .mooicon-search {
            bottom: -15px;
            left: 0;
        }
    }

    > img {
        margin: 0 auto;
    }

    > .label {
        position: absolute;
        bottom: 0;
        right: 9px;
        background: none;
        
        > .mooicon-search {
            left: -10px;
        }
    }

    &.centred {
        text-align: center;
        padding-top: 18px;
    }
}

.product-remove {
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 1;

    &:hover {
        color: @gray-darker;
    }
}

.product-aside {
    padding-top: @vertical-space-2;
}

.product-price-only {
    padding-top: @vertical-space-4;
}

.product-price {
    .h3;
    margin-bottom: 0;
    padding-bottom: @vertical-space-1;

    .product-price-old + & {
        margin-top: 0;
    }
    .original-price {
        .centered-strike;
        font-size: 85%;
    }
    .actual-price {

    }
}

.product-price-old {
    .h4;
    position: relative;
    display: inline-block;
}

