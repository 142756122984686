@dashboard-circle: 180px;
@dashboard-count: 48px;
@dashboard-border: 5px;

.dashboard-content-holder {
    margin: @vertical-space-4 0 @vertical-space-5;
}

.dashboard-packs {
    display: inline-block;
    width: @dashboard-circle;
    height: @dashboard-circle;
    border: @dashboard-border solid @brand-dark-green-lighter;
    border-radius: 100%;
    padding: 0 15px;
    text-align: center;

    a& {
        text-decoration: none;
        &:hover {
            .dashboard-packs-text { text-decoration: underline; }
        }
    }
}

.dashboard-packs-count {
    display: block;
    margin: @vertical-space-4 0 @vertical-space-1;
    font-size: @dashboard-count;
    line-height: 1;
    color: @gray-darker;
}

.dashboard-aside {
    .separator-horizontal-thick(~":before");
    .separator-horizontal-thick();

    margin-top: @vertical-space-5;

    &:before,
    &:after {
        margin: 25px 0;
    }

    .help-account-manager-info {
        margin: 0;
    }

    h2 {
        .h3;
    }

    .manager-data {
        > ul {
            overflow: hidden;

            li {
                float: left;

                &:before {
                    content: "|";
                    margin: 0 (@grid-gutter-width / 2);
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}
