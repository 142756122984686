.status-gray {
    background-color: @gray-darker;
    border-color: @gray-darker;
}

.status-green {
    background-color: @brand-dark-green;
    border-color: @brand-dark-green;
}

.status-red {
    background-color: @brand-red;
    border-color: @brand-red;
}

.status-indicator {
    .list-unstyled;
    margin: 0 0 5px;
    overflow: hidden;

    display: inline-block;
    vertical-align: middle;

    > li {
        float: left;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background-color: transparent;
        border: 1px solid @gray;
        border-radius: 50%;
        text-indent: -32px;
        overflow: hidden;
    }

    &.processing {
        > li:first-child {
            .status-gray;
        }
    }

    &.in-progress {
        > li:first-child {
            .status-gray;

            & + li {
                .status-green;
            }
        }
    }

    &.complete {
        > li {
            .status-green;
        }
    }

    &.cancelled {
        > li {
            .status-red;
        }
    }
}

.status-indicator-label {
    padding-right: 25px;

    & + .status-indicator {
        margin-top: 1px;
        margin-right: 18px;
    }
}
