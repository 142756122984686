//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Moo Icon Codes
//
// This files is automatically generated. Do not directly edit
// its contents.
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

@font-face {
    font-family: "mooicon";
    src: url("@{font-path}mooicons/mooicon.eot");
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: "mooicon";
	src: url("@{font-path}mooicons/mooicon.eot");
	src: url("@{font-path}mooicons/mooicon.eot?#iefix") format("embedded-opentype"),
		url("@{font-path}mooicons/mooicon.woff") format("woff"),
		url("@{font-path}mooicons/mooicon.ttf") format("truetype"),
		url("@{font-path}mooicons/mooicon.svg?#mooicon") format("svg");
	font-weight: normal;
	font-style: normal;
}

.mooicon{
  font-family: "mooicon";
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mooicon-alert: "\f101";
@mooicon-bin: "\f102";
@mooicon-bleed: "\f103";
@mooicon-cards: "\f150";
@mooicon-cart: "\f104";
@mooicon-chat: "\f105";
@mooicon-cheveron-right-x-small: "\f106";
@mooicon-chevron-down-small: "\f107";
@mooicon-chevron-down-x-small: "\f108";
@mooicon-chevron-down: "\f109";
@mooicon-chevron-left-small: "\f10a";
@mooicon-chevron-left-x-small: "\f10b";
@mooicon-chevron-left: "\f10c";
@mooicon-chevron-right-small: "\f10d";
@mooicon-chevron-right: "\f10e";
@mooicon-chevron-up-small: "\f10f";
@mooicon-chevron-up-x-small: "\f110";
@mooicon-chevron-up: "\f111";
@mooicon-circle-carousel: "\f112";
@mooicon-circle-fill-carousel: "\f113";
@mooicon-cross-small: "\f114";
@mooicon-cross: "\f115";
@mooicon-dashboard-alt: "\f151";
@mooicon-dashboard: "\f152";
@mooicon-download: "\f116";
@mooicon-edit: "\f117";
@mooicon-email: "\f118";
@mooicon-error-circle: "\f14e";
@mooicon-etsy: "\f119";
@mooicon-exclamation-large: "\f11a";
@mooicon-exclamation: "\f11b";
@mooicon-facebook: "\f11c";
@mooicon-filter-alt: "\f153";
@mooicon-filter: "\f14f";
@mooicon-flickr: "\f11d";
@mooicon-flip-bigger: "\f11e";
@mooicon-flip: "\f11f";
@mooicon-google-plus: "\f120";
@mooicon-grid-4: "\f121";
@mooicon-grid-6: "\f122";
@mooicon-heart-fill: "\f123";
@mooicon-heart: "\f124";
@mooicon-help-bold-circle: "\f125";
@mooicon-help-circle: "\f126";
@mooicon-help-large: "\f127";
@mooicon-help: "\f128";
@mooicon-info-circle: "\f154";
@mooicon-info-large: "\f129";
@mooicon-info: "\f12a";
@mooicon-instagram: "\f12b";
@mooicon-list-2: "\f12c";
@mooicon-list-3: "\f12d";
@mooicon-minus-small: "\f12e";
@mooicon-minus: "\f12f";
@mooicon-more: "\f130";
@mooicon-orders-alt: "\f155";
@mooicon-orders: "\f156";
@mooicon-packs: "\f157";
@mooicon-people: "\f158";
@mooicon-pinterest-circle: "\f132";
@mooicon-pinterest: "\f133";
@mooicon-play-fill: "\f134";
@mooicon-play: "\f135";
@mooicon-plus-small: "\f136";
@mooicon-plus: "\f137";
@mooicon-print: "\f138";
@mooicon-qr: "\f139";
@mooicon-receipt: "\f13a";
@mooicon-rotate: "\f13b";
@mooicon-rss: "\f13c";
@mooicon-safe-area: "\f13d";
@mooicon-save: "\f13e";
@mooicon-search: "\f13f";
@mooicon-sort-down: "\f141";
@mooicon-sort-up: "\f142";
@mooicon-sort: "\f143";
@mooicon-tick-small: "\f144";
@mooicon-tick: "\f145";
@mooicon-trim: "\f146";
@mooicon-twitter-t: "\f147";
@mooicon-twitter: "\f148";
@mooicon-upload: "\f149";
@mooicon-users: "\f159";
@mooicon-view-fill: "\f14a";
@mooicon-view: "\f14b";
@mooicon-zoom-in: "\f14c";
@mooicon-zoom-out: "\f14d";


// Icons

.mooicon-alert() {
  &:extend(.mooicon);
  content: @mooicon-alert;
}

.mooicon-alert:before {
  .mooicon-alert();
}

.mooicon-bin() {
  &:extend(.mooicon);
  content: @mooicon-bin;
}

.mooicon-bin:before {
  .mooicon-bin();
}

.mooicon-bleed() {
  &:extend(.mooicon);
  content: @mooicon-bleed;
}

.mooicon-bleed:before {
  .mooicon-bleed();
}

.mooicon-cards() {
  &:extend(.mooicon);
  content: @mooicon-cards;
}

.mooicon-cards:before {
  .mooicon-cards();
}

.mooicon-cart() {
  &:extend(.mooicon);
  content: @mooicon-cart;
}

.mooicon-cart:before {
  .mooicon-cart();
}

.mooicon-chat() {
  &:extend(.mooicon);
  content: @mooicon-chat;
}

.mooicon-chat:before {
  .mooicon-chat();
}

.mooicon-cheveron-right-x-small() {
  &:extend(.mooicon);
  content: @mooicon-cheveron-right-x-small;
}

.mooicon-cheveron-right-x-small:before {
  .mooicon-cheveron-right-x-small();
}

.mooicon-chevron-down-small() {
  &:extend(.mooicon);
  content: @mooicon-chevron-down-small;
}

.mooicon-chevron-down-small:before {
  .mooicon-chevron-down-small();
}

.mooicon-chevron-down-x-small() {
  &:extend(.mooicon);
  content: @mooicon-chevron-down-x-small;
}

.mooicon-chevron-down-x-small:before {
  .mooicon-chevron-down-x-small();
}

.mooicon-chevron-down() {
  &:extend(.mooicon);
  content: @mooicon-chevron-down;
}

.mooicon-chevron-down:before {
  .mooicon-chevron-down();
}

.mooicon-chevron-left-small() {
  &:extend(.mooicon);
  content: @mooicon-chevron-left-small;
}

.mooicon-chevron-left-small:before {
  .mooicon-chevron-left-small();
}

.mooicon-chevron-left-x-small() {
  &:extend(.mooicon);
  content: @mooicon-chevron-left-x-small;
}

.mooicon-chevron-left-x-small:before {
  .mooicon-chevron-left-x-small();
}

.mooicon-chevron-left() {
  &:extend(.mooicon);
  content: @mooicon-chevron-left;
}

.mooicon-chevron-left:before {
  .mooicon-chevron-left();
}

.mooicon-chevron-right-small() {
  &:extend(.mooicon);
  content: @mooicon-chevron-right-small;
}

.mooicon-chevron-right-small:before {
  .mooicon-chevron-right-small();
}

.mooicon-chevron-right() {
  &:extend(.mooicon);
  content: @mooicon-chevron-right;
}

.mooicon-chevron-right:before {
  .mooicon-chevron-right();
}

.mooicon-chevron-up-small() {
  &:extend(.mooicon);
  content: @mooicon-chevron-up-small;
}

.mooicon-chevron-up-small:before {
  .mooicon-chevron-up-small();
}

.mooicon-chevron-up-x-small() {
  &:extend(.mooicon);
  content: @mooicon-chevron-up-x-small;
}

.mooicon-chevron-up-x-small:before {
  .mooicon-chevron-up-x-small();
}

.mooicon-chevron-up() {
  &:extend(.mooicon);
  content: @mooicon-chevron-up;
}

.mooicon-chevron-up:before {
  .mooicon-chevron-up();
}

.mooicon-circle-carousel() {
  &:extend(.mooicon);
  content: @mooicon-circle-carousel;
}

.mooicon-circle-carousel:before {
  .mooicon-circle-carousel();
}

.mooicon-circle-fill-carousel() {
  &:extend(.mooicon);
  content: @mooicon-circle-fill-carousel;
}

.mooicon-circle-fill-carousel:before {
  .mooicon-circle-fill-carousel();
}

.mooicon-cross-small() {
  &:extend(.mooicon);
  content: @mooicon-cross-small;
}

.mooicon-cross-small:before {
  .mooicon-cross-small();
}

.mooicon-cross() {
  &:extend(.mooicon);
  content: @mooicon-cross;
}

.mooicon-cross:before {
  .mooicon-cross();
}

.mooicon-dashboard-alt() {
  &:extend(.mooicon);
  content: @mooicon-dashboard-alt;
}

.mooicon-dashboard-alt:before {
  .mooicon-dashboard-alt();
}

.mooicon-dashboard() {
  &:extend(.mooicon);
  content: @mooicon-dashboard;
}

.mooicon-dashboard:before {
  .mooicon-dashboard();
}

.mooicon-download() {
  &:extend(.mooicon);
  content: @mooicon-download;
}

.mooicon-download:before {
  .mooicon-download();
}

.mooicon-edit() {
  &:extend(.mooicon);
  content: @mooicon-edit;
}

.mooicon-edit:before {
  .mooicon-edit();
}

.mooicon-email() {
  &:extend(.mooicon);
  content: @mooicon-email;
}

.mooicon-email:before {
  .mooicon-email();
}

.mooicon-error-circle() {
  &:extend(.mooicon);
  content: @mooicon-error-circle;
}

.mooicon-error-circle:before {
  .mooicon-error-circle();
}

.mooicon-etsy() {
  &:extend(.mooicon);
  content: @mooicon-etsy;
}

.mooicon-etsy:before {
  .mooicon-etsy();
}

.mooicon-exclamation-large() {
  &:extend(.mooicon);
  content: @mooicon-exclamation-large;
}

.mooicon-exclamation-large:before {
  .mooicon-exclamation-large();
}

.mooicon-exclamation() {
  &:extend(.mooicon);
  content: @mooicon-exclamation;
}

.mooicon-exclamation:before {
  .mooicon-exclamation();
}

.mooicon-facebook() {
  &:extend(.mooicon);
  content: @mooicon-facebook;
}

.mooicon-facebook:before {
  .mooicon-facebook();
}

.mooicon-filter-alt() {
  &:extend(.mooicon);
  content: @mooicon-filter-alt;
}

.mooicon-filter-alt:before {
  .mooicon-filter-alt();
}

.mooicon-filter() {
  &:extend(.mooicon);
  content: @mooicon-filter;
}

.mooicon-filter:before {
  .mooicon-filter();
}

.mooicon-flickr() {
  &:extend(.mooicon);
  content: @mooicon-flickr;
}

.mooicon-flickr:before {
  .mooicon-flickr();
}

.mooicon-flip-bigger() {
  &:extend(.mooicon);
  content: @mooicon-flip-bigger;
}

.mooicon-flip-bigger:before {
  .mooicon-flip-bigger();
}

.mooicon-flip() {
  &:extend(.mooicon);
  content: @mooicon-flip;
}

.mooicon-flip:before {
  .mooicon-flip();
}

.mooicon-google-plus() {
  &:extend(.mooicon);
  content: @mooicon-google-plus;
}

.mooicon-google-plus:before {
  .mooicon-google-plus();
}

.mooicon-grid-4() {
  &:extend(.mooicon);
  content: @mooicon-grid-4;
}

.mooicon-grid-4:before {
  .mooicon-grid-4();
}

.mooicon-grid-6() {
  &:extend(.mooicon);
  content: @mooicon-grid-6;
}

.mooicon-grid-6:before {
  .mooicon-grid-6();
}

.mooicon-heart-fill() {
  &:extend(.mooicon);
  content: @mooicon-heart-fill;
}

.mooicon-heart-fill:before {
  .mooicon-heart-fill();
}

.mooicon-heart() {
  &:extend(.mooicon);
  content: @mooicon-heart;
}

.mooicon-heart:before {
  .mooicon-heart();
}

.mooicon-help-bold-circle() {
  &:extend(.mooicon);
  content: @mooicon-help-bold-circle;
}

.mooicon-help-bold-circle:before {
  .mooicon-help-bold-circle();
}

.mooicon-help-circle() {
  &:extend(.mooicon);
  content: @mooicon-help-circle;
}

.mooicon-help-circle:before {
  .mooicon-help-circle();
}

.mooicon-help-large() {
  &:extend(.mooicon);
  content: @mooicon-help-large;
}

.mooicon-help-large:before {
  .mooicon-help-large();
}

.mooicon-help() {
  &:extend(.mooicon);
  content: @mooicon-help;
}

.mooicon-help:before {
  .mooicon-help();
}

.mooicon-info-circle() {
  &:extend(.mooicon);
  content: @mooicon-info-circle;
}

.mooicon-info-circle:before {
  .mooicon-info-circle();
}

.mooicon-info-large() {
  &:extend(.mooicon);
  content: @mooicon-info-large;
}

.mooicon-info-large:before {
  .mooicon-info-large();
}

.mooicon-info() {
  &:extend(.mooicon);
  content: @mooicon-info;
}

.mooicon-info:before {
  .mooicon-info();
}

.mooicon-instagram() {
  &:extend(.mooicon);
  content: @mooicon-instagram;
}

.mooicon-instagram:before {
  .mooicon-instagram();
}

.mooicon-list-2() {
  &:extend(.mooicon);
  content: @mooicon-list-2;
}

.mooicon-list-2:before {
  .mooicon-list-2();
}

.mooicon-list-3() {
  &:extend(.mooicon);
  content: @mooicon-list-3;
}

.mooicon-list-3:before {
  .mooicon-list-3();
}

.mooicon-minus-small() {
  &:extend(.mooicon);
  content: @mooicon-minus-small;
}

.mooicon-minus-small:before {
  .mooicon-minus-small();
}

.mooicon-minus() {
  &:extend(.mooicon);
  content: @mooicon-minus;
}

.mooicon-minus:before {
  .mooicon-minus();
}

.mooicon-more() {
  &:extend(.mooicon);
  content: @mooicon-more;
}

.mooicon-more:before {
  .mooicon-more();
}

.mooicon-orders-alt() {
  &:extend(.mooicon);
  content: @mooicon-orders-alt;
}

.mooicon-orders-alt:before {
  .mooicon-orders-alt();
}

.mooicon-orders() {
  &:extend(.mooicon);
  content: @mooicon-orders;
}

.mooicon-orders:before {
  .mooicon-orders();
}

.mooicon-packs() {
  &:extend(.mooicon);
  content: @mooicon-packs;
}

.mooicon-packs:before {
  .mooicon-packs();
}

.mooicon-people() {
  &:extend(.mooicon);
  content: @mooicon-people;
}

.mooicon-people:before {
  .mooicon-people();
}

.mooicon-pinterest-circle() {
  &:extend(.mooicon);
  content: @mooicon-pinterest-circle;
}

.mooicon-pinterest-circle:before {
  .mooicon-pinterest-circle();
}

.mooicon-pinterest() {
  &:extend(.mooicon);
  content: @mooicon-pinterest;
}

.mooicon-pinterest:before {
  .mooicon-pinterest();
}

.mooicon-play-fill() {
  &:extend(.mooicon);
  content: @mooicon-play-fill;
}

.mooicon-play-fill:before {
  .mooicon-play-fill();
}

.mooicon-play() {
  &:extend(.mooicon);
  content: @mooicon-play;
}

.mooicon-play:before {
  .mooicon-play();
}

.mooicon-plus-small() {
  &:extend(.mooicon);
  content: @mooicon-plus-small;
}

.mooicon-plus-small:before {
  .mooicon-plus-small();
}

.mooicon-plus() {
  &:extend(.mooicon);
  content: @mooicon-plus;
}

.mooicon-plus:before {
  .mooicon-plus();
}

.mooicon-print() {
  &:extend(.mooicon);
  content: @mooicon-print;
}

.mooicon-print:before {
  .mooicon-print();
}

.mooicon-qr() {
  &:extend(.mooicon);
  content: @mooicon-qr;
}

.mooicon-qr:before {
  .mooicon-qr();
}

.mooicon-receipt() {
  &:extend(.mooicon);
  content: @mooicon-receipt;
}

.mooicon-receipt:before {
  .mooicon-receipt();
}

.mooicon-rotate() {
  &:extend(.mooicon);
  content: @mooicon-rotate;
}

.mooicon-rotate:before {
  .mooicon-rotate();
}

.mooicon-rss() {
  &:extend(.mooicon);
  content: @mooicon-rss;
}

.mooicon-rss:before {
  .mooicon-rss();
}

.mooicon-safe-area() {
  &:extend(.mooicon);
  content: @mooicon-safe-area;
}

.mooicon-safe-area:before {
  .mooicon-safe-area();
}

.mooicon-save() {
  &:extend(.mooicon);
  content: @mooicon-save;
}

.mooicon-save:before {
  .mooicon-save();
}

.mooicon-search() {
  &:extend(.mooicon);
  content: @mooicon-search;
}

.mooicon-search:before {
  .mooicon-search();
}

.mooicon-sort-down() {
  &:extend(.mooicon);
  content: @mooicon-sort-down;
}

.mooicon-sort-down:before {
  .mooicon-sort-down();
}

.mooicon-sort-up() {
  &:extend(.mooicon);
  content: @mooicon-sort-up;
}

.mooicon-sort-up:before {
  .mooicon-sort-up();
}

.mooicon-sort() {
  &:extend(.mooicon);
  content: @mooicon-sort;
}

.mooicon-sort:before {
  .mooicon-sort();
}

.mooicon-tick-small() {
  &:extend(.mooicon);
  content: @mooicon-tick-small;
}

.mooicon-tick-small:before {
  .mooicon-tick-small();
}

.mooicon-tick() {
  &:extend(.mooicon);
  content: @mooicon-tick;
}

.mooicon-tick:before {
  .mooicon-tick();
}

.mooicon-trim() {
  &:extend(.mooicon);
  content: @mooicon-trim;
}

.mooicon-trim:before {
  .mooicon-trim();
}

.mooicon-twitter-t() {
  &:extend(.mooicon);
  content: @mooicon-twitter-t;
}

.mooicon-twitter-t:before {
  .mooicon-twitter-t();
}

.mooicon-twitter() {
  &:extend(.mooicon);
  content: @mooicon-twitter;
}

.mooicon-twitter:before {
  .mooicon-twitter();
}

.mooicon-upload() {
  &:extend(.mooicon);
  content: @mooicon-upload;
}

.mooicon-upload:before {
  .mooicon-upload();
}

.mooicon-users() {
  &:extend(.mooicon);
  content: @mooicon-users;
}

.mooicon-users:before {
  .mooicon-users();
}

.mooicon-view-fill() {
  &:extend(.mooicon);
  content: @mooicon-view-fill;
}

.mooicon-view-fill:before {
  .mooicon-view-fill();
}

.mooicon-view() {
  &:extend(.mooicon);
  content: @mooicon-view;
}

.mooicon-view:before {
  .mooicon-view();
}

.mooicon-zoom-in() {
  &:extend(.mooicon);
  content: @mooicon-zoom-in;
}

.mooicon-zoom-in:before {
  .mooicon-zoom-in();
}

.mooicon-zoom-out() {
  &:extend(.mooicon);
  content: @mooicon-zoom-out;
}

.mooicon-zoom-out:before {
  .mooicon-zoom-out();
}
