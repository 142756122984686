@import "moo-toolkit/src/less/lists.less";
@import "moo-toolkit/src/less/breadcrumbs.less";
@import (reference) "variables.less";

.breadcrumbs {
    ol,
    ul {
        font-size: @font-size-small;
        color: @brand-black;
        font-family: @font-family-verdana;

        > li {
            float: none;
            display: inline-block;
        }

        header & {
            line-height: 65px;
            margin-bottom: 0;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
        }
    }

}