.checkout-details {
    .separator > .row > [class^="col-"],
    > .row > [class^="col-"] {
        padding-top: @vertical-space-base * 0.5;
        padding-bottom: @vertical-space-base;
    }
    .payment-method-choice {
        padding-bottom: @vertical-space-base * 2;
    }

    .payment-method-views {
        // correct margin added by form-group
        .custom-checkbox {
            margin-left: 5px;
        }
    }

    .control-label {
        .make-sm-column(3);
    }

    .order-summary .and-packaging {
        .hidden;
    }

    .stored-tokens {
        .new-card-container {
            iframe {
                margin-top: @padding-base-vertical;
            }
        }
    }

    .row.disabled {
        display:none;
    }

    .billing-address-selector {
        margin-top: @vertical-space-base * 2;
        label {
            margin-bottom: @vertical-space-base;
        }
    }

    .has-error .selectpicker {
        border-color: #ee2737;
    }

    .has-error .selectpicker .caret {
        color: #ee2737;
    }

    .has-error .form-control.bootstrap-select {
        padding-right: 0px;
    }
}

.checkout-confirmation {
    .cart-confirmation;
}

.stored-tokens {
    .radio, .checkbox {
        padding-left: 0
    }
}

.btn-complete-checkout {
    float: right;
}

