//
// Panels
// --------------------------------------------------


// Base class
.panel {
    margin-bottom: @line-height-computed;
    background-color: @body-bg;
    border: 2px solid @gray-light;
    border-radius: @border-radius-large;
    padding: @med-obj-padding;
    display: block;
    // when floating / fixed, force it above other elements on the page (e.g. footer)
    z-index: 1;

    .col-key {
        float: left;
    }

    .col-value {
        float: right;
    }
}

// Panel contents
.panel-body {
  padding: 15px;
  .clearfix();
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.

.panel {
  > .table,
  > .table-responsive {
    margin-bottom: 0;
  }
  > .panel-body + .table,
  > .panel-body + .table-responsive {
    border-top: 1px solid @table-border-color;
  }
  > .table-bordered,
  > .table-responsive > .table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }

        &:last-child > th,
        &:last-child > td {
          border-bottom: 0;
        }
      }
    }
  }
}


// Optional heading
.panel-heading {
    .separator-horizontal-thick;
}

// Within heading, strip any `h*` tag of it's default margins for spacing.
.panel-title {
    margin-top: 0;
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
    .separator-horizontal(~":before");

    dl, dt, dd {
        margin-top: 0;
        margin-bottom: 0;
    }
}


// Collapsable panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  // Tighten up margin so it's only between panels
  .panel {
    margin-bottom: 0;
    border-radius: @panel-border-radius;
    overflow: hidden; // crop contents when collapsed
    + .panel {
      margin-top: 5px;
    }
  }

  .panel-heading {
    border-bottom: 0;
    + .panel-collapse .panel-body {
      border-top: 1px solid @panel-inner-border;
    }
  }
  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid @panel-inner-border;
    }
  }
}

// Contextual variations
.panel-default {
  .panel-variant(@panel-default-border; @panel-default-text; @panel-default-heading-bg; @panel-default-border);
}
.panel-primary {
  .panel-variant(@panel-primary-border; @panel-primary-text; @panel-primary-heading-bg; @panel-primary-border);
}
.panel-success {
  .panel-variant(@panel-success-border; @panel-success-text; @panel-success-heading-bg; @panel-success-border);
}
.panel-warning {
  .panel-variant(@panel-warning-border; @panel-warning-text; @panel-warning-heading-bg; @panel-warning-border);
}
.panel-danger {
  .panel-variant(@panel-danger-border; @panel-danger-text; @panel-danger-heading-bg; @panel-danger-border);
}
.panel-info {
  .panel-variant(@panel-info-border; @panel-info-text; @panel-info-heading-bg; @panel-info-border);
}



.panel-callout {
    padding-top: 0;
    padding-bottom: 0;

    .custom-checkbox {
        display: inline-block;
        min-height: 1px;
    }
}