@user-separator-gutter: 15px;
@user-col-height: 30px;

.user-summary {
    margin-top: @user-separator-gutter;

    .user-status {
        padding-bottom: @vertical-space-2;
    }

    & + &,
    &.user-separator {
        margin-top: 0;
        .separator-horizontal(~":before");
        
        &:before {
            margin-top: @user-separator-gutter;
            margin-bottom: @user-separator-gutter;
        }
    }

    .h3 {
        margin-bottom: @vertical-space-2;
    }
    
    .col-key {
        line-height: @user-col-height;
    }

    .col-value {
        line-height: @user-col-height;

        .label {
            margin-right: @grid-gutter-width / 2;
            margin-top: -3px;
        }
    }

    .row-vertical-middle {
        margin-bottom: 20px;
    }
}

.user-summary-delete {
    display: inline-block;
}

.user-make-primary {
    margin-left: @grid-gutter-width / 2;
}

.user-status {
    margin-bottom: @vertical-space-2;
}

.user-tooltip-container {
    .tooltip-inner {
        max-width: 406px;
    }
}