.help-account-manager-info {
    margin-top: @vertical-space-7;
}

.help-qa-list {
    padding: 0;

    > li {
        background: url(../images/help-q.png) no-repeat left 2px;
        list-style: none;
        padding: 0 0 30px 45px;
    }

    .separator-horizontal-thick;
}

.help-account-manager-info {
    margin-top: @vertical-space-4;

    h2 {
        margin-top: 0;
        font-size: @font-size-medium;
        font-weight: bold;
    }
}