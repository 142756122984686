// Transparent tour background pseudo element
.tour-backdrop {
    // Use &nbsp; as content so we can inherit line height and padding
    // to set the correct height without hard coding it
    content: "\00a0";
    background-color: rgba(0,0,0,0);
    position: fixed;
    top: 0;
    left: 0;
    padding: inherit;
}

// Element to cover up and block click events on the whole page
.tour-blocker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @zindex-tour - 1;
    // Without a solid background color or image IE will allow click through
    background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

// The tour bg fades in when the class tour-running is added
// position the pseudo elements using the has-tour class so
// when tour-running is added they are already in place and transparent
body.has-tour {
    .user-select(none);

    header,
    .nav-container,
    .main {
        &:before {
            .transition(background-color @transition-speed linear);
            .tour-backdrop;
        }
    }

    .nav-container:before {
        z-index: @zindex-side-navbar + 1;
        width: @nav-desktop-fixed-width;
        height: 100%;
    }

    header:before {
        z-index: @zindex-header + 1;
        left: 0;
        right: 0;
        bottom: -@nav-shadow-v;
        position: absolute;
    }

    .main:before {
        z-index: @zindex-header - 1;
        left: @nav-desktop-fixed-width;
        right: 0;
        bottom: 0;
    }
}

.mobile-tour-layout {
    .nav-container:before {
        width: @nav-mobile-fixed-width;
    }

    header:before {
        left: @nav-mobile-fixed-width;
    }

    .main:before {
        left: @nav-mobile-fixed-width;
    }
}

// Mobile nav has different positioning. Handle that here
@media (max-width: @screen-md) {
    body.has-tour {
        .mobile-tour-layout;
    }
}

body.has-tour.faux-mobile-view {
    .mobile-tour-layout;
}


// When the tour is running adjust the opacity of the background
body.tour-running {
    header,
    .nav-container,
    .main {
        &:before {
            background-color: rgba(0,0,0,@modal-opacity);
        }
    }

    // For the highlighted tour step increase the z-index so
    // it becomes visible.
    .tour-active {
        position: relative;
        z-index: @zindex-tour;
        .user-select(initial);
    }

    // Remove menu navigation arrows for tour
    .nav-container .nav .active:after {
        content: none;
    }
}

// Styling for the tour popover
.popover[class*="tour-"] {
    .user-select(initial);
    z-index: @zindex-tour;
    width: 480px;

    .popover-title {
        font-size: @font-size-h2;
        padding: @popover-inner-padding;
        border: none;
    }

    .popover-content {
        padding: 5px @popover-inner-padding 10px @popover-inner-padding;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .popover-navigation {
        text-align: right;
        padding: 15px;

        *[data-role="end"] {
            float: none;
        }

        .tour-finish {
            display: none;
        }
    }
}
