@import (reference) "moo-toolkit/src/less/variables/variables.less";

.account-create {
    .control-label {
        .make-sm-column(3);
    }
    .form-input-col {
        .make-sm-column(9);
    }
    .form-button-col {
        float: none;
        margin-left: 0;
        width: 95px;
    }
    .btn-back {
        margin-top: 13px;
        display: block;
    }

    .form-input-col .horizontal-choice .horizontal-choice-item {
        width: 50%;
    }

}
