//
// Button groups
// --------------------------------------------------

// Button carets
//
// Match the button text color to the arrow/caret for indicating dropdown-ness.

.caret {
    .btn-default & {
        border-top-color: @btn-default-color;
    }
    .btn-primary &,
    .btn-success &,
    .btn-warning &,
    .btn-danger &,
    .btn-info & {
        border-top-color: #fff;
    }
}

.dropup {
    .btn-default .caret {
        border-bottom-color: @btn-default-color;
    }
    .btn-primary,
    .btn-success,
    .btn-warning,
    .btn-danger,
    .btn-info {
        .caret {
            border-bottom-color: #fff;
        }
    }
}

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle; // match .btn alignment given font-size hack above
    > .btn {
        position: relative;
        float: left;
        // Bring the "active" button to the front
        &:hover,
        &:focus,
        &:active,
        &.active {
            z-index: 2;
        }
        &:focus {
            // Remove focus outline when dropdown JS adds it after closing the menu
            outline: none;
        }
    }
}

// Prevent double borders when buttons are next to each other
.btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: -1px;
    }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
    .clearfix();

    .btn-group {
        float: left;
    }
    // Space out series of button groups
    > .btn,
    > .btn-group {
        + .btn,
        + .btn-group {
            margin-left: 5px;
        }
    }
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
    margin-left: 0;
    &:not(:last-child):not(.dropdown-toggle) {
        .border-right-radius(0);
    }
}

// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
    .border-left-radius(0);
}

// Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
.btn-group > .btn-group {
    float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
}

.btn-group > .btn-group:first-child {
    > .btn:last-child,
    > .dropdown-toggle {
        .border-right-radius(0);
    }
}

.btn-group > .btn-group:last-child > .btn:first-child {
    .border-left-radius(0);
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline: 0;
}

// Split button dropdowns
// ----------------------

// Give the line between buttons some depth
.btn-group > .btn + .dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px;
}

// The clickable button for toggling the menu
// Remove the gradient and set the same inset shadow as the :active state
.btn-group.open .dropdown-toggle {
    .box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));

    // Show no shadow for `.btn-link` since it has no other button styles.
    &.btn-link {
        .box-shadow(none);
    }
}

// Reposition the caret
.btn .caret {
    margin-left: 0;
}

// Vertical button groups
// ----------------------

.btn-group-vertical {
    > .btn,
    > .btn-group {
        display: block;
        float: none;
        width: 100%;
        max-width: 100%;
    }

    // Clear floats so dropdown menus can be properly placed
    > .btn-group {
        .clearfix();
        > .btn {
            float: none;
        }
    }

    > .btn + .btn,
    > .btn + .btn-group,
    > .btn-group + .btn,
    > .btn-group + .btn-group {
        margin-top: -1px;
        margin-left: 0;
    }
}

.btn-group-vertical > .btn {
    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
    &:first-child:not(:last-child) {
        border-top-right-radius: @border-radius-base;
        .border-bottom-radius(0);
    }
    &:last-child:not(:first-child) {
        border-bottom-left-radius: @border-radius-base;
        .border-top-radius(0);
    }
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child {
    > .btn:last-child,
    > .dropdown-toggle {
        .border-bottom-radius(0);
    }
}

.btn-group-vertical > .btn-group:last-child > .btn:first-child {
    .border-top-radius(0);
}

// Justified button groups
// ----------------------

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    .btn {
        float: none;
        display: table-cell;
        width: 1%;
    }
}

// Checkbox and radio options
[data-toggle="buttons"] > .btn > input[type="radio"],
[data-toggle="buttons"] > .btn > input[type="checkbox"] {
    display: none;
}
