//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------
.clear {
    clear: both;
}

.clear-left {
    clear: left;
}

.clearfix {
    .clearfix();
}
.center-block {
    .center-block();
}
.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
    display: none !important;
}
.show {
    display: block !important;
}
.invisible {
    visibility: hidden;
}
.text-hide {
    .text-hide();
}

.offscreen {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    display: block;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

// For Affix plugin
// -------------------------

.affix {
    position: fixed;
}

// Separators & dividers
// -------------------------
.separator {
    .separator-horizontal();
}

.separator-thick {
    .separator-horizontal-thick();
}

.separator-thicker {
    .separator-horizontal-thicker();
}

.separator-right {
    border-right: 2px dotted @brand-pastel-gray;
}

.separator-left {
    border-left: 2px dotted @brand-pastel-gray;
}

.separator-top {
    .separator-horizontal(~":before");
}

// Vertical seperations
// -------------------------
.bottom-vertical-7 {
    margin-bottom: @vertical-space-7;
}

// Vertical Alignment
// -------------------------
.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

// MOO Sprite
// -------------------------
[class*='moosprite-'] {
  font-size: 10px;
  vertical-align: middle;
}