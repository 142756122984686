.step-indicator {
    .list-unstyled;
    .clearfix();
    counter-reset: li; // Initiate a counter
    text-align: center;
    padding: @vertical-space-1 0 @vertical-space-2;

    > li {
        display: inline-block;
        font-family: @font-family-bryant;
        text-align: center;
        padding: 0 @step-padding;
        position: relative;
        
        // line between steps
        &:before {
            content: " ";
            border-bottom: @step-border-width solid @gray;
            display: block;
            width: @step-separator-width; // width of line
            left: -1 * (@step-separator-width / 2); // align lign in the middle
            top: @step-padding;
            position: absolute;
        }
    }

    > li:first-child {
        padding-left: 0;

        &:before {
            display: none;
        }
    }
}

.step-title {
    color: @gray;
    font-size: @step-label-font-size;
    font-family: @font-family-base;
    display: block;

    .active > & {
        color: @gray-darker;
        &:before{
            // Default font is the icon font, reset to get numbers
            font-family: @font-family-base;
        }
    }
}

.step-indicator .active.complete ~ li .step-title:before,
.step-title:before {
    
    &,
    .active.complete > & {
        counter-increment: li;

        .mooicon;
        content: @mooicon-tick-small;

        display: block;
        margin: 0 auto 10px;
        height: @step-circle-size;
        width: @step-circle-size;
        line-height: (@step-circle-size) - (@step-border-width * 2); // vertical align
        
        background-color: @brand-dark-green;
        border: @step-border-width solid @brand-dark-green;
        border-radius: @step-border-radius;
        color: #fff;
        
        font-size: @step-count-font-size;
        text-align: center;

    }

    .active > & {
        background-color: @gray-darker;
        border-color: @gray-darker;
        color: #fff;
        content: counter(li);
    }
}

.step-indicator .active ~ li .step-title:before {
    background-color : #fff;
    border-color: @gray;
    color: @gray;
    content: counter(li);
    // Default font is the icon font, reset to get numbers
    font-family: @font-family-base;
}
