@minimal-content-width: 537px;
@minimal-content-margin-vertical: 35px;
@minimal-content-padding-horizontal: 40px;
@minimal-content-padding-vertical: 55px;
@moods-grey-01: #F7F7F7;
@moods-grey-06: #545454;
@moods-grey-07: #30373B;

.m4b-minimal {
    background: @moods-grey-01;
    color: #ffffff;
    text-align: center;
    display: table;
    width: 100%;

    h1, .h1 {
        color: @moods-grey-07;
        font-size: @font-size-h3;
        margin: 0;
    }

    h2, .h2 {
        color: @moods-grey-07;
    }

    .content {
        width: @minimal-content-width;
        padding: @minimal-content-padding-vertical @minimal-content-padding-horizontal;
        margin: @minimal-content-margin-vertical auto 20px;

        position: relative;

        background: #ffffff;
        border-radius: @border-radius-base;

        color: @gray-darker;
        text-align: left;

        -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);

        .policy-container {
            background: @moods-grey-01;
            padding: 18px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
                font-size: @font-size-h6;
                font-weight: bold;
                margin: 10px;
            }

            .text {
                margin: 10px;
            }

            .options {
                .form-group {
                    margin-bottom: 0;

                    .form-input-col {
                        width: 100%;

                        input[type="radio"] {

                            ~ .icon:before {
                                height: 20px !important;
                                width: 20px !important;
                            }

                            ~ .icon:after {
                                top: 4px !important;
                                left: 4px !important;
                            }
                        }
                    }
                }
            }

            div[id^=m4b_enter_public_invite_form] {
                display: flex;
                justify-content: flex-start;

                .horizontal-choice-item {
                    padding-right: 36px;
                    padding-left: 0;
                }
            }
        }

        .text-link.btn-back {
            float: right !important;
        }
    }

    &.minimal-padding {
        .content {
            padding: 10px @minimal-content-padding-horizontal @minimal-content-padding-vertical;
        }
    }
}

.m4b-minimal-centred {
    display: table-cell;
    vertical-align: middle;
}
