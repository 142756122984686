 //
// Typography
// --------------------------------------------------

.underline{
    text-decoration: underline;
}

// Body text
// -------------------------

p {
    margin: 0 0 (@line-height-computed / 2);
    line-height: 1.667em;
}

.lead {
    margin-bottom: @line-height-computed;
    font-family: @font-family-bryant;
    font-size: @font-size-lead;
    line-height: 1.4;
    color: #000;
}

.strike {
    text-decoration: line-through;
}

// Mixin for strikethrough text with a line centered no matter where the font middle line is
// Caveat: limited to a single line
.centered-strike {
    text-decoration: none;
    position: relative;
    white-space: no-wrap;

    &:after {
        content: "";
        border-top: 1px solid;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
    }
}


// Emphasis & misc
// -------------------------

// Ex: 14px base font * 85% = about 12px
small,
.small  { font-size: 85%; }

// Undo browser default styling
cite    { font-style: normal; }

// Contextual emphasis
.text-muted {
    color: @text-muted !important;
}
.text-primary {
    color: @brand-primary !important;
}
.text-warning {
    color: @state-warning-text !important;
}
.text-danger {
    color: @state-danger-text !important;
}
.text-success {
    color: @state-success-text !important;
}
.text-info {
    color: @state-info-text !important;
}

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }


.text-link {
    // reset
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    display: inline;

    cursor: pointer;
    color: @link-color;

    &:hover {
        text-decoration: underline;
    }

}

.text-regular {
    font-family: @font-family-base;
    font-weight: normal;
    font-size: @font-size-base;
    margin: 0 0 (@line-height-computed / 2);
    line-height: 1.667em;
}

// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.m4b-page h1, .m4b-page h2, .m4b-page h3, .m4b-page h4, .m4b-page h5, .m4b-page h6 {
    font-family: @headings-font-family;
    font-weight: @headings-font-weight;
    line-height: @headings-line-height;
    color: @headings-color;

    small,
    .small {
        font-weight: normal;
        line-height: 1;
        color: @headings-small-color;
    }
}

h1,
h2,
h3 {
    margin-top: @line-height-computed;
    margin-bottom: (@line-height-computed / 2);

    small,
    .small {
        font-size: 65%;
    }
}
h4,
h5,
h6 {
    margin-top: (@line-height-computed / 2);
    margin-bottom: (@line-height-computed / 2);

    small,
    .small {
        font-size: 75%;
    }
}

h1, .h1, .m4b-page h1 { font-size: @font-size-h1; }
h2, .h2, .m4b-page h2  { font-size: @font-size-h2; }
h3, .h3, .m4b-page h3  { font-size: @font-size-h3; }
h4, .h4, .m4b-page h4 { font-size: @font-size-h4; }
h5, .h5, .m4b-page h5 { font-size: @font-size-h5; }
h6, .h6, .m4b-page h6 { font-size: @font-size-h6; }
h6, .h6 {
    font-family: @font-family-base;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
p {
    &.no-leading {
        margin-top: 0;
    }

    &.no-trailing {
        margin-bottom: 0;
    }
}

// Page header
// -------------------------

.page-header {
    padding: 0 0 @vertical-space-2;

    h1,
    .h1 {
        margin: 0;
        line-height: 1;
        padding: 0 0 @vertical-space-3;
    }
}

.page-header-separated,
.page-header-separated-inline {
    padding: 0;

    .separator-horizontal();

    h1,
    .h1 {
        margin: 4px 0 @separator-gutter-height;
    }
}

.page-header,
.page-header-separated,
.page-header-separated-inline {
    text-align: right;

    // clearfix solution instead of overflow hidden to stop the clipping of info icon
    &:after {
        content: " ";
        display: block;
        clear: both;
    }

    h1,
    .h1 {
        float: left;
    }
}

// This is needed to support more than one button in the header area without the buttons vertically stacking
// Instead the buttons take up as much space as they want in the .actions element and the title wraps around them.
.page-header-multibutton {
    text-align: left;

    h1, .h1 {
        float: none;
        display: inline;
    }

    .actions {
        float: right;
        // The margin bottom here is required as the page header block :after that provides the padding
        // at the bottom of the element is not effective with floats
        margin-bottom: @vertical-space-2;
    }
}


// Lists
// --------------------------------------------------

// Unordered and Ordered lists
ul,
ol {
    margin-top: 0;
    margin-bottom: (@line-height-computed / 2);
    ul,
    ol {
        margin-bottom: 0;
    }
}

.list-pretty{
    @icon-size: 17px;

    list-style:none;
    padding-left: @icon-size;

    li{
        position: relative;

        &:before{
            .mooicon;
            content: @mooicon-circle-fill-carousel;
            color: @brand-dark-green;

            font-size: 0.75em;

            text-shadow: -1px 0 @brand-pastel-gray, 0 1px @brand-pastel-gray, 1px 0 @brand-pastel-gray, 0 -1px @brand-pastel-gray;
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);

            position: absolute;
            top: 4px;
            left: -17px;
        }
    }
}

// List options

// Unstyled keeps list items block level, just removes default browser padding and list-style
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-unstyled-spaced > li {
    margin-bottom: 0.3em;

    &:last-child {
        margin-bottom: 0;
    }
}

// Inline turns list items into inline-block
.list-inline {
    .list-unstyled();

    > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;

        &:first-child {
            padding-left: 0;
        }
    }
}

// Description Lists
dl {
    margin-bottom: @line-height-computed;
}
dt,
dd {
    line-height: @line-height-base;
}
dd {
    margin-left: 0; // Undo browser default
}

.dl-row {
    .clearfix();

    > dt,
    > dd {
        float: left
    }

    > dt {
        clear: left;
    }
}

.fluid-attributes {
    overflow: hidden;
    margin-bottom: 0;
    margin-top: 0;

    dt,
    dd {
        margin: @vertical-space-1 0;
    }

    dt {
        clear: left;
        float: left;
    }

    dd {
        float: right;
        text-align: right;
    }
}

// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).

@media (min-width: @grid-float-breakpoint) {
    .dl-horizontal {
        dt {
            float: left;
            width: (@component-offset-horizontal - 20);
            clear: left;
            text-align: right;
            .text-overflow();
        }
        dd {
            margin-left: @component-offset-horizontal;
            .clearfix(); // Clear the floated `dt` if an empty `dd` is present
        }
    }
}

// MISC
// ----

// Abbreviations and acronyms
abbr[title],
// Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted @abbr-border-color;
}
abbr.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

// Blockquotes
blockquote {
    padding: (@line-height-computed / 2) @line-height-computed;
    margin: 0 0 @line-height-computed;
    border-left: 5px solid @blockquote-border-color;
    p {
        font-size: (@font-size-base * 1.25);
        font-weight: 300;
        line-height: 1.25;
    }
    p:last-child {
        margin-bottom: 0;
    }
    small {
        display: block;
        line-height: @line-height-base;
        color: @blockquote-small-color;
        &:before {
            content: '\2014 \00A0'; // EM DASH, NBSP
        }
    }

  // Float right with text-align: right
    &.pull-right {
        padding-right: 15px;
        padding-left: 0;
        border-right: 5px solid @blockquote-border-color;
        border-left: 0;
        p,
        small,
        .small {
            text-align: right;
        }
        small,
        .small {
            &:before {
                content: '';
            }
            &:after {
                content: '\00A0 \2014'; // NBSP, EM DASH
            }
        }
    }
}

// Quotes
blockquote:before,
blockquote:after {
    content: "";
}

// Addresses
address {
    margin-bottom: @line-height-computed;
    font-style: normal;
    line-height: @line-height-base;
}

.address {
    &.address-block {
        .address-line {
            display: block;
        }
    }
}

.original-price {
    text-decoration: line-through;
    color: @gray-dark;
    margin-right: @price-horizontal-space;

    .shipping-method .product-price & {
        display: inline-block;
        margin-bottom: 3px;
    }
}

.actual-price {
    text-decoration: none;
}

.text-right {
    .original-price {
        margin-right: 0;
    }

    .actual-price {
        margin-left: @price-horizontal-space;
    }
}

.page-header-separated-inline {
        div, form {
            display: inline;
        }
}
