.pack-details {
    dl {
        // use padding instead of margin to avoid margin collapse
        margin-top: 0;
        margin-bottom: 0;
        padding-top: (@vertical-space-base * 0.5) 0;

        > dd,
        > dt {
            line-height: 32px;
        }

        .m4b-icon {
            margin-left: 10px;
        }
    }

    // Use a span inside the pack name to contain just the text
    // The :after allows the span to not be inline-block which will clear the button
    .pack-name span:after {
        display: inline-block;
        content: " ";
        width: 35px;
    }

    .preview-control {
        padding: @med-preview-control-padding 0;

        .h3 {
            margin-bottom: @med-preview-control-padding;
        }
    }

    .preview-control-footer {
        padding-top: @med-preview-control-padding;
    }
}

.pack-detail-options {
    .separator;

    .paper-quantity {
        margin-top: 7px;
    }

    .paper-quantity-wrapper {
        .make-sm-column(3);
    }

    .paper-quantity-label {
        width: auto;
        position: static;
        height: auto;
        float: left;
        line-height: 44px;
        margin-right: 18px;
    }

    .paper-summary {
        .make-sm-column(5);
        margin-top: 7px;
    }

    .product-price-old {
        margin-top: 1px;
    }
    
    .product-price {
        padding-bottom: 0;
    }
}

.pack-name-edit {
    display: none;
    margin-top: -7px;
    margin-left: -14px;

    .form-inline {
        .form-group {
            padding-right: 0;
            margin-left: 0;
            margin-right: 4px;
        }

        .form-input-col {
            padding-right: 0;
            padding-left: 0;
            width: auto;
        }

        .form-button-col {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
        }
    }

    #m4b_pack_details_form {
        text-align: right;
        width: 350px;
        
        .form-input-col {
            padding: 0;
            width: 100%;
        }

        .form-group-btn {
            display: inline-block;
            margin-right: -11px;
        }
    }
}

