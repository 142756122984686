//
// Forms
// --------------------------------------------------

form {
    .form-horizontal;
}

.form-input-col {
    .make-sm-column(10);

    .horizontal-choice {
        .row;
        .horizontal-choice-item {
            .make-sm-column(4);
        }
    }
}

.control-label {
    .make-sm-column(2);
}

.form-button-col {
    .make-sm-column(10);
    .make-sm-column-offset(2);
}

.form-info:empty {
    display: none;
}

.form-info > .help-block {
    .make-sm-column(10);
    .make-sm-column-offset(2);

    color: @brand-red;
}

// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    font-size: (@font-size-base * 1.5);
    line-height: inherit;
    color: @legend-color;
    border: 0;
}

label {
    display: inline-block;
    margin-bottom: 5px;
}

// Normalize form controls

// Override content-box in Normalize (* isn't specific enough)
input[type="search"] {
    .box-sizing(border-box);
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9; /* IE8-9 */
    line-height: normal;
}

// Set the height of select and file controls to match text inputs
input[type="file"] {
    display: block;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
    height: auto;
}

// Fix optgroup Firefox bug per https://github.com/twbs/bootstrap/issues/7611
select optgroup {
    font-size: inherit;
    font-style: inherit;
    font-family: inherit;
}

// Focus for select, file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    .tab-focus();
}

// Fix for Chrome number input
// Setting certain font-sizes causes the `I` bar to appear on hover of the bottom increment button.
// See https://github.com/twbs/bootstrap/issues/8350 for more.
input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        height: auto;
    }
}

// Adjust output element
output {
    display: block;
    padding-top: (@padding-base-vertical + 1);
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;
    vertical-align: middle;
}

// remove ugly red glows from firefox
input:required {
    box-shadow: none;
}

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-control {
    display: block;
    width: 100%;
    height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;
    vertical-align: middle;
    background-color: @input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid @input-border;
    border-radius: @input-border-radius;
    .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

    // Customize the `:focus` state to imitate native WebKit styles.
    .form-control-focus();

    // Placeholder
    //
    // Placeholder text gets special styles because when browsers invalidate entire
    // lines if it doesn't understand a selector/
    .placeholder();

    // Disabled and read-only inputs
    // Note: HTML5 says that controls under a fieldset > legend:first-child won't
    // be disabled if the fieldset is disabled. Due to implementation difficulty,
    // we don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        background-color: @input-bg-disabled;
    }
    &[readonly] {
        background-color: @input-bg-disabled;
    }

    // Reset height for `textarea`s
    textarea& {
        height: auto;
    }
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
    margin-bottom: 20px;
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
    display: block;
    min-height: @line-height-form-row; // clear the floating input if there is no label text
    padding-left: 20px;
    vertical-align: middle;
    label {
        display: inline;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
    }
}

//Suppression of some rules above for tables
table {
    .checkbox {
        min-height: 30px;
        margin-top: @vertical-space-3 * -1;
        margin-bottom: 0;
        top: @vertical-space-1;
        left: @vertical-space-1 / 2;

        .oldie & {
            margin-top: 0;
        }
    }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    float: left;
    margin-right: @grid-gutter-width / 2;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px; // space out consecutive inline controls
}

// Apply same disabled cursor tweak as for inputs
//
// Note: Neither radios nor checkboxes can be readonly.
input[type="radio"],
input[type="checkbox"],
.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
    }
}

.control-form-separator {
    min-height: @line-height-form-row
}

// body:not(#foo) ensures IE8 ignores the custom styling
body:not(#foo) {

    .radio input[type="radio"],
    .radio-inline input[type="radio"],
    .checkbox input[type="checkbox"],
    .checkbox-inline input[type="checkbox"] {
        margin: 0 0 -15px;
    }

    .custom-radio,
    .custom-checkbox {
        position: relative;

        .icon {
            display: inline-block;
            position: relative;
            vertical-align: middle;
        }

        // Don't show the input but make it navigable and clickable
        input[type="radio"],
        input[type="checkbox"] {
            opacity: 0;
            float: left;
            position: relative;

            ~ .icon:before {
                content: " ";
                color: @gray-light;
                display: inline-block;
                line-height: 0.9em;
                font-size: 30px;
            }

            ~ .icon:before {
                margin-right: 10px;
            }
        }

        input[type=radio] {
            &:checked ~ .icon:after {
                content: " ";
                display: inline-block;
                font-size: 30px;
            }
        }

        input:checked ~ .icon:before {
            content: " ";
            color: @state-success-border;
        }

        input[disabled][type="checkbox"],
        input[disabled][type="checkbox"] ~ .icon:before {
            background-color: @brand-pastel-gray;
            cursor: default;
        }
    }

    .custom-checkbox,
    .custom-radio {
        padding-left: 0;
    }

    .custom-radio {
        input[type="radio"] ~ .icon:before, input[type="radio"] ~ .icon:after {
            .mooicon;
            vertical-align: middle;
            line-height: 1;
        }

        input[type="radio"] ~ .icon:before {
            border: 1px solid @gray-light;
            border-radius: 50%;
            width: 28px;
            height: 28px;
        }

        input[type="radio"]:checked ~ .icon:after {
            background-color: @brand-dark-green;
            position: absolute;
            top: 8px;
            left: 8px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        input:checked ~ .icon:before {
            border-color: @brand-gray;
        }
    }

    .custom-checkbox {
        line-height: @checkbox-size;

        input[type="checkbox"] ~ .icon:before {
            content: " ";

            width: @checkbox-size;
            height: @checkbox-size;
            line-height: @checkbox-size;

            float: left;
            border-radius: @border-radius-base;
            border: 1px solid @gray-light;
            background-color: @brand-white;
            color: @brand-dark-green;
            text-align: center;
            font-size: @checkbox-font-size;
        }

        input:checked ~ .icon:before {
            .mooicon;
            content: @mooicon-tick-small;
            border: 1px solid @brand-dark-green;
            background-color: @brand-white;
        }
    }
}

// Form control sizing
.input-sm {
    .input-size(@input-height-small; @padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}

.input-lg {
    .input-size(@input-height-large; @padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
}

// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.

// Warning
.has-warning {
    .form-control-validation(@state-warning-text; @state-warning-text; @state-warning-bg);
    .form-control-icon {
        &:before {
            .mooicon-exclamation();
        }
    }
}

// Error
.has-error {
    .form-control-validation(@state-danger-text; @state-danger-text; @state-danger-bg);
    .form-control-icon {
        &:before {
            .mooicon-circle-fill-carousel();
            font-size: 5px;
            top: 13px;
        }
    }
}

// Success
.has-success {
    .form-control-validation(@state-success-text; @state-success-text; @state-success-bg);

    .form-control-icon {
        &:before {
            .mooicon-tick();
        }
    }
}

// Static form control text
//
// Apply class to a `p` element to make any string of text align with labels in
// a horizontal form layout.

.form-control-static {
    margin-bottom: 0; // Remove default margin from `p`
}

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.help-block {
    font-size: @font-size-small;
    display: block; // account for any element using help-block
    margin-top: 5px;
    margin-bottom: 10px;
    color: lighten(@text-color, 25%); // lighten the text some for contrast

    .form-group & {
        display: none;
    }

    .form-group.has-error & {
        display: block;
    }
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
//
// Heads up! This is mixin-ed into `.navbar-form` in navbars.less.

.inline-form {
    display: inline-block;
}

.form-inline {

    // Kick in the inline
    @media (min-width: @screen-sm) {
        // Inline-block all the things for "inline"
        .form-group {
            display: inline-block;
            margin-bottom: 0;
            vertical-align: middle;
        }

        // In navbar-form, allow folks to *not* use `.form-group`
        .form-control {
            display: inline-block;
        }

        // Remove default margin on radios/checkboxes that were used for stacking, and
        // then undo the floating of radios and checkboxes to match (which also avoids
        // a bug in WebKit: https://github.com/twbs/bootstrap/issues/1969).
        .radio,
        .checkbox {
            display: inline-block;
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 0;
        }

        .radio input[type="radio"],
        .checkbox input[type="checkbox"] {
            float: none;
            margin-left: 0;
        }
    }
}

// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.form-horizontal {

    // Consistent vertical alignment of labels, radios, and checkboxes
    .control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: (@padding-base-vertical + 1); // Default padding plus a border
    }

    // Make form groups behave like rows
    .form-group {
        .make-row();
    }

    .form-control-static {
        padding-top: (@padding-base-vertical + 1);
    }

    // Only right align form labels here when the columns stop stacking
    @media (min-width: @screen-sm-min) {
        .control-label {
            text-align: left;
        }
    }
}

// Align labels left inputs right with
// No padding against it's container
.form-justified {
    margin-left: 0;
    margin-right: 0;
    padding: 0;

    label {
        padding: 0;
        text-align: left;
    }

    .form-input-col, .form-button-col {
        padding: 0;
    }

    .form-button-col {
        text-align: right;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    .form-button-col {
        float: right;
    }

    .form-button-col {
        margin-top: 20px;
    }
}

.required-help-text {
    color: @state-danger-text;
    font-family: @font-family-sans-serif;
    font-size: 27px;
    margin-right: 5px;
    line-height: 0;
    position: relative;
    top: 10px;
}

// Adds a 'red' asterik beside a label
// Has custom font treatment to make the character ledgable
.required:after {
    content: "*";
    color: @state-danger-text;
    font-weight: 100;
    font-family: @font-family-sans-serif;
    font-size: @font-size-medium;
    margin-left: 5px;
    line-height: 0;
}

.form-readonly {
    position: relative;

    .form-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        // ie8 support for an invisible element overlay
        background-color: #fff;
        .opacity(0);
    }

    input[type=text],
    select {
        border-color: transparent;
    }

    .form-control-styled-select {
        &:before {
            display: none;
        }
    }

    .form-button-col {
        display: none;
    }
}

.upload-control {
    display: inline-block;
    width: 500px;
    position: relative;

    &:hover {
        .btn {
            .btn-focus();
        }
    }

    &:active {
        .btn {
            &:active {
                background-color: @btn-default-bg-active;
            }
        }
    }

    .file-paths {
        display: inline-block;
        height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
        padding: @padding-base-vertical @padding-base-horizontal;
        font-size: @font-size-base;
        line-height: @line-height-base;
        color: @input-color;
        vertical-align: middle;
        background-color: @input-bg;
        background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
        border: 1px solid @input-border;
        border-radius: @input-border-radius;
        width: 367px;
        margin-right: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    input[type=file] {
        .opacity(0);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 1;
    }

    &.has-error {
        .file-paths {
            border-color: @state-danger-border;
            padding-right: 45px;
        }

        .form-control-icon {
            position: absolute;
            top: 0;
            left: 365px;
            &:after {
                position: static;
                margin-right: 0.5em;
            }
        }
    }
}

.input-action {
    position: relative;

    .action {
        position: absolute;
        right: 2px;
        top: 2px;
        bottom: 2px;
        vertical-align: middle;
        line-height: 1;
    }
}