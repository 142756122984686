.media-preview {
    .media-preview-main {
        margin-bottom: @med-obj-padding;
        height: @med-preview-img-width;
        width: @med-preview-img-width;
        background: @brand-gray-lighter url('/m4b/bundles/m4b/images/80px_spinner_grey.gif') no-repeat center;
        border-radius: @border-radius-base;
        border: @med-preview-border-width solid @brand-pastel-gray;

        display: table;

        img {
            margin: 0 auto;

            .rounded & {
                overflow: hidden;
                border-radius : @rounded-lg;
            }
        }

        .item {
            display: none;
            text-align: center;

            vertical-align: middle;
            text-align: center;

            &.selected,
            &.active {
                display: table-cell;
            }
        }
    }
}

.media-preview-controls {
    > .media-arrow {
        display: none;
    }
}

.media-preview-thumbnails {
    .clearfix();
    
    //reset styles
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;

    > .item {
        width: @med-preview-thumb-width + (@med-preview-border-width * 2);
        border-radius: @border-radius-base;
        text-align: center;
        cursor: pointer;
        float: left;

        .transition(opacity @transition-speed linear);

        &:hover {
            .opacity(0.5);
        }

        .item-container {
            background: #ffffff url('/m4b/bundles/m4b/images/48px_spinner.gif') no-repeat center;
            border: @med-preview-border-width solid @brand-pastel-gray;
            .rounded & {
                overflow: hidden;
                border-radius : @rounded-md;
            }
        }

        &.selected,
        &.active {
            .item-container {
                border-color: @brand-dark-green;
            }
        }

        &.landscape .item-container {
            width: @med-preview-thumb-width;
            min-height: 50px;
        }

        &.portrait .item-container {
            min-width: 50px;
            height: @med-preview-thumb-width;
            float: right;
        }
    }

}


// Modifiers
// -----------------

.media-preview-grid {
    @med-preview-box-size : 423px;

    .row;
    
    .media-preview-holder {
        .make-sm-column(6);
    }

    .media-preview-main {
        width: 100%;
        height: @med-preview-box-size;

        // large res get a full size image, lower res get a scaled down version to fit the column
        @media (max-width: @screen-lg) {
            img {
                width: auto;
                width: 100%;
            }
        }
    }

    .media-preview-controls {
        .make-sm-column(6);
    }
    
    .media-preview-thumbnails {
        text-align: right;

        height: @med-preview-box-size;
        overflow: hidden;
        overflow-y: auto;

        > li {
            margin: 0 16px 16px 0;
            
        }
    }
}

.media-preview-horizontal {

    .media-preview-controls {
        .clearfix();
        
        position: relative;
        width: @med-preview-img-width + (@med-preview-border-width * 2);
        padding: 0 @med-obj-padding;
        overflow: hidden;

        > .media-arrow {
            display: block;
            background-color: #fff;
            top: 0;
            bottom: 0;
            z-index: 1;
            line-height: @med-preview-thumb-width + (@med-preview-border-width * 2);
            
            position: absolute;

            &.left {
                left: 0;
                padding-right: @med-obj-padding;
            }
            &.right {
                right: 0;
                padding-left: @med-obj-padding;
            }
        }
    }

    .media-preview-thumbnails {

        width: 2000em; // make sure thumbnails do not wrap

        > li {
            float: left;
            margin-left: @med-obj-padding;
        }
    }
}

.media-preview-vertical {
    @indicators-container-width: 147px;

    position: relative;

    .media-preview-main {
        margin-right: @indicators-container-width + @med-obj-padding;
        margin-bottom: 0;
    }

    .media-preview-controls {
        position: absolute;
        top: 0;
        bottom: auto;
        left: @med-preview-img-width;
        padding: 0;
        overflow: hidden;
        overflow-y: auto;
        height: 325px;
        min-width: @indicators-container-width + @med-obj-padding;
    }


    .media-preview-thumbnails {
        position: static;
        width: @indicators-container-width;
        padding-left: @med-obj-padding;

        > li {
            clear: both;
            margin-bottom: @med-obj-padding;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .media-preview-all {
        position: absolute;
        bottom: 0;
        left: @med-preview-img-width + @med-obj-padding;
        line-height: 1;
        display: block;
    }
}

.media-preview-all {
    display: none;
}

.rounded {
    .media-preview-thumbnails > li {
        border-radius: 12px;
    }
}
