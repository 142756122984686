.datebox {
    border: 2px solid @datebox-border-colour;
    border-radius : @datebox-border-radius;
    display: block;
    padding-bottom: @vertical-space-1 / 2;
    width: 88px;
    background: #fff;

    color: @gray-darker;
    line-height: 1em;
    text-align: center;

    > span {
        display: block;
    }
}

.datebox-header {
    margin-bottom: @vertical-space-1 / 2;
    padding: 2px 0;
    background-color: @datebox-bg;

    color: #fff;
    font-size: @date-box-header-size;
}

.datebox-day {
    font-family: @font-family-bryant;
    font-size: @date-box-day-size;
    line-height: 1em;
}

.datebox-sm {
    width: 75px;
    font-size: @date-box-sm-text-size;

    .datebox-header {
        font-size: @date-box-sm-header-size;
    }

    .datebox-day {
        font-size: @date-box-sm-day-size;
    }
}