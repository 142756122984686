#invite-container {
    min-height: 365px;

    .h1 {
        margin-top: 0;
    }

}

.invite-form {
    display: block;
    
    p {
        margin-bottom: 25px;
    }

    .control-label {
        .make-sm-column(3);
    }

    .form-button-col {
        .make-sm-column(9);
        .make-sm-column-offset(3);

        text-align: right;
    }

    .form-group-btn {
        margin-bottom: 0;
    }
}

.success-cta-container {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    .rose-success {
        display:block;
        opacity: 0.3;
        margin: 0 auto 80px auto;
    }
}