@import "../components/attached-packs";

.people-table {
    &.table > thead > tr > th {
        padding-top: @vertical-space-1 / 2;
    }
}

#people-table-form {
    min-height: 500px;
}

#filter-popover .filter .filter-field {
    width: 230px;
}

.people-filter.filter-popover fieldset {
    border: none;
    margin: 0;
    padding-right: 0;
    padding-left: 0;
}

.search-box {
    input, button {
        display: inline-block;
    }
}

.scrollable-packs-container {
    padding-top: @vertical-space-1 / 2;
    margin-bottom: 0;

    &:after,
    &:before {
        display: none;
    }

    > .last-child:after {
        display: none;
    }
}

.delete {
    float: right;
    cursor: pointer;
}

.user-container {
    .form-input-col {
        .make-sm-column(12);
    }
}

#m4b_update_user_form_userType {
    .horizontal-choice-item {
        .make-sm-column(4);
        font-weight: bold;
    }
}

.user-summary-profile .form-group {
    .control-label {
        .make-sm-column(2);
    }
    .form-input-col {
        .make-sm-column(6);
    }
}

#people-search-sort-filter-control {
    .product-checkbox {
        padding-left: 5px;
    }
}