//--------------------------------------------------------------
// Mixins - Utilities
//
// Define custom mixins. All mixins should have parenthesis
// even if they take not arguments so that they do not appear
// in the output as classes
//--------------------------------------------------------------

// Stretches an element using absolute positioning to
// fill the full height and width of it's relative parent
.stretch() {
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.all-link-styles(@rules){
  &,
  &:active,
  &:hover,
  &:focus:visited {
    @rules();
  }
}

.square(@size) {
  .shape-square(@size);
}

.bryant-pro-regular() {
  font-family: @font-family-bryant;
  font-weight: normal;
}

.bryant-pro-medium() {
  font-family: @font-family-bryant;
  font-weight: 500;
}

.bryant-pro-bold() {
  font-family: @font-family-bryant;
  font-weight: bold;
  letter-spacing: normal;
}



// List styles
// Remove default list style
.list-unstyled() {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}

// Apply font smoothing
.font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-image(@path) {
   background-image: url("@{image-path}@{path}");
}

// SVG background image with PNG fallback
.svg-background(@src) {
  .background-image(@src);

  html.no-svg & {
    .background-image(replace(@src, '.svg', '.png'));
  }
}

.background-size(@size) {
  background-size: @size;
  -ms-behavior: url(/bower_components/background-size-polyfill/backgroundsize.min.htc);
}

.standard-size-colour() {
    color: @brand-primary-blue;
}

.moo-size-colour() {
    color: @brand-primary-green;
}

.jumbotron-content() {
  padding-top: 90px;
  padding-left: @grid-gutter-width / 2;
  text-align: left;

  h1 {
    color: @text-color;
  }

  p {
    max-width: 350px;
  }

  .btn {
    margin-top: 20px;
  }
}

.play-cta() {
  position: absolute;
  background: @brand-primary;
  border: 1px solid #FFF;
  border-radius: 50%;
  text-align: center;
  opacity: 0.7;
  transition: opacity 1s ease;
  z-index: 1;
  color: #FFF;
  line-height: 1;
  vertical-align: middle;
  padding: 0;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:active,
  &:focus,
  &:hover {
    opacity: 1;
    outline: none;
  }
}
