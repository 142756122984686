@search-clear-size : 26px;
@search-clear-gutter : 8px;

.search-box {
    display: inline-block;
    margin-right: 10px;
    
    .form-control {
        width: 220px;
    }

    // when form control has text
    &.has-text {
        position: relative;

        .form-control {
            padding-right: @search-clear-size + (@search-clear-gutter * 2);
        }

        .search-clear { display: block; }
    }
}

.search-box-full {
    position: relative;
    display: block;
    width: 100%;

    .form-control {
        display: block;
        width: 100%;
    }
}

.search-clear {
    &, 
    button& {
        position: absolute;
        top: @search-clear-gutter;
        right: @search-clear-gutter;

        background: @brand-pastel-gray;

        display: none;
        border-radius: 50%;
        overflow: hidden;

        line-height: 1;
        text-align: center;

        width: 1em;
        height: 1em;
    }

    &.mooicon-cross-small {
        &:before {
            font-size: 22px;
            margin-top: -4px;
            margin-left: 1px;
        }
    }
}