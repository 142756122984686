.create-pack {
    
    .form-group {
        .control-label-select,
        .control-label {
            .make-sm-column(4);

            .bootstrap-select.btn-group {
                max-width: 146px;
            }
        }

        .control-label-select {
            padding-top: 0;
        }

        .control-element {
            .make-sm-column(8);
        }
    }

    .side {
        display: inline-block;
        background-color: #fff;
        background-image: url('/m4b/bundles/m4b/images/80px_spinner.gif');
        background-repeat: no-repeat;
        background-position: center;
        border: @med-preview-border-width solid @gray-light;
        line-height: 0;
        max-width: 100%;

        img { max-width: 100% }
    }

    .rendered-preview {
        .landscape img {
            width: 275px;
            height: 185px;
            @media (max-width: @screen-lg) { height: auto }
        }

        .portrait img {
            height: 275px;
            width: 185px;
            @media (max-width: @screen-lg) { height: auto }
        }
    }

    .rounded {
        .side {
            border-radius: @rounded-lg;

            img {
                border-radius: @rounded-lg;
            }
        }
    }
    
    .alert {
        margin-bottom: @vertical-space-4;
        margin-top: @vertical-space-1 * -1;
    }
}


.preview-container {
    position: relative;
    min-height: 190px;

    .preview-pack {
        .btn-link;
        .btn-clear;

        &:hover {
            color: @gray-darker;
        }

        position: absolute;
        bottom: 0;
        right: 10px;
        font-weight: bold;
        @media (max-width: @screen-lg) { 
            right: 0;
            bottom: -30px;
        }
    }
}


.sme-designs {
    margin-bottom: @vertical-space-4;

    &:before {
        margin-top: @vertical-space-1;
    }
    
    h3,
    .h3 {
        margin-top: @vertical-space-1;
    }

    .paper-info {
        margin-bottom: 25px;
    }

    // Don't show bottom "borders" on the last item
    > li:last-child:after {
        display: none;
    }
}


.packs-added-list,
.packs-edit-list {
    list-style: none;
    padding: 0;

    > li {
        margin-left: 5px;
    }

    h3,
    .h3 {
        margin-top: 0;
        margin-bottom: @vertical-space-2;
    }


    .row-edit {
        .form-group {
            width: auto;
            display: block;
            padding-right: 0;
        }
    }
}

.packs-added-list {
    .add-btn {
        display: none;
    }

    .btn-link:hover {
        color: @gray-darker;
    }

    > .pack + .pack-added-none {
        display: none;
    }
}

.packs-edit-list {
    margin: 0 0 15px;

    /* transition start 
    * overflow + max-height allows us to transition an element smoothly without knowing the element height
    * it should be noted that the higher the max-height value, the slower the speed should be because the browser transitions from the max-height to zero
    */
    overflow: hidden;
    max-height: 5000px;
    .transition(max-height (@transition-speed * 6) ease-out);
    /* transition end */

    .collapse-btn {
        display: none;
    }
    
    &.is-collapsed {
        max-height: 0;
    }

    &.rows-collapse > li > .row:after {
        display: none;
    }
}

.btn-block-holder {
    display: none;

    .is-collapsed + & {
        display: block;
    }
}

.add-more-btn {
    > .mooicon-plus-small:before {
        margin-right: 10px;
        margin-top: -3px;
    }
}

.pack-added-none-msg {
    width: 35%;
    margin: @vertical-space-6 auto;

    img {
        margin-bottom: @vertical-space-1;
    }
}

.import-success-msg {

    .singular-msg { display: none }
    .plural-msg { display: block }

    &.is-single-import {
        .singular-msg { display: block }
        .plural-msg { display: none }
    }
 
}

.import-csv-overlay {
    h2 {
        margin-top: 0;
    }

    .column-headings {
        .separator-thick;

        > .h3 {
            margin-bottom: @vertical-space-2;
        }
    }

    .field-side {
        margin-bottom: @vertical-space-2;

        > .h3 {
            margin-bottom: @vertical-space-1;
        }

        .group-selector {
            margin-bottom: @vertical-space-2;

            .field-name,
            .example-data {
                margin-top: @vertical-space-1;
                word-break: break-word;
            }

            .example-data {
                color: @gray;
                font-style: italic;
            }

            .bootstrap-select.btn-group {
                margin-bottom: 0;
                width: 100%;
            }
        }
    }
}

.import-contacts-form {
    > p {
        line-height: 2.15em;
    }

    .upload-control {
        margin-bottom: @vertical-space-1;

        .btn-default {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.select-fields-form {
    display: none;
}