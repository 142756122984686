.orders-table {
    margin-top: 25px;

    // Specificity due to overwriting existing styles.
    thead > tr > th {
        padding-top: 0;
    }
}

.order-details {
    dl {
        margin-bottom: 0;

        dt {
            line-height: 1;
        }

        dt, dd {
            padding-top: 5px;
            padding-bottom: @vertical-space-base * 2.5;
        }
    }

    .fluid-attributes {
        dt, dd {
            padding: 0;
        }
    }

    // Specificity override from cart.less
    .order-summary {
        > .row {
            > .col-value,
            > .col-key {
                padding-top: 0;
                padding-bottom: @vertical-space-base * 3;
            }

            &.order-summary-total {
                > .col-value,
                > .col-key {
                    padding-top: @vertical-space-base;
                    padding-bottom: 0;                
                }
            }
        }
    }

    .order-items-header {
        margin-bottom: @vertical-space-base * 4;
    }

    .invoice-row {
        position: relative;

        .order-delivery {
            position: absolute;
            top: 0;
            bottom: 0;

            .address {
                // Tracking number is absolute, give this padding to prevent
                // potential overlap
                padding-bottom: @line-height-computed * 2;
            }

            .tracking-number {
                position: absolute;
                bottom: 0;
            }
        }

        .order-cart {
            float: right;

            .sub-total {
                // Reset strike through applied from cart component
                text-decoration: none;
            }
        }
    }
}

.order-items {
    // 475px is about 3 times the height of one row.
    max-height: 475px;
    overflow-y: auto;
    overflow-x: hidden;

    .product-description {
        h3 {
            margin-top: @vertical-space-base;
            margin-bottom: @vertical-space-base * 2;
        }

        li {
            padding-bottom: @vertical-space-base * 0.5;
        }

    }

    .product-price {
        margin-top: 0;
    }

    .products {
        &:before {
            // Reset the thin separator given in products.less
            content: initial;
        }

        // On the orders page these rows have a thick separator after them
        // So remove the last thin separator on the row as well as the padding
        > .row.last-child{
            &:after{
                content: initial;
            }

            & > [class*="col-"]{
                padding-bottom: 0;
            }
        }
    }
}

.order-delivery, .order-cart {
    padding-top: 5px;
}

.order-cart {
    .order-summary {
        padding-left: @grid-gutter-width;
    }

    .order-summary-total.row {
        margin-bottom: 0;

        &:after,
        &:before {
            display: none;
        }

        .h2 {
            .h3;
        }
    }
}

.order-delivery {
    .separator-right;

    h2{
        margin-bottom: @vertical-space-base * 3;
    }

    .cart-address {
        margin-bottom: @padding-large-horizontal * 1.5;
    }
}

.order-number {
    margin-left: 30px;
}