@search-sort-filter-control-height : 42px;
@search-sort-filter-control-gutter : 10px;


.search-sort-filter-control {

    position: relative; // push layer up to show filter popovers above paper options popovers
    .separator-horizontal(~":after");

    .bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]) {
        width: 150px;
    }

    .bootstrap-select.btn-group {
        margin-right: @search-sort-filter-control-gutter;
        margin-bottom: 0;

        .btn {
            height: @search-sort-filter-control-height;

            &:hover {
                z-index: 0;
            }
        }
    }

    .custom-checkbox {
        margin: 9px 0 0;
    }

    .form-control {
        margin-right: @search-sort-filter-control-gutter;
        height: @search-sort-filter-control-height;
    }

    .search-control {
        margin-right: 0;
    }

    .sort-control {
        width: 100%;
        margin-bottom: @search-sort-filter-control-gutter * 2;
        padding-left: 5px;

        & > form {
            display: block;
            .clearfix();
        }
    }

    &.affix {
        position: fixed;
        // -1 so it doesn't cover the top navigation bar drop down
        z-index: @zindex-tooltip - 1;
        // Header height plus thick separator margin
        top: @navbar-height;
        background: @body-bg;
        padding-top: 20px;

        &:after {
            margin-bottom: 0;
        }

        & ~ .process-form {
            padding-top: 95px;

            .products:before {
                content: initial;
            }
        }
    }

    .search-box {
        float: left;
    }

    .btn[disabled] {
        display: none;
    }
}

.filters-control {
    clear: both;
    padding-top: @vertical-space-1 / 2;

    h3 {
        margin: 0 0 @vertical-space-2;
    }

    .label-toggable {
        margin-right: @search-sort-filter-control-gutter;
    }
}
