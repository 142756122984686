//--------------------------------------------------------------
// Font Face Declarations
//--------------------------------------------------------------

@font-face {
  @font-name: 'BryantMooWebProRegular';

  font-family: 'Bryant MOO Pro';
  src: url('@{font-path}bryant/@{font-name}.eot');
  src: url('@{font-path}bryant/@{font-name}.eot?#iefix') format('embedded-opentype'),
       url('@{font-path}bryant/@{font-name}.woff') format('woff');

  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}

@font-face {
  @font-name: 'BryantMooWebProMedium';

  font-family: 'Bryant MOO Pro';
  src: url('@{font-path}bryant/@{font-name}.eot');
  src: url('@{font-path}bryant/@{font-name}.eot?#iefix') format('embedded-opentype'),
       url('@{font-path}bryant/@{font-name}.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
}

@font-face {
  @font-name: 'BryantMooWebProBold';

  font-family: 'Bryant MOO Pro';
  src: url('@{font-path}bryant/@{font-name}.eot');
  src: url('@{font-path}bryant/@{font-name}.eot?#iefix') format('embedded-opentype'),
       url('@{font-path}bryant/@{font-name}.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
}
