//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    font-family: @font-family-bryant;
    font-size: @font-size-large;
    //.clearfix();

    > li {
        position: relative;
        display: block;

        > span,
        > a {
            position: relative;
            display: block;
            padding: @nav-link-padding;
            &:hover,
            &:focus {
                text-decoration: none;
                background-color: @nav-link-hover-bg;
            }
        }

        // Disabled state sets text to gray and nukes hover/tab effects
        &.disabled > a {
            color: @nav-disabled-link-color;

            &:hover,
            &:focus {
                color: @nav-disabled-link-hover-color;
                text-decoration: none;
                background-color: transparent;
                cursor: not-allowed;
            }
        }
    }

    // Open dropdowns
    .open > a {
        &,
        &:hover,
        &:focus {
            background-color: @nav-link-hover-bg;
            border-color: @link-color;

            .caret {
                border-top-color: @link-hover-color;
                border-bottom-color: @link-hover-color;
            }
        }
    }

    // Prevent IE8 from misplacing imgs
    //
    // See https://github.com/h5bp/html5-boilerplate/issues/984#issuecomment-3985989
    > li > a > img {
        max-width: none;
    }
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
    padding-bottom: @vertical-space-1 * 2.5;

    &:after {
        display: block;
        content: " ";
        background-color : @nav-tabs-border-color;
        height: 5px;
        border-radius: 10px;
        clear: both;
    }

    > li {
        float: left;
        margin-right: 60px;


        // Actual tabs (as links)
        > a,
        > span {
            line-height: 1;
            font-family: @font-family-bryant;
            font-size: @font-size-large;
            padding: 10px 0 12px 0;
            text-transform: capitalize;

            .page-header + & {
                // Page headers directly followed by tabs should not be
                // padded, page headers have a significant margin bottom
                padding-top: 0;
            }

            &,
            &:focus,
            &:hover {
                background-color : transparent;
            }
        }

        // Active state, and it's :hover to override normal :hover
        &.active {

            &:after {
                display: block;
                content: " ";
                background-color : @nav-tabs-active-border-color;
                height: 5px;
                border-radius: 10px;

                // overlay gray border at the bottom
                margin-bottom: -5px;
            }

            > a,
            > span {
                &,
                &:hover,
                &:focus {
                    background-color : transparent;
                    cursor: default;

                    color: @nav-tabs-active-link-color;
                }


            }
        }
    }
    // pulling this in mainly for less shorthand
    &.nav-justified {
        .nav-justified();
        .nav-tabs-justified();
    }
}


// Pills
// -------------------------
.nav-pills {
    > li {
        float: left;

        // Links rendered as pills
        > a {
            border-radius: @nav-pills-border-radius;
        }
        + li {
            margin-left: 2px;
        }

        // Active state
        &.active > a {
            &,
            &:hover,
            &:focus {
                color: @nav-pills-active-link-hover-color;
                background-color: @nav-pills-active-link-hover-bg;

                .caret {
                    border-top-color: @nav-pills-active-link-hover-color;
                    border-bottom-color: @nav-pills-active-link-hover-color;
                }
            }
        }
    }
}

.nav-invert {
    a {
        color: @nav-invert-link-color;

        &:hover,
        &:focus
        {
            .icon {
                background: @nav-invert-active-link-color;
            }
            color: @nav-invert-active-link-color;
            background: none;
        }
    }

    li {
        a:hover {
            background: none;
        }

        &.active {
            background: none;

            .icon {
                background: @nav-invert-active-link-color;
            }

            a {
                &,
                &:hover,
                &:focus {
                    background: none;
                    color: @nav-invert-active-link-color;
                }
            }
        }
    }
}


// Stacked pills
.nav-stacked {
    > li {
        float: none;
        + li {
            margin-top: 2px;
            margin-left: 0; // no need for this gap between nav items
        }
    }
}


// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {
    width: 100%;

    > li {
        float: none;
        > a {
            text-align: center;
            margin-bottom: 5px;
        }
    }

    > .dropdown .dropdown-menu {
        top: auto;
        left: auto;
    }

    @media (min-width: @screen-sm-min) {
        > li {
            display: table-cell;
            width: 1%;
            > a {
                margin-bottom: 0;
            }
        }
    }
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
    border-bottom: 0;

    > li > a {
        // Override margin from .nav-tabs
        margin-right: 0;
    }

    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
    }

    @media (min-width: @screen-sm-min) {
        > li > a {
        }

        > .active > a,
        > .active > a:hover,
        > .active > a:focus {
        }
    }
}


// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
    > .tab-pane {
        display: none;
    }
    > .active {
        display: block;
    }
}

// Bottom progress bar
// -------------------------

.nav-progress-bar {
    color: #fff;
    background: #555;
    padding: 10px 0;

    &.affix-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: @zindex-navbar-bottom;

        // As
        div[class*="col-"] {
            padding: 0;
        }
    }

    .back-arrow {
        color: #fff;
    }

    a {
        color: #fff;
    }
}

// Anything which is floating right, pull up above the nav
.nav-separated {
    position: relative;

    .pull-right {
        position: absolute;
        right: 0;
        bottom: 38px;
    }
}
