//
// Close icons
// --------------------------------------------------

.close {
    float: right;
    font-size: (@font-size-base * 2);
    font-weight: @close-font-weight;
    line-height: 18px;
    color: @close-color;
    text-shadow: @close-text-shadow;
    outline: none;

    &:hover,
    &:focus {
        text-decoration: none;
        cursor: pointer;
    }

    // Additional properties for button version
    // iOS requires the button element instead of an anchor tag.
    // If you want the anchor version, it requires `href="#"`.
    button& {
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
    }

    .alert-dismissable & {
        top: -4px;
    }
}