.details-form {
    .control-label{
        .make-sm-column(3);
    }
    .form-input-col{
        .make-sm-column(4);
    }
    .form-button-col {
        text-align: right;
    }
    .form-button-col{
        .make-sm-column(4);
        .make-sm-column-offset(3);
    }
}

.nav-tabs + .address-list-container {
    margin-top: @vertical-space-1 * 1.5;
}

.address-list-container .address-header {
    font-family: @font-family-bryant;
    font-size: @font-size-large;
}

#m4b_enter_sme_account_form {

    .required:after {
        content: initial;
    }
}
