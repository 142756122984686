//
// Grid system
// --------------------------------------------------

// Vertical spacing
.make-vertical-spacing(10);

// Set the container width, and override it for fixed navbars in media queries
.container {
    .container-fixed();
}

// mobile first defaults
.row {
    .make-row();

    &:after,
    &:before {
        margin-left: floor(@grid-gutter-width / 2);
        margin-right: floor(@grid-gutter-width / 2);
    }
}

.row-vertical-middle {
    white-space: nowrap;
    .make-row-vertical-align(@grid-columns, middle);
}

tr.row {
    &:after,
    &:before {
        margin-left: 0;
        margin-right: 0;
    }
}

// Common styles for small and large grid columns
    .make-grid-columns();


// Extra small grid
//
// Grid classes for extra small devices like smartphones. No offset, push, or
// pull classes are present here due to the size of the target.
//
// Note that `.col-xs-12` doesn't get floated on purpose--there's no need since
// it's full-width.

    .make-grid-columns-float(xs);
    .make-grid(@grid-columns, xs, width);
    .make-grid(@grid-columns, xs, pull);
    .make-grid(@grid-columns, xs, push);
    .make-grid(@grid-columns, xs, offset);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.
//
// Note that `.col-sm-12` doesn't get floated on purpose--there's no need since
// it's full-width.

@media (min-width: @screen-sm-min) {
    .container {
        width: @container-sm;
    }

    .make-grid-columns-float(sm);
    .make-grid(@grid-columns, sm, width);
    .make-grid(@grid-columns, sm, pull);
    .make-grid(@grid-columns, sm, push);
    .make-grid(@grid-columns, sm, offset);
}

// Indentation, useful for padding panels (used on the dashboard)

.indent-left {
    padding-left: 30px;
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.
//
// Note that `.col-md-12` doesn't get floated on purpose--there's no need since
// it's full-width.

@media (min-width: @screen-md-min) {
    .container {
        width: @container-md;
    }

    .make-grid-columns-float(md);
    .make-grid(@grid-columns, md, width);
    .make-grid(@grid-columns, md, pull);
    .make-grid(@grid-columns, md, push);
    .make-grid(@grid-columns, md, offset);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.
//
// Note that `.col-lg-12` doesn't get floated on purpose--there's no need since
// it's full-width.

@media (min-width: @screen-lg-min) {
    .container {
        width: @container-lg;
    }

    .make-grid-columns-float(lg);
    .make-grid(@grid-columns, lg, width);
    .make-grid(@grid-columns, lg, pull);
    .make-grid(@grid-columns, lg, push);
    .make-grid(@grid-columns, lg, offset);
}
