//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    display: none;
    overflow: auto;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @zindex-modal-background;

    // When fading in the modal, animate it to slide down
    &.fade .modal-dialog {
        .translate(0, -25%);
        .transition-transform(~"0.3s ease-out");
    }
    &.in .modal-dialog { .translate(0, 0)}


    .close {
        @offset: -18px;

        position: absolute;
        top: @offset;
        right: @offset;
        z-index: 1;
        background-image: url("/images/close_cross_green.png");
        height: 38px;
        width: 38px;
        display: block;
    }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    padding: 10px;
    z-index: (@zindex-modal-background + 10);
}

// Actual modal
.modal-content {
    position: relative;
    background-color: @modal-content-bg;
    border-radius: @border-radius-base;

    // ie8 fallback for boxshadow border
    border: 1px solid @modal-content-fallback-border-color;
    border: 0px solid @modal-content-border-color;

    .box-shadow(0 2px 6px rgba(0,0,0,.6));
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: none;
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: (@zindex-modal-background - 10);
    background-color: @modal-backdrop-bg;
    // Fade for backdrop
    &.fade { .opacity(0); }
    &.in { .opacity(.5); }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    padding: @modal-title-padding;
    border-bottom: 1px solid @modal-header-border-color;
    min-height: (@modal-title-padding + @modal-title-line-height);
}

// Title text within header
.modal-title {
    margin: 0;
    line-height: @modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    padding: @modal-inner-padding;

    // Headings and other elements that are at the top of modals do not
    // need margin or padidng top, the modal padding will cover this
    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }
}

// Footer (for actions)
.modal-footer {
    margin-top: 15px;
    padding: (@modal-inner-padding - 1) @modal-inner-padding @modal-inner-padding;
    text-align: right; // right align buttons
    border-top: 1px solid @modal-footer-border-color;
    .clearfix(); // clear it in case folks use .pull-* classes on buttons

    // Properly space out buttons
    .btn + .btn {
        margin-left: 5px;
        margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
    // but override that for button groups
    .btn-group .btn + .btn {
        margin-left: -1px;
    }
    // and override it for block buttons as well
    .btn-block + .btn-block {
        margin-left: 0;
    }
}

// Scale up the modal
@media screen and (min-width: @screen-sm-min) {

    .modal-dialog {
        width: 600px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .modal-content {
        .box-shadow(0 5px 15px rgba(0,0,0,.5));
    }

    // Modal sizes
    .modal-sm { width: @modal-sm; }

    #modalSpinner {
        .modal-spinner {
            position:fixed;
            top:50%;
            left:50%;
            margin-left: 0-(@modal-spinner-size/2);
            margin-top: 0-(@modal-spinner-size/2);
            width: @modal-spinner-size;
            height: @modal-spinner-size;
            .modal-content {
                div {
                    text-align: center;
                    img  {
                        margin-bottom:30px;
                    }
                }
            }
        }
    }
}

#modalConfirm {
    display: none;

    .modalContent {
        position: fixed;
        background: white;
        box-shadow: 0 0 25px #ccc;
        padding: 30px;
        z-index: 100;
        top: 50%;
        left: 50%;
        width: 492px;
        margin-left: -246px;
        height: 204px;
        margin-top: -102px;

        .btn-group {
            float:right;
            padding-top: @vertical-space-1;
        }

        .buttons {
            text-align: right;
            font-family: "Bryant MOO Pro", "Trebuchet MS", Trebuchet, "Liberation Sans", sans-serif;
            font-size: @font-size-medium;

            button {
                margin-left: 8px;
                padding: 7px 19px;
                border: none;
                border-radius: 3px;
            }

            .confirm-button {
                background: @brand-dark-green;
                color: white;
                &:hover {
                    box-shadow: 0 -2px 0 0 @brand-dark-green-darker inset;
                }
            }

            .cancel-button {
                background: @gray-light;
                color: @brand-dark-green;
                &:hover {
                    box-shadow: 0 -2px 0 0 @brand-gray inset;
                }
            }

        }
    }

    .cover {
        position:fixed;
        top:0;
        left:0;
        background:#fafafa;
        opacity: 0.75;
        z-index:5;
        width:100%;
        height:100%;
    }
}


