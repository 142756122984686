//
// Mixins
// --------------------------------------------------

// Utilities
// -------------------------

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
.clearfix() {
    &:before,
    &:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }
    &:after {
        clear: both;
    }
}

// WebKit-style focus
.tab-focus() {
    // Default
    outline: none;
}

// Center-align a block level element
.center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Sizing shortcuts
.size(@width; @height) {
    width: @width;
    height: @height;
}

.square(@size) {
    .size(@size; @size);
}

// Placeholder text
.placeholder(@color: @input-color-placeholder) {
    // Firefox applies opactiy 0.54 to placeholder text
    &:-moz-placeholder {
        color: @color;
        font-style: italic;
        opacity: 1;
    }
    // Firefox 4-18
    &::-moz-placeholder {
        color: @color;
        font-style: italic;
        opacity: 1;
    }
    // Firefox 19+
    &:-ms-input-placeholder {
        color: @color;
        font-style: italic;
    }
    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: @color;
        font-style: italic;
    }
    // Safari and Chrome
}

// Text overflow
// Requires inline-block or block for proper styling
.text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// CSS image replacement
//
// Heads up! v3 launched with with only `.hide-text()`, but per our pattern for
// mixins being reused as classes with the same name, this doesn't hold up. As
// of v3.0.1 we have added `.text-hide()` and deprecated `.hide-text()`. Note
// that we cannot chain the mixins together in Less, so they are repeated.
//
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757

// Deprecated as of v3.0.1 (will be removed in v4)
.hide-text() {
    font: ~"0/0" a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

// New mixin to use as of v3.0.1
.text-hide() {
    font: ~"0/0" a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
.border-top-radius(@radius) {
    border-top-right-radius: @radius;
    border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
    border-bottom-right-radius: @radius;
    border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
    border-bottom-left-radius: @radius;
    border-top-left-radius: @radius;
}

// Drop shadows
.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
    box-shadow: @shadow;
}

// Animations
.animation(@value1,@value2:X,...) {
    @value: ~`"@{arguments}".replace(/[\[\]]|\,\sX/g, '')`;

    -webkit-animation: @value;
    -moz-animation: @value;
    animation: @value;
}

// Transitions
.transition (@value1,@value2:X,...) {
    @value: ~`"@{arguments}".replace(/[\[\]]|\,\sX/g, '')`;

    -webkit-transition: @value;
    -moz-transition: @value;
    -ms-transition: @value;
    -o-transition: @value;
    transition: @value;
}

.transition-property(@transition-property) {
    -webkit-transition-property: @transition-property;
    transition-property: @transition-property;
}

.transition-delay(@transition-delay) {
    -webkit-transition-delay: @transition-delay;
    transition-delay: @transition-delay;
}

.transition-duration(@transition-duration) {
    -webkit-transition-duration: @transition-duration;
    transition-duration: @transition-duration;
}

.transition-transform(@transition) {
    -webkit-transition: -webkit-transform @transition;
    -moz-transition: -moz-transform @transition;
    -o-transition: -o-transform @transition;
    transition: transform @transition;
}

// Transformations
.rotate(@degrees) {
    -webkit-transform: rotate(@degrees);
    -ms-transform: rotate(@degrees); // IE9+
    transform: rotate(@degrees);
}

.scale(@ratio) {
    -webkit-transform: scale(@ratio);
    -ms-transform: scale(@ratio); // IE9+
    transform: scale(@ratio);
}

.translate(@x; @y) {
    -webkit-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y); // IE9+
    transform: translate(@x, @y);
}

.skew(@x; @y) {
    -webkit-transform: skew(@x, @y);
    -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    transform: skew(@x, @y);
}

.translate3d(@x; @y; @z) {
    -webkit-transform: translate3d(@x, @y, @z);
    transform: translate3d(@x, @y, @z);
}

.rotateX(@degrees) {
    -webkit-transform: rotateX(@degrees);
    -ms-transform: rotateX(@degrees); // IE9+
    transform: rotateX(@degrees);
}

.rotateY(@degrees) {
    -webkit-transform: rotateY(@degrees);
    -ms-transform: rotateY(@degrees); // IE9+
    transform: rotateY(@degrees);
}

.perspective(@perspective) {
    -webkit-perspective: @perspective;
    -moz-perspective: @perspective;
    perspective: @perspective;
}

.perspective-origin(@perspective) {
    -webkit-perspective-origin: @perspective;
    -moz-perspective-origin: @perspective;
    perspective-origin: @perspective;
}

.transform-origin(@origin) {
    -webkit-transform-origin: @origin;
    -moz-transform-origin: @origin;
    transform-origin: @origin;
}

.translateY(@origin) {
    -webkit-transform: translateY(@origin);
    -moz-transform: translateY(@origin);
    transform: translateY(@origin);
}

// Animations
.animation(@animation) {
    -webkit-animation: @animation;
    animation: @animation;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
.backface-visibility(@visibility) {
    -webkit-backface-visibility: @visibility;
    -moz-backface-visibility: @visibility;
    backface-visibility: @visibility;
}

// Box sizing
.box-sizing(@boxmodel) {
    -webkit-box-sizing: @boxmodel;
    -moz-box-sizing: @boxmodel;
    box-sizing: @boxmodel;
}

// User select
// For selecting text on the page
.user-select(@select) {
    -webkit-user-select: @select;
    -moz-user-select: @select;
    -ms-user-select: @select; // IE10+
    -o-user-select: @select;
    user-select: @select;
}

// Resize anything
.resizable(@direction) {
    resize: @direction; // Options: horizontal, vertical, both
    overflow: auto; // Safari fix
}

// CSS3 Content Columns
.content-columns(@column-count; @column-gap: @grid-gutter-width) {
    -webkit-column-count: @column-count;
    -moz-column-count: @column-count;
    column-count: @column-count;
    -webkit-column-gap: @column-gap;
    -moz-column-gap: @column-gap;
    column-gap: @column-gap;
}

// Optional hyphenation
.hyphens(@mode: auto) {
    word-wrap: break-word;
    -webkit-hyphens: @mode;
    -moz-hyphens: @mode;
    -ms-hyphens: @mode; // IE10+
    -o-hyphens: @mode;
    hyphens: @mode;
}

// Opacity
.opacity(@opacity) {
    opacity: @opacity;
    // IE8 filter
    @opacity-ie: (@opacity * 100);
    filter: ~"alpha(opacity=@{opacity-ie})";
}

// GRADIENTS
// --------------------------------------------------

#gradient {

    // Horizontal gradient, from left to right
    //
    // Creates two color stops, start and end, by specifying a color and position for each color stop.
    // Color stops are not available in IE9 and below.
    .horizontal(@start-color: #555; @end-color: #333; @start-percent: 0%; @end-percent: 100%) {
        background-image: -webkit-gradient(linear, @start-percent top, @end-percent top, from(@start-color), to(@end-color)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(left, color-stop(@start-color @start-percent), color-stop(@end-color @end-percent)); // Safari 5.1+, Chrome 10+
        background-image: -moz-linear-gradient(left, @start-color @start-percent, @end-color @end-percent); // FF 3.6+
        background-image: linear-gradient(to right, @start-color @start-percent, @end-color @end-percent); // Standard, IE10
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@start-color), argb(@end-color))); // IE9 and down
    }

    // Vertical gradient, from top to bottom
    //
    // Creates two color stops, start and end, by specifying a color and position for each color stop.
    // Color stops are not available in IE9 and below.
    .vertical(@start-color: #555; @end-color: #333; @start-percent: 0%; @end-percent: 100%) {
        background-image: -webkit-gradient(linear, left @start-percent, left @end-percent, from(@start-color), to(@end-color)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(top, @start-color @start-percent, @end-color @end-percent); // Safari 5.1+, Chrome 10+
        background-image: -moz-linear-gradient(top, @start-color @start-percent, @end-color @end-percent); // FF 3.6+
        background-image: linear-gradient(to bottom, @start-color @start-percent, @end-color @end-percent); // Standard, IE10
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@start-color), argb(@end-color))); // IE9 and down
    }

    .directional(@start-color: #555; @end-color: #333; @deg: 45deg) {
        background-repeat: repeat-x;
        background-image: -webkit-linear-gradient(@deg, @start-color, @end-color); // Safari 5.1+, Chrome 10+
        background-image: -moz-linear-gradient(@deg, @start-color, @end-color); // FF 3.6+
        background-image: linear-gradient(@deg, @start-color, @end-color); // Standard, IE10
    }
    .horizontal-three-colors(@start-color: #00b3ee; @mid-color: #7a43b6; @color-stop: 50%; @end-color: #c3325f) {
        background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from(@start-color), color-stop(@color-stop, @mid-color), to(@end-color));
        background-image: -webkit-linear-gradient(left, @start-color, @mid-color @color-stop, @end-color);
        background-image: -moz-linear-gradient(left, @start-color, @mid-color @color-stop, @end-color);
        background-image: linear-gradient(to right, @start-color, @mid-color @color-stop, @end-color);
        background-repeat: no-repeat;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@start-color), argb(@end-color))); // IE9 and down, gets no color-stop at all for proper fallback
    }
    .vertical-three-colors(@start-color: #00b3ee; @mid-color: #7a43b6; @color-stop: 50%; @end-color: #c3325f) {
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@start-color), color-stop(@color-stop, @mid-color), to(@end-color));
        background-image: -webkit-linear-gradient(@start-color, @mid-color @color-stop, @end-color);
        background-image: -moz-linear-gradient(top, @start-color, @mid-color @color-stop, @end-color);
        background-image: linear-gradient(@start-color, @mid-color @color-stop, @end-color);
        background-repeat: no-repeat;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@start-color), argb(@end-color))); // IE9 and down, gets no color-stop at all for proper fallback
    }
    .radial(@inner-color: #555; @outer-color: #333) {
        background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@inner-color), to(@outer-color));
        background-image: -webkit-radial-gradient(circle, @inner-color, @outer-color);
        background-image: -moz-radial-gradient(circle, @inner-color, @outer-color);
        background-image: radial-gradient(circle, @inner-color, @outer-color);
        background-repeat: no-repeat;
    }
    .striped(@color: rgba(255,255,255,.15); @angle: 45deg) {
        background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, @color), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, @color), color-stop(.75, @color), color-stop(.75, transparent), to(transparent));
        background-image: -webkit-linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
        background-image: -moz-linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
        background-image: linear-gradient(@angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
    }
}

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.
.reset-filter() {
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}

// Retina images
//
// Short retina mixin for setting background-image and -size

.img-retina(@file-1x; @file-2x; @width-1x; @height-1x) {
    background-image: url("@{file-1x}");

    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (   min--moz-device-pixel-ratio: 2), only screen and (     -o-min-device-pixel-ratio: 2/1), only screen and (        min-device-pixel-ratio: 2), only screen and (                min-resolution: 192dpi), only screen and (                min-resolution: 2dppx) {
        background-image: url("@{file-2x}");
        background-size: @width-1x @height-1x;
    }
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.

.img-responsive(@display: block;) {
    display: @display;
    max-width: 100%; // Part 1: Set a maximum relative to the parent
    height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
.nav-divider(@color: #e5e5e5) {
    height: 1px;
    margin: ((@line-height-computed / 2) - 1) 0;
    overflow: hidden;
    background-color: @color;
}

// Panels
// -------------------------
.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border;) {
    border-color: @border;

    & > .panel-heading {
        color: @heading-text-color;
        background-color: @heading-bg-color;
        border-color: @heading-border;

        + .panel-collapse .panel-body {
            border-top-color: @border;
        }
        & > .dropdown .caret {
            border-color: @heading-text-color transparent;
        }
    }
    & > .panel-footer {
        + .panel-collapse .panel-body {
            border-bottom-color: @border;
        }
    }
}

// Alerts
// -------------------------
.alert-variant(@background; @border; @text-color) {
    background-color: @background;
    border-color: @border;
    color: @text-color;
    margin: 0 0 20px;

    hr {
        border-top-color: darken(@border, 5%);
    }
    .alert-link {
        color: darken(@text-color, 10%);
    }

    .full-width & {
        margin: 0 20px 20px;
    }
    .faux-mobile-view & {
        margin: 0 15px 20px 0;
    }
}

// Tables
// -------------------------
.table-row-variant(@state; @background; @border) {
    // Exact selectors below required to override `.table-striped` and prevent
    // inheritance to nested tables.
    .table > thead > tr,
    .table > tbody > tr,
    .table > tfoot > tr {
        > td.@{state},
        > th.@{state},
        &.@{state} > td,
        &.@{state} > th {
            background-color: @background;
        }
    }

    // Hover states for `.table-hover`
    // Note: this is not available for cells or rows within `thead` or `tfoot`.
    .table-hover > tbody > tr {
        > td.@{state}:hover,
        > th.@{state}:hover,
        &.@{state}:hover > td,
        &.@{state}:hover > th {
            background-color: darken(@background, 5%);
        }
    }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
.button-variant(@color; @background; @border; @background-active) {
    color: @color;
    background-color: @background;
    border-color: transparent;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open .dropdown-toggle & {
        border-color: @border;
        color: @color;
    }

    &:active,
    &.active {
        background-color: @background-active;
        border-color: transparent;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: rgba(red(@background), green(@background), blue(@background), 0.3);
            cursor: not-allowed;
            border-color: transparent;
        }
    }
}

// Button sizes
// -------------------------
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    padding: (@padding-vertical + 2) @padding-horizontal (@padding-vertical - 2) @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
    border-radius: @border-radius;
}

.btn-focus() {
    color: @btn-default-color;
    text-decoration: none;
    border-bottom-width: @btn-border-bottom;
    margin-top: 0;
}

// Labels
// -------------------------
.label-variant(@color) {
    background-color: @color;
    &[href] {
        &:hover,
        &:focus {
            background-color: darken(@color, 10%);
        }
    }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.
.navbar-vertical-align(@element-height) {
    margin-top: ((@navbar-height - @element-height) / 2);
    margin-bottom: ((@navbar-height - @element-height) / 2);
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
.responsive-visibility() {
    display: block !important;
    tr& {
        display: table-row !important;
    }
    th&,
    td& {
        display: table-cell !important;
    }
}

.responsive-invisibility() {
    &,
    tr&,
    th&,
    td& {
        display: none !important;
    }
}

// Grid System
// -----------

// Centered container element
.container-fixed() {
    margin-right: auto;
    margin-left: auto;
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
    .clearfix();
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
    margin-left: (@gutter / -2);
    margin-right: (@gutter / -2);
    .clearfix();
}

// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    float: left;
    width: percentage((@columns / @grid-columns));
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);
}

// Generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);

    // Calculate width based on number of columns available
    @media (min-width: @screen-sm-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

// Generate the small column offsets
.make-sm-column-offset(@columns) {
    @media (min-width: @screen-sm-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-sm-column-push(@columns) {
    @media (min-width: @screen-sm-min) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-sm-column-pull(@columns) {
    @media (min-width: @screen-sm-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);

    // Calculate width based on number of columns available
    @media (min-width: @screen-md-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

// Generate the medium column offsets
.make-md-column-offset(@columns) {
    @media (min-width: @screen-md-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-md-column-push(@columns) {
    @media (min-width: @screen-md) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-md-column-pull(@columns) {
    @media (min-width: @screen-md-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);

    // Calculate width based on number of columns available
    @media (min-width: @screen-lg-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

// Generate the large column offsets
.make-lg-column-offset(@columns) {
    @media (min-width: @screen-lg-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-lg-column-push(@columns) {
    @media (min-width: @screen-lg-min) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-lg-column-pull(@columns) {
    @media (min-width: @screen-lg-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `@grid-columns`.

.make-grid-columns() {
    // Common styles for all sizes of grid columns, widths 1-12
    .col(@index) when (@index = 1) {
        // initial
        @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
        .col(@index + 1, @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) {
        // general; "=<" isn't a typo
        @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
        .col(@index + 1, ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) {
        // terminal
        @{list} {
            position: relative;
            // Prevent columns from collapsing when empty
            min-height: 1px;
            // Inner gutter via padding
            padding-left: (@grid-gutter-width / 2);
            padding-right: (@grid-gutter-width / 2);
        }
    }
    .col(1); // kickstart it
}

.make-grid-columns-float(@class) {
    .col(@index) when (@index = 1) {
        // initial
        @item: ~".col-@{class}-@{index}";
        .col(@index + 1, @item);
    }
    .col(@index, @list) when (@index < @grid-columns) {
        // general
        @item: ~".col-@{class}-@{index}";
        .col(@index + 1, ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index = @grid-columns) {
        // terminal
        @{list} {
            float: left;
        }
    }
    .col(1); // kickstart it
}

.calc-grid(@index, @class, @type) when (@type = width) and (@index > 0) {
    .col-@{class}-@{index} {
        width: percentage((@index / @grid-columns));
    }
}

.calc-grid(@index, @class, @type) when (@type = push) {
    .col-@{class}-push-@{index} {
        left: percentage((@index / @grid-columns));
    }
}

.calc-grid(@index, @class, @type) when (@type = pull) {
    .col-@{class}-pull-@{index} {
        right: percentage((@index / @grid-columns));
    }
}

.calc-grid(@index, @class, @type) when (@type = offset) {
    .col-@{class}-offset-@{index} {
        margin-left: percentage((@index / @grid-columns));
    }
}

// Basic looping in LESS
.make-grid(@index, @class, @type) when (@index >= 0) {
    .calc-grid(@index, @class, @type);
    // next iteration
    .make-grid(@index - 1, @class, @type);
}

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

.form-control-validation(@text-color: #555; @border-color: #ccc; @background-color: #f5f5f5) {
    // Color the label and help text
    .help-block,
    .control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        color: @text-color;
    }
    // Set the border and box shadow on specific inputs to match
    .form-control {
        border-color: @border-color;

        // stop overlapping of icon over input text
        padding-right: 44px;

        // remove ugly firefox box shadow
        box-shadow: none;
        outline: 0 none;
    }

    .form-control-icon {
        position: relative;
        display: block;

        &:before {
            .mooicon;
            .mooicon-medium;

            color: @text-color;
            font-size: 16px;
            position: absolute;
            right: 13px;
            top: 8px;
            padding: 5px;
        }
    }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

.form-control-focus(@color: @input-border-focus) {
    @color-rgba: rgba(red(@color), green(@color), blue(@color), .6);
    &:focus {
        border-color: @color;
        border-width: @input-border-width;
        padding: (@padding-base-vertical - 1px) (@padding-base-horizontal - 1px);
        outline: 0;
    }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

.input-size(@input-height; @padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    height: @input-height;
    padding: @padding-vertical @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
    border-radius: @border-radius;

    select& {
        height: @input-height;
        line-height: @input-height;
    }

    textarea& {
        height: auto;
    }
}

.clear-appearance() {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    // Hack to hide Firefox dropdown arrow http://stackoverflow.com/a/18317228/728855
    text-indent: 0.01px;
    text-overflow: '';

    // Hide Firefox's focus dotted lines
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 @input-color;
    }

    // Hide IE's dropdown arrow
    &::-ms-expand {
        display: none;
    }
}

// Generates a left triangle (◀) using CSS borders http://css-tricks.com/snippets/css/css-triangle
.triangle-left(@width: 7px; @color) {
    width: 0;
    height: 0;
    border-color: transparent @color transparent transparent;
    border-style: solid;
    border-width: @width @width @width 0;
}

// Line dividers
// -------------------------
.make-separator(@pseudo, @thickness) {
    &@{pseudo} {
        content: " ";
        background: @brand-pastel-gray;
        border-radius: @thickness;
        height: @thickness;
        margin: @separator-gutter-height 0;
        clear: both;
        display: block;
    }

    &.separator-darker@{pseudo} {
        background: @gray-light;
    }

    &.row@{pseudo} {
        margin-left: floor(@grid-gutter-width / 2);
        margin-right: floor(@grid-gutter-width / 2);
        display: block;
    }

    &.no-leading@{pseudo} {
        margin-top: 0;
    }

    &.no-trailing@{pseudo} {
        margin-bottom: 0;
    }
}

.separator-horizontal(@pseudo : ~":after") {
    .make-separator(@pseudo, 1px);
}

.separator-horizontal-thick(@pseudo : ~":after") {
    .make-separator(@pseudo, 2px);
}

.separator-horizontal-thicker(@pseudo : ~":after") {
    .make-separator(@pseudo, 5px);
}

// Notification circles

.notification-icon(@type) {
    .mooicon;
    .mooicon-circle;
    .mooicon-small;

    color: white;
}

.notification-icon(@type) when (@type = 'danger') {
    content: @mooicon-exclamation;
    background: @state-danger-text;
}

.notification-icon(@type) when (@type = 'success') {
    content: @mooicon-tick-small;
    background: @state-success-text;
}

.notification-icon(@type) when (@type = 'info') {
    content: @mooicon-info;
    background: @state-info-text;
}

.make-vertical-spacing(@index) when (@index >= 0) {
    .leading-@{index} {
        margin-top: @index * @vertical-space-base;
    }
    .trailing-@{index} {
        margin-bottom: @index * @vertical-space-base;
    }
    // next iteration
    .make-vertical-spacing(@index - 1);
}

.make-col-vertical-align(@align-position) {
    display: inline-block;
    vertical-align: @align-position;
    float: none;
}

.make-row-vertical-align(@index, @position) when (@index >= 0) {
    .col-xs-@{index},
    .col-sm-@{index},
    .col-md-@{index},
    .col-lg-@{index} {
        .make-col-vertical-align(@position);
    }

    // Iterate
    .make-row-vertical-align(@index - 1, @position);
}
