// When collapsed, hide the nav text - Used as a mixin and a class
// ----------------------------------------------------------------------------
.nav-collapsed {
    .nav {
        .nav-text {
            .transition(opacity @transition-speed ease-in-out);
            opacity: 0;
            /* IE 8 hacks below */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            display: inline-block;
        }
    }
    .moo-logo {
        .transition(all @transition-speed ease-in-out);
        width: (@vertical-space-3 + 4);
    }
}

// Default nav styling
// ----------------------------------------------------------------------------
.nav-container {
    position: fixed;
    width: percentage((2 / @grid-columns));
    height: 100%;
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
    overflow: hidden;
    font-family: @font-family-bryant;
    line-height: 2.385;
    background: @brand-gray-lighter;
    z-index: @zindex-side-navbar;

    .moo-logo {
        margin-left: @outer-left-space;
        margin-top: (@navbar-height - @mooimage-height-lockup) / 2;
        overflow: hidden;
    }

    .faux-mobile-view & {
        overflow: hidden;
    }

    // List items
    .nav {
        padding-top: @navbar-height + @vertical-space-3;

        a {
            color: @brand-black-lighter;
            padding-left: 0;
            padding-right: 0;

            .mooicon {
                width: @nav-icon-size;
                height: @nav-icon-size;
                color: @brand-black-lighter;
                margin: 0 @outer-left-space;
                font-size: @nav-icon-size;
                vertical-align: baseline;
            }
        }

        li {
            white-space: nowrap;

            &:hover {
                .mooicon {
                    color: #000;
                }

                a {
                    background: inherit;
                    color: #000;
                }
            }

            &.active {
                .mooicon {
                    color: @brand-dark-green;
                }

                a {
                    background: inherit;
                    color: @brand-dark-green;
                }
            }
        }
    }

    &.collapsed {
        .nav-collapsed;
    }
}

// Cobranding Notice
// ----------------------------------------------------------------------------
.cobrand-notice {
    position: absolute;
    bottom: 0;
    width: @nav-desktop-fixed-width - (@navbar-cobrand-vertical-margin * 2);
    margin: 0 @navbar-cobrand-vertical-margin 10px;
    padding: 10px 0 0;
    border-top: 1px dotted @gray;

    .created {
        font-weight: 500;
        overflow: hidden;
        color: @brand-black-lighter;
    }
}

// Mixins
// ----------------------------------------------------------------------------

// Mobile view mixin - small width, hide text
.mobile-nav() {
    .nav-container {
        width: @nav-mobile-fixed-width;
        .transition(all @transition-speed ease-in-out);
        .nav-collapsed;

        .nav {
            padding-top: 33px;
        }
    }
    .cobrand-notice {
        width: @nav-mobile-fixed-width - (@navbar-cobrand-vertical-margin * 2);
        border: none;

        .created {
            // Trying to make shrinking window animation less clunky
            width: 0;
            height: 0;
            padding: 0;
            margin: 0;
            .transition(all @transition-speed ease-in-out)
        }
    }
}

// Desktop view mixin - wide width
.desktop-nav() {
    .nav-container {
        width: @nav-desktop-fixed-width;

        &.collapsed {
            width: @nav-mobile-fixed-width;
        }
    }
}

// When not a collapsible nav, show plain mobile view (always force closed)
.faux-mobile-view:not(.nav-collapsible) {
    .mobile-nav;
}

// Media selectors to set widths and use desktop / mobile mixins
// ----------------------------------------------------------------------------

// On desktop, small screens, show collapsed mobile view
@media (max-width: @screen-md) {
    .mobile-nav;
}

@media (min-width: @screen-md) {
    // If on full screen, show the desktop settings
    .desktop-nav;

    // Unless we have a collapsible navigation!
    .nav-collapsible {
        .mobile-nav;

        .nav-container:hover {
            width: @nav-desktop-fixed-width;

            // Invert .nav-collapsible
            .nav {
                .nav-text {
                    opacity: 1;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                }
            }
            .moo-logo {
                width: @nav-desktop-fixed-width - ((@vertical-space-3 + 4) * 2);
            }
        }
    }
}
