// The site top navigation
header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    min-height: @navbar-height;
    background: @brand-white;
    color: @brand-gray;
    font-family: @font-family-bryant;
    font-size: @font-size-small + 2;
    text-align: right;
    line-height: @navbar-height / 2;
    border-bottom: @nav-border-bottom solid @brand-pastel-gray;
    box-shadow: @nav-shadow-h @nav-shadow-v @nav-shadow-blur @nav-shadow-spread @nav-shadow-colour;
    z-index: @zindex-header;

    a,
    .btn-link {
        color: @gray-dark;
        font-family: @font-family-bryant;

        .mooicon {
            vertical-align: baseline;
        }
    }

    .container {
        margin: 0;
    }

    // Logo, icon, text
    .moo-logo {
        margin-left: @outer-left-space;
        margin-top: (@navbar-height - @mooimage-height-lockup) / 2;

        // Margin and padding are in the uploaded image
        img {
            max-height: @navbar-height - 1;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .header-item {
        margin: 13px 10px;
        padding: 2px;

        &:hover {
            color: @brand-black;
            cursor: pointer;
            text-decoration: none;
        }

        &:focus,
        &:active
        {
            color: @brand-dark-green;
            cursor: pointer;
            text-decoration: none;
        }

        .header-content {
            padding: 0 5px;
        }

        .country-flag {
            display: inline-block;
        }

        .drop-down-option {
            margin-right: 10px;
        }
    }
}

.header-basket {
    display: inline-block;

    .mooicon-cart {
        position: relative;
        top: -2px;
    }
}
