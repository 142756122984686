//
// Labels
// --------------------------------------------------

.label {
    display: inline-block;
    padding: @label-gutter-height @label-gutter-width;
    font-family: @font-family-bryant;
    line-height: 1;
    color: @label-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1px;
    border: 0 none;

    // Add hover effects, but only for links
    &[href] {
        &:hover,
        &:focus {
            color: @label-link-hover-color;
            text-decoration: none;
            cursor: pointer;
        }
    }

    // Empty labels collapse automatically (not available in IE8)
    &:empty {
        display: none;
    }
}

.label-default {
    color: @gray-darker;
    .label-variant(@label-default-bg);
}

.label-primary {
    .label-variant(@label-primary-bg);
}

.label-success {
    .label-variant(@label-success-bg);
}

.label-info {
    .label-variant(@label-info-bg);
}

.label-warning {
    .label-variant(@label-warning-bg);
}

.label-danger {
    .label-variant(@label-danger-bg);
}

.label-toggable {
    position: relative;
    padding-right: @label-gutter-width * 3;
    font-family: @font-family-base;

    > .close {
        position: absolute;
        right: 8px;
        top: 1px;
    }
}

.label-sm {
    font-size: 14px;
    min-width: 86px;
    height: 25px;
    line-height: 25px;
    padding: 0;
}
