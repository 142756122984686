/**
 * MOO Bryant Web @font-face declarations
 *
 * We define our own font family, whose name is "Bryant MOO".
 * We define 3 difference classes which give different weights:
 *  - normal weight (Bryant MOO Web Medium)
 *  - bold weight (Bryant MOO Web Bold)
 *  - light weight (Bryant MOO Web Regular)
 *
 * Note that medium = normal weight, and regular = light weight, this is because we would want anything rendered in
 * Brant MOO Web Medium to fall back to a normal weighted font etc
 *
 * There are a few gotchas with @font-face which we attempt to work around with the additional declarations.
 * Both the double src declarations, and the ?#iefix are attempts to make IE behave in a sane way
 *
 * PLEASE NOTE: this file is duplicated for beehive at beehive/b/css/bryant.css
 *
 */

@import "moo-toolkit/src/less/fontface.less";

@font-face {
    font-family: 'Bryant MOO';
    src: url('@{icon-font-path}bryant/BryantMooWebMedium.eot');
    src: url('@{icon-font-path}bryant/BryantMooWebMedium.eot?#iefix') format('embedded-opentype'),
    url('@{icon-font-path}bryant/BryantMooWebMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
}

@font-face {
    font-family: 'Bryant MOO';
    src: url('@{icon-font-path}bryant/BryantMooWebBold.eot');
    src: url('@{icon-font-path}bryant/BryantMooWebBold.eot?#iefix') format('embedded-opentype'),
    url('@{icon-font-path}bryant/BryantMooWebBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
}