//
// Variables
// --------------------------------------------------

// Global values
// --------------------------------------------------

// Colour palette - Check moo-toolkit/src/less/variables/colors.less for all brand colors
// -------------------------

// Grays
// -------------------------

@gray-darker:            #555;
@gray-dark:              #999;
@gray:                   #b7b7b7;
@gray-light:             #ddd;

// State colors
// -------------------------

@brand-success:         @brand-dark-green;
@brand-info:            @brand-primary-navy;
@brand-warning:         @brand-primary-yellow;
@brand-danger:          @brand-primary-red;

// Typography
// -------------------------

@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;

@font-size-small:         13px;
@font-size-medium:        17px;

@font-size-h-base:        16px;
@font-size-h1:            floor(@font-size-h-base * 2);
@font-size-h2:            ceil(@font-size-h-base * 1.6);
@font-size-h3:            floor(@font-size-h-base * 1.25);
@font-size-h4:            @font-size-h-base;
@font-size-h5:            @font-size-h-base;
@font-size-h6:            ceil(@font-size-h-base * 0.85);
@font-size-lead:          18px;

@line-height-form-row:    44px;


// Iconography
// -------------------------

@font-path:               "../js/bower_components/moo-toolkit/dist/fonts/";
@image-path:              "../js/bower_components/moo-toolkit/dist/images/";

@mooicon-font-size-x-sm:        12px;
@mooicon-font-size-sm:          17px;
@mooicon-font-size-md:          28px;
@mooicon-font-size-lg:          36px;

// Components
// ------------------------

@caret-width-base:               4px;
@caret-width-large:              5px;
@caret-font-size:                24px;

@box-shadow-colour-base:         rgba(0,0,0,.3);

// Tables
// -------------------------

@table-grid-th-padding:          18px;
@table-th-padding:               @vertical-space-base * 1.5;
@table-th-font-size:             @font-size-base;

@table-bg-hover:                 @gray-light;
@table-bg-active:                @table-bg-hover;

@table-checkbox-size:            30px;

// Buttons
// -------------------------

@btn-default-color:              @brand-black;
@btn-default-bg:                 @brand-secondary-gray;
@btn-default-border:             @gray;
@btn-default-bg-active:          @brand-gray-lighter;

@btn-primary-color:              @brand-primary-white;
@btn-primary-bg:                 @brand-hero;
@btn-primary-border:             @brand-hero-darker;
@btn-primary-bg-active:          @brand-dark-green-lighter;

@btn-alternate-color:            @brand-alternate-black;
@btn-alternate-bg:               @brand-white;
@btn-alternate-border:           @brand-gray;
@btn-alternate-bg-active:        @brand-white;

@btn-font-weight:                normal;
@btn-loading-opacity:            0.3;

@btn-link-disabled-color:        @gray-light;
@btn-border-bottom:              2px;

@btn-inkdrop-inner-height:       16px;
@btn-inkdrop-anim-speed:         3s;

// Forms
// -------------------------

@input-border:                   @gray-light;
@input-border-width:             1px;
@input-border-focus:             @brand-pastel-green;

@input-height-large:             (floor(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

@legend-color:                   @gray-dark;
@legend-border-color:            #e5e5e5;

// Dropdowns
// -------------------------

@dropdown-caret-color:           @brand-black;

// Checkboxes
// -------------------------

@checkbox-size:                 30px;
@checkbox-font-size:            26px;


// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)

@zindex-navbar:                     0;
@zindex-dropdown:                   1;
@zindex-invite-bar:                 1;
@zindex-navbar-bottom:              1;
@zindex-popover:                    2;
@zindex-header:                     2;
@zindex-tooltip:                    2;
@zindex-side-navbar:                2;
@zindex-bootstrap-dropdown:         2;
@zindex-navbar-fixed:               3;
@zindex-modal-background:           4;
@zindex-modal:                      5;
@zindex-tour:                       6;

// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
// Note: Deprecated @screen-xs and @screen-phone as of v3.0.1
@screen-xs:                  480px;
@screen-xs-min:              @screen-xs;
@screen-phone:               @screen-xs-min;

// Small screen / tablet
// Note: Deprecated @screen-sm and @screen-tablet as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
// Note: Deprecated @screen-md and @screen-desktop as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
// Note: Deprecated @screen-lg and @screen-lg-desktop as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);


// Grid system
// --------------------------------------------------
// Some additional space on the left edge of the viewport
@outer-left-space: 13px;

// Vertical spacing should be consistent.
// This should be used for spacing between modules
@vertical-space-1:          @vertical-space-base * 1; // 10px
@vertical-space-2:          @vertical-space-base * 2;
@vertical-space-3:          @vertical-space-base * 3;
@vertical-space-4:          @vertical-space-base * 4;
@vertical-space-5:          @vertical-space-base * 5; // 50px
@vertical-space-6:          @vertical-space-base * 6;
@vertical-space-7:          @vertical-space-base * 7;
@vertical-space-8:          @vertical-space-base * 8;
@vertical-space-9:          @vertical-space-base * 9;
@vertical-space-10:         @vertical-space-base * 10;

// Navbar
// -------------------------

// Basics of a navbar
@navbar-height:                    70px;
@navbar-margin-bottom:             @line-height-computed;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        floor(@grid-gutter-width / 2);
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);

@navbar-default-color:             #777;
@navbar-default-bg:                #f8f8f8;
@navbar-default-border:            darken(@navbar-default-bg, 6.5%);

@navbar-cobrand-vertical-margin:    20px;

// Navbar links
@navbar-default-link-color:                #777;
@navbar-default-link-hover-color:          #333;
@navbar-default-link-hover-bg:             transparent;
@navbar-default-link-active-color:         #555;
@navbar-default-link-active-bg:            darken(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-color:       #ccc;
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           @gray-light;
@navbar-default-toggle-icon-bar-bg:        #ccc;
@navbar-default-toggle-border-color:       @gray-light;

// Fixed Left Nav
@nav-mobile-fixed-width: 64px;
@nav-mobile-margin-right: 26px;

@nav-desktop-fixed-width: 64px;
@nav-desktop-margin-right: 36px;

@nav-icon-size: 24px;

// Top Nav styling
@nav-min-height: 80px;
@nav-margin-bottom: @vertical-space-2;
@nav-border-bottom: 1px;
@nav-shadow-h: 0px;
@nav-shadow-v: 3px;
@nav-shadow-blur: 3px;
@nav-shadow-spread: -2px;
@nav-shadow-colour: @brand-pastel-gray;

// Inverted navbar
// -------------------------

// Reset inverted navbar basics
@navbar-logo-font-size:                     46px;
@navbar-logo-text-font-size:                82px;
@navbar-inverse-color:                      @gray-light;
@navbar-inverse-bg:                         #222;
@navbar-inverse-border:                     darken(@navbar-inverse-bg, 10%);

// Inverted navbar links
@navbar-inverse-link-color:                 @gray-light;
@navbar-inverse-link-hover-color:           @brand-white;
@navbar-inverse-link-hover-bg:              transparent;
@navbar-inverse-link-active-color:          @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg:             darken(@navbar-inverse-bg, 10%);
@navbar-inverse-link-disabled-color:        #444;
@navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color:                @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:          @brand-white;
@navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg:            #333;
@navbar-inverse-toggle-icon-bar-bg:         @brand-white;
@navbar-inverse-toggle-border-color:        #333;


// Navs
// -------------------------

@nav-link-padding:                          10px 20px;
@nav-disabled-link-color:                   @gray-light;
@nav-disabled-link-hover-color:             @gray-light;

@nav-open-link-hover-color:                 @brand-white;
@nav-open-caret-border-color:               @brand-white;

// Tabs
@nav-tabs-border-color:                     @gray-light;
@nav-tabs-active-border-color:              @brand-dark-green;
@nav-tobs-font-weight:                      bold;
@nav-tobs-border-radius:                    10px;
@nav-tabs-active-link-color:                @gray-darker;

// Pills
@nav-pills-border-radius:                   @border-radius-base;
@nav-pills-active-link-hover-bg:            @component-active-bg;
@nav-pills-active-link-hover-color:         @component-active-color;

@nav-invert-link-color:                     @brand-white;
@nav-invert-active-link-color:              #555;

// Pagination
// -------------------------

@pagination-bg:                             @brand-white;

// Step Indicator
// -------------------------

@step-circle-size:                      35px;
@step-border-radius:                    50%;
@step-border-width:                     2px;
@step-count-font-size:                  20px;
@step-label-font-size:                  @font-size-base;
@step-separator-width:                  24px;
@step-padding:                          18px;

// General Arrows
// -------------------------
@arrow-button-size : 24px;
@arrow-button-size-inner : 24px;

// Tooltips
// -------------------------

@tooltip-max-width:                     250px;
@tooltip-color:                         @gray-darker;
@tooltip-bg:                            @brand-white;
@tooltip-border-color:                  rgba(0,0,0,.2);
@tooltip-border-radius:                 2px;
@tooltip-border-width:                  10px;
@tooltip-fallback-border-color:         #ccc;

@tooltip-padding:                       20px;
@tooltip-arrow-width:                   5px;
@tooltip-arrow-color:                   @tooltip-bg;

// Popovers
// -------------------------

@popover-inner-padding:               15px;
@popover-bg:                          @brand-white;
@popover-max-width:                   600px;

@popover-title-bg:                    darken(@popover-bg, 3%);

@popover-arrow-width:                 10px;
@popover-arrow-color:                 @brand-white;

@popover-arrow-outer-width:           (@popover-arrow-width + 1);
@popover-arrow-outer-color:           @brand-gray;

@popover-anchor-bg:                   @brand-white;
@popover-anchor-colour:               @brand-dark-green;

// Labels
// -------------------------

@label-default-bg:            @brand-pastel-gray;
@label-primary-bg:            @brand-primary;
@label-success-bg:            @brand-success;
@label-info-bg:               @brand-info;
@label-warning-bg:            @brand-warning;
@label-danger-bg:             @brand-danger;

@label-color:                 @brand-white;
@label-link-hover-color:      @brand-white;

@label-gutter-width:            15px;
@label-gutter-height:           9px;

// Modals
// -------------------------

@modal-inner-padding:         40px;

@modal-title-padding:         20px;
@modal-title-line-height:     @line-height-base;

@modal-content-bg:                             @brand-white;
@modal-content-border-color:                   rgba(0,0,0,.2);
@modal-content-fallback-border-color:          @gray-dark;

@modal-backdrop-bg:           @brand-black;
@modal-header-border-color:   #e5e5e5;
@modal-footer-border-color:   @modal-header-border-color;

@modal-opacity: 0.5;

@modal-spinner-size: 225px;

// Alerts
// -------------------------

@alert-bg:                    @brand-white;
@alert-text:                  #555;
@alert-border-radius:         @border-radius-base;

// Media
// -------------------------

@med-obj-padding:                  20px;
@med-obj-arrow-size:               38px;
@med-obj-width:                    88px;
@med-obj-height:                   84px;

@med-preview-img-width:            354px;
@med-preview-img-height:           238px;
@med-preview-thumb-width:          115px;
@med-preview-thumb-height:         76px;
@med-preview-border-width:         1px;

@med-preview-control-padding:      20px;

// Date box
// -------------------------

@datebox-header-colour:         @brand-white;
@datebox-border-radius:         @border-radius-base;
@datebox-border-colour:         @datebox-bg;
@datebox-bg:                    @brand-blue;
@date-box-day-size:             36px;
@date-box-header-size:          12px;
@date-box-sm-day-size:          26px;
@date-box-sm-header-size:       10px;
@date-box-sm-text-size:         11px;

// Separator
// -------------------------

@separator-gutter-width:            floor(@grid-gutter-width / 2);
@separator-gutter-height:           @vertical-space-2;

// Panels
// -------------------------

@panel-inner-border:          @gray-light;
@panel-footer-bg:             #f5f5f5;

@panel-primary-text:          @brand-white;
@panel-primary-border:        @brand-primary;
@panel-primary-heading-bg:    @brand-primary;

@panel-success-text:          @state-success-text;
@panel-success-border:        @state-success-border;
@panel-success-heading-bg:    @state-success-bg;

@panel-warning-text:          @state-warning-text;
@panel-warning-border:        @state-warning-border;
@panel-warning-heading-bg:    @state-warning-bg;

@panel-danger-text:           @state-danger-text;
@panel-danger-border:         @state-danger-border;
@panel-danger-heading-bg:     @state-danger-bg;

@panel-info-text:             @state-info-text;
@panel-info-border:           @state-info-border;
@panel-info-heading-bg:       @state-info-bg;

// Thumbnails
// -------------------------

@thumbnail-padding:           4px;
@thumbnail-bg:                @body-bg;
@thumbnail-border:            @gray-light;
@thumbnail-border-radius:     @border-radius-base;

@thumbnail-caption-color:     @text-color;
@thumbnail-caption-padding:   9px;

// Wells
// -------------------------

@well-bg:                     #f5f5f5;

// Close
// ------------------------

@close-font-weight:           bold;
@close-color:                 #555;
@close-text-shadow:           0 1px 0 @brand-white;

// Code
// ------------------------

@code-color:                  #c7254e;
@code-bg:                     #f9f2f4;

@pre-bg:                      #f5f5f5;
@pre-color:                   @gray-dark;
@pre-border-color:            #ccc;
@pre-scrollable-max-height:   340px;

// Type
// ------------------------

@text-muted:                  @gray-light;
@abbr-border-color:           @gray-light;
@blockquote-small-color:      @gray-light;
@blockquote-border-color:     @brand-pastel-gray;
@page-header-border-color:    @brand-pastel-gray;

// Miscellaneous
// -------------------------

// Hr border color
@hr-border:                   @brand-pastel-gray;

// Horizontal forms & lists
@component-offset-horizontal: 180px;

// rounded packs
@rounded-lg:                    22px;
@rounded-md:                    12px;

// Container sizes
// --------------------------------------------------

// Small screen / tablet
@container-tablet:             ((720px + @grid-gutter-width));
@container-sm:                 @container-tablet;

// Medium screen / desktop
@container-desktop:            ((940px + @grid-gutter-width));
@container-md:                 @container-desktop;

// Large screen / wide desktop
@container-large-desktop:      ((1140px + @grid-gutter-width));
@container-lg:                 @container-large-desktop;

// Transitions
// --------------------------------------------------

@transition-speed: 300ms;

// Side nav
// --------------------------------------------------

@side-nav-min-height: 520px;

// Price Spacing
// --------------------------------------------------

@price-horizontal-space: 15px;
