@cart-base-gutter : @vertical-space-2;

.cart {
    h3 {
        margin-bottom: @vertical-space-2;

        &.product-title {
            margin-bottom: 0;
        }
    }
}

#clear-cart-label {
    font-size: @font-size-small;
    display: inline-block;
    padding: 15px 30px 0 0;
}

.cart-items-holder {
    position: relative;

    &:after,
    &:before {
        position: absolute;
        z-index: 1;
        height: 30px;
        width: 100%;
        display: block;
        content: " ";
        pointer-events: none;
        height: 25px;
    }

    &:before {
        #gradient.vertical(rgba(255,255,255,1), rgba(255,255,255,0));
        top: 0;
        left: 0;
    }

    &:after {
        #gradient.vertical(rgba(255,255,255,0), rgba(255,255,255,1));
        bottom: 0;
        left: 0;
    }
}

.cart-items {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 375px;
    padding-top: @vertical-space-1 / 2;
    margin-bottom: 0;
    padding-right:10px;

    &:after,
    &:before {
        display: none;
    }

    > .last-child:after {
        display: none;
    }

    .product-type-name {
        position: relative;
        top: 5px;
        left: 10px;
    }

    .paper-quantity-wrapper {
        float: left;
        margin-right: 20px;

        .single-quantity {
            width: 85px;
            height: 23px;
            margin-top: 3px;
        }
    }

    .paper-options-message a {
        padding-left: 10px;
    }
}

.cart-cta-btn {
    text-align: right;

    .form-button-col,
    .form-group {
        padding: 0;
        margin: 0;
        width: 100%;
    }
}

.cart-address {
    margin-bottom: 0;
    float: left;
    max-width: 90%;

    & + .popover-anchor {
        margin-left: 10px;
        margin-top: @vertical-space-1 / 2 * -1;
    }
}

.cart-address-selector {

    &, &:hover {
        margin-left: @cart-base-gutter;
        float: left;
        margin-top: @vertical-space-1 * -1;
    }

}

.cart-details {
    .separator-horizontal(~":before");

    h3 {
        margin-top: 25px;
    }

    .order-summary {
        .separator-horizontal();
        margin-bottom: @vertical-space-4;

        .fluid-attributes {
            margin-top: 0;
            .make-sm-column-offset(7);
        }
    }

    .order-summary-total {
        margin-bottom: @vertical-space-4;

        .col-key,
        .col-value {
            padding-bottom: 25px;
        }
    }
}

.cart-shipping-method {
    padding-right: 10px;
}

.cart-contact {

    // The help badge takes up space so change it to come out of the content flow with pos:abs.
    h3 {
        position: relative;

        sup {
            position: absolute;
        }
    }

    .form-group {
        margin-left: 0;
        margin-right: 0;
    }
}

// cart checkout section

.cart-total-price {
    margin-left: @cart-base-gutter * 2;
}

// cart confirmation section

.cart-confirmation {
    padding: 28px 0 ;

    .separator;

    .h3 {
        margin-top: 0;
    }

    .btn {
        margin-bottom: @cart-base-gutter;
    }
}

#addresses-popover {
    display: none;
}

.address-selection .address-option {
    cursor: pointer;
}
