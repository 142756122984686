/**
 * Varela Web @font-face declarations
 *
 * A Google Web Font
 * http://www.google.com/fonts#UsePlace:use/Collection:Varela+Round
 */

@font-face {
    font-family: 'VarelaRound';
    src: url('@{icon-font-path}VarelaRound-Regular.eot');
    src: url('@{icon-font-path}VarelaRound-Regular.eot?#iefix') format('embedded-opentype'),
         url('@{icon-font-path}VarelaRound-Regular.woff') format('woff'),
         url('@{icon-font-path}VarelaRound-Regular.ttf') format('truetype'),
         url('@{icon-font-path}VarelaRound-Regular.svg#VarelaRoundRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
}

@font-face {
    font-family: 'VarelaRound';
    src: url('@{icon-font-path}VarelaRound-Regular.eot');
    src: url('@{icon-font-path}VarelaRound-Regular.eot?#iefix') format('embedded-opentype'),
         url('@{icon-font-path}VarelaRound-Regular.woff') format('woff'),
         url('@{icon-font-path}VarelaRound-Regular.ttf') format('truetype'),
         url('@{icon-font-path}VarelaRound-Regular.svg#VarelaRoundRegular') format('svg');
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
}

@font-face {
    font-family: 'VarelaRound';
    src: url('@{icon-font-path}VarelaRound-Regular.eot');
    src: url('@{icon-font-path}VarelaRound-Regular.eot?#iefix') format('embedded-opentype'),
         url('@{icon-font-path}VarelaRound-Regular.woff') format('woff'),
         url('@{icon-font-path}VarelaRound-Regular.ttf') format('truetype'),
         url('@{icon-font-path}VarelaRound-Regular.svg#VarelaRoundRegular') format('svg');
    font-weight: lighter;
    font-style: normal;
    font-variant: normal;
}
