html {
    height: 100%;
    font-size: 100%;
}

body{
    height: 100%;
}

// Wrapper for page content to push down footer
#wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -50px;
    position:relative;
}

#wrap-footer-inset {
    height: 100px;
}

.operation-edit {
    @operation-edit-margin: 37px;

    position: fixed;
    top: 0;
    background-color: red;
    width: 100%;
    color: #fff;
    border-bottom: 2px solid black;
    z-index: @zindex-side-navbar + 1;

    p {
        margin: 10px;
        line-height: 1em;
    }

    & + #wrap {
        margin-top: @operation-edit-margin;

        .nav-container {
            top: @operation-edit-margin;
        }

        header {
            top: @operation-edit-margin;
        }

        .search-sort-filter-control.affix {
            top: 76px;
        }
    }
}

// Footer containing the company copyright and address
footer {
    margin-top: -50px;
    height: 50px;

    address {
        margin: 0;
    }

    small {
        display: inline-block;
        color: @brand-black;
        font-size: @font-size-small;
        padding-left: @nav-desktop-margin-right;
    }

    .links {
        margin-top: @vertical-space-1;
        a {
            margin-right: 25px;
        }
    }
    position: relative;
}

@media (min-width: @screen-md) {
    footer {
        padding-left: @nav-desktop-fixed-width;
    }
}

.full-width {
    .container {
        padding-left: @nav-desktop-margin-right;
    }

    .main .container {
        // Containers nested inside div.main should align with container in header
        padding-right: @nav-desktop-margin-right + (@grid-gutter-width / 2);

        // Make the controls take up the full window space as the Flash does too
        width: 100%;
    }

    .page-header {
        padding: 0;
        h1 {
            padding: 0;
            margin-bottom: @vertical-space-2;
        }
    }
}

// Fixed left navigaion bar
// This also keeps the container against the left vs. center
.container-with-nav-fixed {
    margin: 0;
    padding-left: @nav-mobile-fixed-width + @nav-mobile-margin-right;
    .transition(padding @transition-speed ease-in-out);

    body.full-width & {
        padding-left: @nav-mobile-fixed-width;
    }

    .container {
        // Full width layouts use nested containers
        margin: 0;
    }
}

.container-pushdown {
    padding-top: @nav-min-height + @nav-margin-bottom;
}

.faux-mobile-view .container-with-nav-fixed {
    padding-left: @nav-mobile-fixed-width + @nav-mobile-margin-right;

    body.full-width & {
        padding-left: @nav-mobile-fixed-width;
    }
}

@media (min-width: @screen-md-min) {
    .container-with-nav-fixed {
        padding-left: @nav-desktop-fixed-width + @nav-desktop-margin-right;

        body.full-width & {
            padding-left: @nav-desktop-fixed-width;
        }

        body.full-width.faux-mobile-view & {
            padding-left: @nav-mobile-fixed-width;
        }
    }

    // Push container when the navigation is hovered over
    body.faux-mobile-view.nav-collapsible {
        .nav-container:hover ~ header {
            .builder-info {
                padding-left: (@nav-desktop-fixed-width + @nav-desktop-margin-right) - (@nav-mobile-fixed-width + @nav-mobile-margin-right);
                .transition(padding @transition-speed ease-in-out);
            }
        }
        .nav-container:hover ~ .container-with-nav-fixed {
            padding-left: @nav-desktop-fixed-width + @nav-desktop-margin-right;
            .transition(padding @transition-speed ease-in-out);
        }
        &.nav-collapsible-edge {
            .nav-container:hover ~ .container-with-nav-fixed {
                padding-left: @nav-desktop-fixed-width;
                .transition(padding @transition-speed ease-in-out);
            }
        }
    }
}

#popover-notification {
    min-width: 350px;

    .notification-container {
        border-bottom: 1px solid @brand-pastel-gray;
        padding-bottom: 15px;
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}
