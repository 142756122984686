#canvasFallbackNoFlashSupport {
  display: none;
}

.canvas-container {
    // Bring the container up to the title on the page and blend it with the grey canvas
    margin-top: -21px;
    padding-top: 24px;

    background: #EBEBEB;
    text-align: left;
    min-height: 740px; // Try to match the movie's height to avoid flash of content while Canvas boots
    transition: all 200ms ease; // If the height is changing, ease it in/out

    .canvas-header {
        margin-top: @vertical-space-2;
    }

    .pack-details {
        font-family: @headings-font-family;
        font-weight: @headings-font-weight;
        font-size: @font-size-large;
        vertical-align: bottom;
        display: inline-block;
        width: 50%;
    }

    .canvasLoading{
        background: none;
    }

    // This element gets removed when the canvas has loaded

    #divCanvasFallback {
        text-align: center;
    }


}

#divProgressContainer {
    text-align: right;
    padding-left: 15px;
}

.pack-name {
    float: left;
    padding-bottom: 15px;
    clear: left;
}