
.pack {
    .btn {
        &.disabled,
        &[disabled],
        fieldset[disabled] & {
            cursor: not-allowed;
            pointer-events: none; // Future-proof disabling of clicks
            .opacity(.65);
            display: block;
        }
    }

    .product-thumb-holder {
        position: relative;
    }

    .not-available {
        .small;
        color: @gray-dark;
        text-align: left;

        .mooicon-help-circle {
            float: right;
            margin-top: -6px;
            margin-left: 6px;
            font-size: @font-size-medium;
        }
    }

    .product-aside {
        min-height: 115px;
    }

    .packs & .paper-quantity-wrapper {
        margin-right: 30px;
    }

    .product-type-name {
        display: inline-block;
        margin-top: 4px;
    }
}



.packs-no-results {
    .separator-horizontal-thick(~":before")
}

.custom-checkbox input[type="checkbox"].checkbox-transition ~ .icon:before {
    .transition(background-color 0.4s ease);
}