
// Editable table style
.rows-collapse {
    .list-unstyled;
    
    // table header
    > .row:first-child {
        .separator-horizontal-thick();
    
        > [class*="col-"] {
            font-weight: bold;
            line-height: @table-checkbox-size;
            padding-top: @table-grid-th-padding - @separator-gutter-height;
            padding-bottom: @table-grid-th-padding;
        }
    }
    
    // table rows
    > li > .row,
    > .row {
        .separator-horizontal();
        
        > [class*="col-"] {
            padding-top: @vertical-space-1 / 2;
            padding-bottom: @vertical-space-1;
        }
    }
    
    .custom-checkbox {
        // reset styles
        margin: 0;
        min-height: 0;

        width: @table-checkbox-size;
        height: @table-checkbox-size;
    }
}

.row-edit {
    @padding-right: 40%;

    .form-group {
        width: 100%;
        padding-right: @padding-right;
        display: inline-block;
    }

    .form-input-col{
        .make-sm-column(8);
    }
    .control-label{
        .make-sm-column(4);
    }

    .form-group-btn {
        display: inline-block;
        padding: 0;
        margin: 0;
        width: auto;
        float: right;

        padding-right: @padding-right;
        margin-right: @grid-gutter-width;

        &:last-child {
            padding-right: 0;
            margin-right: 0;
        }

        .form-button-col {
            .make-sm-column(3);   
            .make-sm-column-offset(3);

            margin-left: 0;
            padding: 0 0 0 (@grid-gutter-width / 2);

            @media (min-width: @screen-sm-min) {
                float: none;
                margin-left: 0;
            }

            .btn {
                min-width: 110px;
            }
        }
    }
}

.row-summary,
.row-edit {
    overflow: hidden;
}

.row-edit,
.is-editing > .row-summary { 
    max-height: 0; 
}

.rows-collapse {
    .row-summary,
    .row-edit {
        .transition(max-height @transition-speed linear);
    }
}

.is-editing > .row-edit { 
    max-height: 1200px;
}

// needs to be overridden
.row-summary { 
    max-height: 125px;
}




