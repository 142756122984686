.signup-link {

    .form-control {
        .make-sm-column(8);
    }

    .form-button-col {
        margin-left: 0;
    }

    #signUpLinkButton[disabled] {
        cursor: pointer;
    }

    .signup-link-overlay.active {
        opacity: 0.4;
    }
}