.address-header {
    border-bottom: 1px solid @brand-pastel-gray;

    label {
        line-height: 50px;
    }
}

.address-list {
    .rows-collapse {
        .row {
            &.row-summary,
            &.row-edit {
                padding-top: 10px;

                &:after {
                    display: none;
                }
            }
        }
        .address-item:nth-child(2n + 1) {
            .row {
                background-color: @brand-gray-lighter;
            }
        }
    }
}

.address-item {
    position: relative;

    .edit-row {
        .btn-link;

        outline: none;

        &:before {
            .mooicon-edit();
            .mooicon-small;
        }

        &:hover{
            text-decoration: none;
        }

        padding: 0;
        text-align: right;
    }
}

.address-checkbox {
    position: absolute;
    top: 15px;
    left: 0;
}

.address-add-modal {
    h2 {
        margin-top: 10px;
        margin-bottom: 18px;
    }

    .address-form-cancel {
        display: none;
    }

    .form-input-col{
        .make-sm-column(8);
    }
    .control-label{
        .make-sm-column(4);
    }

    .form-group-btn {
        margin: 0;

        .form-button-col {
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: right;

            .btn {
                min-width: 110px;
            }
        }
    }
}

.address-selection {
    .address-option {
        cursor: pointer;
        float: left;
        width: 85%;
        .edit-item {
            float: right;
        }
    }
}