//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 0;

    font-size: 13px;

    > li {
        display: inline-block; // Remove list-style and block-level defaults

        &.disabled + li {
            > a,
            > span {
                margin-left: 0;
            }
        }

        &:first-child {
            border-right: 1px solid @gray-light;
            margin-right: 17px;
        }

        &:last-child {
            border-left: 1px solid @gray-light;
            padding-left: 17px;

            > a,
            > span {
                margin-left: 0;
                margin-right: 0;
            }
        }

        > a,
        > span {
            position: relative;
            float: left; // Collapse white-space
            padding: 0px;
            text-decoration: none;
            margin-right: 17px;
            text-align: center;

            &:hover {
                margin-top: 1px;
                margin-bottom: -1px;
            }

            &:active {
                outline: 0;
                margin-top: 2px;
                margin-bottom: -2px;
                border-bottom-width: 2px;
            }
        }

        > .pagination-elipsis {
            &,
            &:hover,
            &:active {
                border: 0;
                font-size: 21px;
                letter-spacing: 2px;
                padding: 10px 0px 4px;
                margin-top: 0;
                width: auto;
            }
        }

    }

    > .active > a,
    > .active > span {
        &,
        &:hover,
        &:active,
        &:focus {
            color: @pagination-active-color;
            cursor: default;
        }
    }

    > .disabled {

        > span,
        > span:active,
        > span:hover,
        > span:focus,
        > a,
        > a:active,
        > a:hover,
        > a:focus {
            color: @pagination-disabled-color;
            cursor: not-allowed;
        }
    }
}

// Pagination Summary
//---------------------------------------------------

.pagination-summary {
    font-size: 13px;
    margin: 0;
}

.pagination-holder {
    .clearfix();

    margin: @vertical-space-4 0 0;
}