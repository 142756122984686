.mooicon-x-small {
    font-size: @mooicon-font-size-x-sm;
}

.mooicon-small {
    font-size: @mooicon-font-size-sm;
}

.mooicon-medium {
    font-size: @mooicon-font-size-md;
}

.mooicon-large {
    font-size: @mooicon-font-size-lg;
}

.mooicon-circle {
    border-radius: 50%;
    width: 1em;
    height: 1em;
}

.mooicon-primary {
    background: @brand-dark-green;
    color: white;
}

.mooicon-danger {
    background: @brand-danger;
    color: white;
}

.mooicon-light {
    background: @brand-white;
    color: @gray-darker;
}

.mooicon-dark {
    color: @brand-white;
    background: @gray-darker;
}

.mooicon-gray {
    background: @gray;
    color: @brand-white;
}

.mooicon-light-bg {
    background: @gray-light;
    color: @gray;
}


// Moo brand logo
.moo-logo {
    text-decoration: none;
    color: #fff;
    font-family: @font-family-bryant;
    display: inline-block;
    vertical-align: bottom;

    &:hover {
        text-decoration: none;
        color: #fff;
    }

    .icon-brand-logo {
        float: left;

        // pixel precision
        margin-top: -1px;
        margin-right: -4px;
    }
}

.mooicon-help-circle {
    &:before {
        h1 &,
        h2 &,
        h3 &,
        h4 &,
        h5 &,
        h6 &
        {
            font-size: 75%;
            margin-left: 5px;
        }
    }
}

.main .mooicon-help-circle
{
    color: @brand-dark-green;
}