//
// Component animations
// --------------------------------------------------

// Heads up!
//
// We don't use the `.opacity()` mixin here since it causes a bug with text
// fields in IE7-8. Source: https://github.com/twitter/bootstrap/pull/3552.

.fade {
    opacity: 0;
    .transition(opacity .15s linear);
    &.in {
        opacity: 1;
    }
}

.collapse {
    display: none;
    &.in {
        display: block;
    }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    .transition(height .35s ease);
}

.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -ms-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

.animated.hinge {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -ms-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInDown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInDown {
    0% {
        opacity: 0;
        -o-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    -moz-animation-name: fadeInDown;
    -o-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -moz-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -o-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes fadeInRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes fadeInRight {
    0% {
        opacity: 0;
        -o-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    -moz-animation-name: fadeInRight;
    -o-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    -moz-animation-name: fadeOut;
    -o-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
}

@-moz-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -moz-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -moz-transform: translateY(-20px);
    }
}

@-o-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -o-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -o-transform: translateY(-20px);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    -moz-animation-name: fadeOutUp;
    -o-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }
}

@-moz-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -moz-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }
}

@-o-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -o-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -o-transform: translateY(20px);
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    -moz-animation-name: fadeOutDown;
    -o-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }
}

@-moz-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -moz-transform: translateX(-20px);
    }
}

@-o-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -o-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -o-transform: translateX(-20px);
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-20px);
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    -moz-animation-name: fadeOutLeft;
    -o-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(20px);
    }
}

@-moz-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -moz-transform: translateX(20px);
    }
}

@-o-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -o-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -o-transform: translateX(20px);
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(20px);
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    -moz-animation-name: fadeOutRight;
    -o-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
}

.fadein {
    .fadeIn;
}

.fadein-up {
    .fadeInUp;
}

.fadein-down {
    .fadeInDown;
}

.fadein-left {
    .fadeInLeft;
}

.fadein-right {
    .fadeInRight;
}

.fadeout {
    .fadeOut;
}

.fadeout-up {
    .fadeOutUp;
}

.fadeout-down {
    .fadeOutDown;
}

.fadeout-left {
    .fadeOutLeft;
}

.fadeout-right {
    .fadeOutRight;
}

