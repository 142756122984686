@import (reference) "moo-toolkit/src/less/variables/colors.less";

.messages-form {
    padding: 15px;
    background-color: @brand-alternate-gray;

    .control-label{
        .make-sm-column(3);
    }
    .form-input-col{
        .make-sm-column(4);
    }

    textarea {
        height: 120px;
    }
}