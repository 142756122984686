.preview-control.popover {
    display: block;
    width: 556px;
    height: 392px;

    &.placeholder {
        display: none;
    }

    .header {
        display: none;
    }

    .preview-control-footer {
        display: none;
    }
}

.preview-control-toggle {
    cursor: pointer;
}
