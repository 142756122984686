//
// Tables
// --------------------------------------------------


table {
    max-width: 100%;
    background-color: @table-bg;
}
th {
    text-align: left;
}


// Baseline styles

.table {
    width: 100%;
    margin-bottom: @line-height-computed;
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: @table-cell-padding 5px;
                line-height: 1;
                vertical-align: top;
                color: @gray-darker;
            }
        }
    }

    thead tr {
        border-bottom: @table-border-width * 2 solid @table-border-color;
    }

    tbody tr {
        border-bottom: @table-border-width solid @table-border-color;

        &:nth-child(2n + 1) {
            td {
                background-color: @gray-lighter;
            }
        }
    }

    // Bottom align for column headings
    > thead > tr > th {
        font-size: @table-th-font-size;
        font-family: @font-family-verdana;
        font-weight: normal;
        vertical-align: bottom;
        padding-bottom: @table-th-padding;
        
        .sort-link,
        button {
            &,
            &:focus,
            &:active,
            &:hover {
                border: 0 none;
                background: transparent;
                padding: 0 30px 0 0;
                outline: 0 none;
                margin: 0;
                font-family: @font-family-verdana;
                font-size: @table-th-font-size;
                cursor: pointer;
                color: @gray-darker;
                position: relative;
                text-decoration: none;
                width: 100%;
                display: inline-block;
                text-align: left;
            }
        }

        .sort-inactive,
        .sort-desc,
        .sort-asc,
        .sort {
            &:after {
                margin-left: 5px;
                display: inline-block;
                .mooicon;
                font-size: @mooicon-font-size-x-sm;
                float: right;
            }
        }

        .sort-desc:after {
            content: @mooicon-sort-up;
        }
        .sort-asc:after {
            content: @mooicon-sort-down;
        }
        .sort-inactive:after,
        .sort:after {
            content: @mooicon-sort;
        }
        .sort-inactive {
            color: @gray;
        }

    }

    // Remove top border from thead by default
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
        > tr:first-child {
            > th,
            > td {
                border-top: 0;
            }
        }
    }
    // Account for multiple tbody instances
    > tbody + tbody {
        border-top: @table-border-width * 2 solid @table-border-color;
    }

    // Nesting
    .table {
        background-color: @body-bg;
    }

}

// Condensed table w/ half padding

.table-condensed {
    > thead,
    > tbody,
    > tfoot {

        > tr {

            > th,
            > td {
                padding-top: @table-condensed-cell-padding;
                padding-bottom: @table-condensed-cell-padding;
            }
        }
    }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
    border: @table-border-width solid @table-border-color;
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border: @table-border-width solid @table-border-color;
            }
        }
    }

    > thead > tr {
        > th,
        > td {
            border-bottom-width: @table-border-width * 2;
        }
    }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
    > tbody > tr:nth-child(odd) {
        > td,
        > th {
            background-color: @table-bg-accent;
        }
    }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
    > tbody > tr:hover {
        > td,
        > th {
            background-color: @table-bg-hover;
        }
    }
}


// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
    float: none;
    display: table-column;
}
table {
    td,
    th {
        &[class*="col-"] {
            float: none;
            display: table-cell;
        }
    }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

.table {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > td.active,
            > th.active,
            &.active > td,
            &.active > th  {
                background-color: @table-bg-active;
            }
        }
    }
}

// Generate the contextual variants
.table-row-variant(success; @state-success-bg; @state-success-border);
.table-row-variant(danger; @state-danger-bg; @state-danger-border);
.table-row-variant(warning; @state-warning-bg; @state-warning-border);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

@media (max-width: @screen-xs-max) {
    .table-responsive {
        width: 100%;
        margin-bottom: (@line-height-computed * 0.75);
        overflow-y: hidden;
        overflow-x: scroll;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: @table-border-width solid @table-border-color;
        -webkit-overflow-scrolling: touch;

        // Tighten up spacing
        > .table {
            margin-bottom: 0;

            // Ensure the content doesn't wrap
            > thead,
            > tbody,
            > tfoot {
            > tr {
                > th,
                > td {
                    white-space: nowrap;
                }
            }
            }
        }

        // Special overrides for the bordered tables
        > .table-bordered {
            border: 0;

            // Nuke the appropriate borders so that the parent can handle them
            > thead,
            > tbody,
            > tfoot {

                > tr {
                    > th:first-child,
                    > td:first-child {
                        border-left: 0;
                    }

                    > th:last-child,
                    > td:last-child {
                        border-right: 0;
                    }
                }
            }

            // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
            // chances are there will be only one `tr` in a `thead` and that would
            // remove the border altogether.
            > tbody,
            > tfoot {

                > tr:last-child {
                    > th,
                    > td {
                        border-bottom: 0;
                    }
                }
            }

        }
    }
}
