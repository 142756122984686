//
// Empty lists
// --------------------------------------------------

.list-empty {
    text-align: center;

    margin: @vertical-space-3 0;
    overflow: hidden;

    p {
        width: 45%;
        line-height: 1em;
        margin: auto;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    img {
        display: inline-block;
        margin: 0 auto @vertical-space-3;
    }

    .list-empty-cta {
        margin-top: @vertical-space-3;
    }
}

//
// List groups
// --------------------------------------------------

// Base class
//
// Easily usable on <ul>, <ol>, or <div>.
.list-group {
    // No need to set list-style: none; since .list-group-item is block level
    margin-bottom: 20px;
    padding-left: 0; // reset padding because ul and ol
}

// Individual list items
// -------------------------

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    // Place the border on the list items and negative margin up for better styling
    margin-bottom: -1px;
    background-color: @list-group-bg;
    border: 1px solid @list-group-border;

    // Round the first and last items
    &:first-child {
        .border-top-radius(@list-group-border-radius);
    }
    &:last-child {
        margin-bottom: 0;
        .border-bottom-radius(@list-group-border-radius);
    }

    // Align badges within list items
    > .badge {
        float: right;
    }
    > .badge + .badge {
        margin-right: 5px;
    }
}

// Linked list items
a.list-group-item {
    color: @list-group-link-color;

    .list-group-item-heading {
        color: @list-group-link-heading-color;
    }

    // Hover state
    &:hover,
    &:focus {
        text-decoration: none;
        background-color: @list-group-hover-bg;
    }

    // Active class on item itself, not parent
    &.active,
    &.active:hover,
    &.active:focus {
        z-index: 2; // Place active items above their siblings for proper border styling
        color: @list-group-active-color;
        background-color: @list-group-active-bg;
        border-color: @list-group-active-border;

        // Force color to inherit for custom content
        .list-group-item-heading {
            color: inherit;
        }
        .list-group-item-text {
            color: lighten(@list-group-active-bg, 40%);
        }
    }
}

// Custom content options
// -------------------------

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3;
}
