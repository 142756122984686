// Media objects
// Source: http://stubbornella.org/content/?p=497
// --------------------------------------------------


// Common styles
// -------------------------
.media {
    .popover & {
        width: 339px;
    }

    .selected-icon {
        display: none;
    }

    &.hover,
    &.option:hover {
        background-color: @brand-pastel-green;
        border-color: @brand-pastel-green;
        cursor: pointer;

        & + .media {
            border-color: @brand-pastel-green;
        }
    }

    &.selected {
        .selected-icon {
            &:before {
                .mooicon-tick-small();
            }

            display: inline-block;
            font-size: 28px;
            margin-left: 15px;
            vertical-align: middle;
            color: @brand-dark-green;
            position: relative;
            top: -5px;
        }
    }
}

// Clear the floats
.media,
.media-body {
    overflow: hidden;
    zoom: 1;

    > p {
        line-height: 1.4em;
        margin-bottom: 5px;
    }
}

// Proper spacing between instances of .media
.media,
.media .media {
    padding: @med-obj-padding;
    border-top: 2px solid @brand-pastel-gray;
}

.media:first-child {
    border-top: 0 none;
}

.media-arrowed {
    position: relative;
    padding-right: 29px;


    .media-arrow {
        // vertical align arrow
        position: absolute;
        top: 50%;
        margin-top: -1 * (@med-obj-arrow-size  + (@med-obj-padding / 2));

        right: 0;
        padding: @med-obj-padding (@med-obj-padding / 2) @med-obj-padding @med-obj-padding;
    }
}

.media-arrow {
    font-size: @med-obj-arrow-size;
    line-height: 1em;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.media-title {
    padding: 13px 18px 8px;

    h3 {
        margin: 0;
    }

    .btn-link {
        line-height: 1;
        margin: 0 0 @vertical-space-1;
        outline: 0 none;
        padding: 0 0 0 20px;
        border: 0;
        position: relative;
        font-size: @font-size-base;

        > .back-arrow {
            position: absolute;
            left: 0;
            top: -17px;
            font-size: 40px;
        }
    }
}

// For images and videos, set to block
.media-object {
    display: block;
}

.media-object-88x84 {
    width: 88px;
    height: 84px;

    > img {
        width: 88px;
        height: 84px;
    }

    & + .media-body {
        height: 84px;
    }
}

.media-object-130x98 {
    width: 130px;
    height: 98px;

    > img {
        width: 130px;
        height: 98px;
    }

    & + .media-body {
        height: 88px;
        margin-top: 10px;
    }
}

.media-object-88x53 {
    width: 88px;
    height: 53px;

    > img {
        width: 88px;
        height: 53px;
    }

    & + .media-body {
        height: 53px;
    }
}

// Reset margins on headings for tighter default spacing
.media-heading {
    margin: 0 0 5px;
    line-height: 32px;
}

.media-centred {
    .media-body {
        height: 84px;

        &:before {
            content: ' ';
            display: inline-block;
            vertical-aligxn: middle;
        }

        .media-heading {
            margin-top: 5px;
            vertical-align: middle;

        }
    }
}

.media-info {
    clear: both;

    h4 {
        margin-bottom: @vertical-space-1 / 2;
        font-weight: bold;
    }

    p {
        line-height: 1.2em;
    }
}

.media-info-show {
    cursor: pointer;

    &.collapsing {
        display: none;
    }
}

.media-info-hide {
    cursor: pointer;

    // align "hide info" text to "more info" text
    margin-left: @med-obj-width + @med-obj-padding;
}

// Media image alignment
// -------------------------

.media {
    > .pull-left {
        margin-right: @med-obj-padding;
    }
    > .pull-right {
        margin-left: @med-obj-padding;
    }
}

// Media box
.media-box {
    border: 3px solid @gray;
    padding: @med-obj-padding (@med-obj-padding * 2);
    border-radius: @border-radius-base;
    display: inline-block;

    > h3 {
        margin-top: 0;
    }

    > ul {
        margin-bottom: 0;
    }
}

.media-box-info {
    border-color: @brand-blue;
}

// Media list variation
// -------------------------

.media-list {
    // Undo default ul/ol styles
    padding-left: 0;
    list-style: none;
    overflow: hidden;
    margin-bottom: 0;
}

// Media Javascript States
// ------------------------
.media {
    .media-info-show {
        .animated;
        .fadeIn;

        margin: 0;
        line-height: 1.4em;
    }

    .collapse {
        max-height: 0;
        overflow: hidden;
        display: block;
        .transition(max-height @transition-speed linear);
    }

    &.has-more-info {

        .media-info-show {
            visibility: hidden;
            .fadeOutDown;
        }

        .collapse {
            max-height: 700px;
        }
    }
}
