@font-size-enormous: 124px;
.error-page {
    .container {
        margin-left: auto;
        margin-right: auto;
        padding: @vertical-space-4 0;
        text-align: center;
    }

    .decorated-illustration {
        display: block;
        margin-bottom: 25px;
    }

    .decorated-illustration .digit {
        font-size: @font-size-enormous;
        vertical-align: middle;
        margin: 35px;
    }
}