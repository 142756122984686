[class*='mooimage-'] {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

@mooimage-url-boarding-design: replace(replace('dist/images/svg/boarding-design.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-boarding-upload: replace(replace('dist/images/svg/boarding-upload.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-coins: replace(replace('dist/images/svg/coins.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-computer: replace(replace('dist/images/svg/computer.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-create-own-make: replace(replace('dist/images/svg/create-own-make.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-create-own-templates: replace(replace('dist/images/svg/create-own-templates.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-create-own-upload: replace(replace('dist/images/svg/create-own-upload.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-dyo: replace(replace('dist/images/svg/dyo.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-friends-unknown: replace(replace('dist/images/svg/friends-unknown.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-ink-drop-filled: replace(replace('dist/images/svg/ink-drop-filled.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-ink-drop-hollow: replace(replace('dist/images/svg/ink-drop-hollow.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-lockup: replace(replace('dist/images/svg/lockup.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-lockup-solid: replace(replace('dist/images/svg/lockup-solid.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-lockup-solid-white: replace(replace('dist/images/svg/lockup-solid-white.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-lockup-white: replace(replace('dist/images/svg/lockup-white.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-moo_drop_smile: replace(replace('dist/images/svg/moo_drop_smile.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-moo_drop_wink: replace(replace('dist/images/svg/moo_drop_wink.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-new-sticker: replace(replace('dist/images/svg/new-sticker.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-people: replace(replace('dist/images/svg/people.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-printfinity: replace(replace('dist/images/svg/printfinity.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-printfinity-grey: replace(replace('dist/images/svg/printfinity-grey.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-printfinity-white: replace(replace('dist/images/svg/printfinity-white.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-promise-grey: replace(replace('dist/images/svg/promise-grey.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-promise-stamp: replace(replace('dist/images/svg/promise-stamp.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-promise-white: replace(replace('dist/images/svg/promise-white.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-stopwatch: replace(replace('dist/images/svg/stopwatch.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-stopwatch-grey: replace(replace('dist/images/svg/stopwatch-grey.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-stopwatch-white: replace(replace('dist/images/svg/stopwatch-white.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-tick-green: replace(replace('dist/images/svg/tick-green.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-tick-grey: replace(replace('dist/images/svg/tick-grey.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-yay: replace(replace('dist/images/svg/yay.png', '.png', '.svg'), 'dist/images/', '');
@mooimage-url-yay-blue: replace(replace('dist/images/svg/yay-blue.png', '.png', '.svg'), 'dist/images/', '');

@mooimage-width-boarding-design: 91px;
@mooimage-width-boarding-upload: 91px;
@mooimage-width-coins: 50px;
@mooimage-width-computer: 50px;
@mooimage-width-create-own-make: 54px;
@mooimage-width-create-own-templates: 54px;
@mooimage-width-create-own-upload: 54px;
@mooimage-width-dyo: 54px;
@mooimage-width-friends-unknown: 260px;
@mooimage-width-ink-drop-filled: 11px;
@mooimage-width-ink-drop-hollow: 23px;
@mooimage-width-lockup: 120px;
@mooimage-width-lockup-solid: 52px;
@mooimage-width-lockup-solid-white: 53px;
@mooimage-width-lockup-white: 120px;
@mooimage-width-moo_drop_smile: 25px;
@mooimage-width-moo_drop_wink: 25px;
@mooimage-width-new-sticker: 51px;
@mooimage-width-people: 50px;
@mooimage-width-printfinity: 50px;
@mooimage-width-printfinity-grey: 50px;
@mooimage-width-printfinity-white: 50px;
@mooimage-width-promise-grey: 50px;
@mooimage-width-promise-stamp: 50px;
@mooimage-width-promise-white: 50px;
@mooimage-width-stopwatch: 50px;
@mooimage-width-stopwatch-grey: 50px;
@mooimage-width-stopwatch-white: 50px;
@mooimage-width-tick-green: 27px;
@mooimage-width-tick-grey: 27px;
@mooimage-width-yay: 71px;
@mooimage-width-yay-blue: 71px;

@mooimage-height-boarding-design: 101px;
@mooimage-height-boarding-upload: 101px;
@mooimage-height-coins: 50px;
@mooimage-height-computer: 50px;
@mooimage-height-create-own-make: 78px;
@mooimage-height-create-own-templates: 78px;
@mooimage-height-create-own-upload: 78px;
@mooimage-height-dyo: 78px;
@mooimage-height-friends-unknown: 88px;
@mooimage-height-ink-drop-filled: 17px;
@mooimage-height-ink-drop-hollow: 35px;
@mooimage-height-lockup: 40px;
@mooimage-height-lockup-solid: 18px;
@mooimage-height-lockup-solid-white: 18px;
@mooimage-height-lockup-white: 40px;
@mooimage-height-moo_drop_smile: 38px;
@mooimage-height-moo_drop_wink: 38px;
@mooimage-height-new-sticker: 51px;
@mooimage-height-people: 50px;
@mooimage-height-printfinity: 50px;
@mooimage-height-printfinity-grey: 50px;
@mooimage-height-printfinity-white: 50px;
@mooimage-height-promise-grey: 50px;
@mooimage-height-promise-stamp: 50px;
@mooimage-height-promise-white: 50px;
@mooimage-height-stopwatch: 50px;
@mooimage-height-stopwatch-grey: 50px;
@mooimage-height-stopwatch-white: 50px;
@mooimage-height-tick-green: 27px;
@mooimage-height-tick-grey: 27px;
@mooimage-height-yay: 71px;
@mooimage-height-yay-blue: 71px;

.mooimage-background-boarding-design() {
  .svg-background(@mooimage-url-boarding-design);
}

.mooimage-background-boarding-upload() {
  .svg-background(@mooimage-url-boarding-upload);
}

.mooimage-background-coins() {
  .svg-background(@mooimage-url-coins);
}

.mooimage-background-computer() {
  .svg-background(@mooimage-url-computer);
}

.mooimage-background-create-own-make() {
  .svg-background(@mooimage-url-create-own-make);
}

.mooimage-background-create-own-templates() {
  .svg-background(@mooimage-url-create-own-templates);
}

.mooimage-background-create-own-upload() {
  .svg-background(@mooimage-url-create-own-upload);
}

.mooimage-background-dyo() {
  .svg-background(@mooimage-url-dyo);
}

.mooimage-background-friends-unknown() {
  .svg-background(@mooimage-url-friends-unknown);
}

.mooimage-background-ink-drop-filled() {
  .svg-background(@mooimage-url-ink-drop-filled);
}

.mooimage-background-ink-drop-hollow() {
  .svg-background(@mooimage-url-ink-drop-hollow);
}

.mooimage-background-lockup() {
  .svg-background(@mooimage-url-lockup);
}

.mooimage-background-lockup-solid() {
  .svg-background(@mooimage-url-lockup-solid);
}

.mooimage-background-lockup-solid-white() {
  .svg-background(@mooimage-url-lockup-solid-white);
}

.mooimage-background-lockup-white() {
  .svg-background(@mooimage-url-lockup-white);
}

.mooimage-background-moo_drop_smile() {
  .svg-background(@mooimage-url-moo_drop_smile);
}

.mooimage-background-moo_drop_wink() {
  .svg-background(@mooimage-url-moo_drop_wink);
}

.mooimage-background-new-sticker() {
  .svg-background(@mooimage-url-new-sticker);
}

.mooimage-background-people() {
  .svg-background(@mooimage-url-people);
}

.mooimage-background-printfinity() {
  .svg-background(@mooimage-url-printfinity);
}

.mooimage-background-printfinity-grey() {
  .svg-background(@mooimage-url-printfinity-grey);
}

.mooimage-background-printfinity-white() {
  .svg-background(@mooimage-url-printfinity-white);
}

.mooimage-background-promise-grey() {
  .svg-background(@mooimage-url-promise-grey);
}

.mooimage-background-promise-stamp() {
  .svg-background(@mooimage-url-promise-stamp);
}

.mooimage-background-promise-white() {
  .svg-background(@mooimage-url-promise-white);
}

.mooimage-background-stopwatch() {
  .svg-background(@mooimage-url-stopwatch);
}

.mooimage-background-stopwatch-grey() {
  .svg-background(@mooimage-url-stopwatch-grey);
}

.mooimage-background-stopwatch-white() {
  .svg-background(@mooimage-url-stopwatch-white);
}

.mooimage-background-tick-green() {
  .svg-background(@mooimage-url-tick-green);
}

.mooimage-background-tick-grey() {
  .svg-background(@mooimage-url-tick-grey);
}

.mooimage-background-yay() {
  .svg-background(@mooimage-url-yay);
}

.mooimage-background-yay-blue() {
  .svg-background(@mooimage-url-yay-blue);
}


.mooimage-boarding-design {
  .mooimage-background-boarding-design();

  width: @mooimage-width-boarding-design;
  height: @mooimage-height-boarding-design
}

.mooimage-boarding-upload {
  .mooimage-background-boarding-upload();

  width: @mooimage-width-boarding-upload;
  height: @mooimage-height-boarding-upload
}

.mooimage-coins {
  .mooimage-background-coins();

  width: @mooimage-width-coins;
  height: @mooimage-height-coins
}

.mooimage-computer {
  .mooimage-background-computer();

  width: @mooimage-width-computer;
  height: @mooimage-height-computer
}

.mooimage-create-own-make {
  .mooimage-background-create-own-make();

  width: @mooimage-width-create-own-make;
  height: @mooimage-height-create-own-make
}

.mooimage-create-own-templates {
  .mooimage-background-create-own-templates();

  width: @mooimage-width-create-own-templates;
  height: @mooimage-height-create-own-templates
}

.mooimage-create-own-upload {
  .mooimage-background-create-own-upload();

  width: @mooimage-width-create-own-upload;
  height: @mooimage-height-create-own-upload
}

.mooimage-dyo {
  .mooimage-background-dyo();

  width: @mooimage-width-dyo;
  height: @mooimage-height-dyo
}

.mooimage-friends-unknown {
  .mooimage-background-friends-unknown();

  width: @mooimage-width-friends-unknown;
  height: @mooimage-height-friends-unknown
}

.mooimage-ink-drop-filled {
  .mooimage-background-ink-drop-filled();

  width: @mooimage-width-ink-drop-filled;
  height: @mooimage-height-ink-drop-filled
}

.mooimage-ink-drop-hollow {
  .mooimage-background-ink-drop-hollow();

  width: @mooimage-width-ink-drop-hollow;
  height: @mooimage-height-ink-drop-hollow
}

.mooimage-lockup {
  .mooimage-background-lockup();

  width: @mooimage-width-lockup;
  height: @mooimage-height-lockup
}

.mooimage-lockup-solid {
  .mooimage-background-lockup-solid();

  width: @mooimage-width-lockup-solid;
  height: @mooimage-height-lockup-solid
}

.mooimage-lockup-solid-white {
  .mooimage-background-lockup-solid-white();

  width: @mooimage-width-lockup-solid-white;
  height: @mooimage-height-lockup-solid-white
}

.mooimage-lockup-white {
  .mooimage-background-lockup-white();

  width: @mooimage-width-lockup-white;
  height: @mooimage-height-lockup-white
}

.mooimage-moo_drop_smile {
  .mooimage-background-moo_drop_smile();

  width: @mooimage-width-moo_drop_smile;
  height: @mooimage-height-moo_drop_smile
}

.mooimage-moo_drop_wink {
  .mooimage-background-moo_drop_wink();

  width: @mooimage-width-moo_drop_wink;
  height: @mooimage-height-moo_drop_wink
}

.mooimage-new-sticker {
  .mooimage-background-new-sticker();

  width: @mooimage-width-new-sticker;
  height: @mooimage-height-new-sticker
}

.mooimage-people {
  .mooimage-background-people();

  width: @mooimage-width-people;
  height: @mooimage-height-people
}

.mooimage-printfinity {
  .mooimage-background-printfinity();

  width: @mooimage-width-printfinity;
  height: @mooimage-height-printfinity
}

.mooimage-printfinity-grey {
  .mooimage-background-printfinity-grey();

  width: @mooimage-width-printfinity-grey;
  height: @mooimage-height-printfinity-grey
}

.mooimage-printfinity-white {
  .mooimage-background-printfinity-white();

  width: @mooimage-width-printfinity-white;
  height: @mooimage-height-printfinity-white
}

.mooimage-promise-grey {
  .mooimage-background-promise-grey();

  width: @mooimage-width-promise-grey;
  height: @mooimage-height-promise-grey
}

.mooimage-promise-stamp {
  .mooimage-background-promise-stamp();

  width: @mooimage-width-promise-stamp;
  height: @mooimage-height-promise-stamp
}

.mooimage-promise-white {
  .mooimage-background-promise-white();

  width: @mooimage-width-promise-white;
  height: @mooimage-height-promise-white
}

.mooimage-stopwatch {
  .mooimage-background-stopwatch();

  width: @mooimage-width-stopwatch;
  height: @mooimage-height-stopwatch
}

.mooimage-stopwatch-grey {
  .mooimage-background-stopwatch-grey();

  width: @mooimage-width-stopwatch-grey;
  height: @mooimage-height-stopwatch-grey
}

.mooimage-stopwatch-white {
  .mooimage-background-stopwatch-white();

  width: @mooimage-width-stopwatch-white;
  height: @mooimage-height-stopwatch-white
}

.mooimage-tick-green {
  .mooimage-background-tick-green();

  width: @mooimage-width-tick-green;
  height: @mooimage-height-tick-green
}

.mooimage-tick-grey {
  .mooimage-background-tick-grey();

  width: @mooimage-width-tick-grey;
  height: @mooimage-height-tick-grey
}

.mooimage-yay {
  .mooimage-background-yay();

  width: @mooimage-width-yay;
  height: @mooimage-height-yay
}

.mooimage-yay-blue {
  .mooimage-background-yay-blue();

  width: @mooimage-width-yay-blue;
  height: @mooimage-height-yay-blue
}

